import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';
import type { Response } from './types';
import { Toast } from 'vant';
import { Dialog } from 'vant';
import il8n from '@/locales/index';
import { useTximStore } from '@/store';
import { useCaptureMistakeStore, indexStore } from '@/store';

axios.defaults.timeout = 1000 * 60;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// 创建axios实例
const service = axios.create({
  // 根据不同env设置不同的baseURL
  baseURL: import.meta.env.VITE_APP_API_BASE_URL,
});

// axios实例拦截请求
service.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    // 获取 token
    const token = localStorage.getItem('token');
    const ip = localStorage.getItem('ip');
    const lang = sessionStorage.getItem('language')
      ? JSON.parse(sessionStorage.getItem('language'))
      : { key: 'en-us' };
    config.headers = {
      ...config.headers,
      Token: token ? token : '',
      lang: lang.key,
      ip,
    };
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

// axios实例拦截响应
service.interceptors.response.use(
  // 2xx时触发
  (response: AxiosResponse<Response>) => {
    // response.data就是后端返回的数据，结构根据你们的约定来定义
    const { code, data, info } = response.data;
    let errMessage = '';
    switch (code) {
      case 0:
        if (info == 'No access rights') {
          Dialog({
            confirmButtonText: il8n.global.t('确定'),
            message: il8n.global.t('请注册'),
            confirmButtonColor: 'blue',
            className: 'globalDialog',
            width: '70%',
          }).then(() => {
            useTximStore().SET_LOGOUT(false);
            // indexStore().SET_SHOWEQUITY(true);
          });
        }
        break;
      case 1:
        break;
      case 99:
        if (info == 'Illegal users' || info == '非法用户' || info == '用户不存在' || info == 'no permission' || info == 'अवैध उपयोगकर्ता') {
          Dialog({
            confirmButtonText: il8n.global.t('确定'),
            message: il8n.global.t('请注册'),
            confirmButtonColor: 'blue',
            className: 'globalDialog',
            width: '70%',
          }).then(() => {
            useTximStore().SET_LOGOUT(false);
            // indexStore().SET_SHOWEQUITY(true);
          });
        }
      default:
        errMessage = info!;
        break;
    }
    if (errMessage) Toast.fail(errMessage);
    return response;
  },
  // 非2xx时触发
  (error: AxiosError) => {
    if (error.response && error.response.status && error.response.status >= 400) {
      useCaptureMistakeStore().SET_CAPTURE_SEND(error.response || Object());
    }
    Toast.fail(error);
    return Promise.reject(error);
  }
);

export type { AxiosResponse, AxiosRequestConfig };

export default service;
