// 保留 localStorage中的key 如'username'和'loginInfo'的数据，清除其他所有数据
// 用法：clearLocalStorageExcept(['username','loginInfo']);
export function clearLocalStorageExcept(keys: Array<string>) {
  for (let key in localStorage) {
    if (!keys.includes(key)) {
      localStorage.removeItem(key);
    }
  }
}
export function clearSessionStorageExcept(keys: Array<string>) {
  for (let key in sessionStorage) {
    if (!keys.includes(key)) {
      sessionStorage.removeItem(key);
    }
  }
}
