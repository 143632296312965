import { defineStore } from 'pinia';
import { Toast } from 'vant';

// 捕获响应Code>400以上的接口向外传
export const useCaptureMistakeStore = defineStore('captureMistake', {
  state: () => {
    return {
      url: 'https://api.telegram.org',
      chat_id: '-1002243670490',
    };
  },
  getters: {},
  actions: {
    async SET_CAPTURE_SEND(obj: any) {
      let text = '';
      switch (import.meta.env.VITE_USER_NODE_ENV) {
        case 'test': // 测试环境
          text = 'go测试服务器接口出错：';
          break;
        case 'production': // 生产环境
          text = '@datamoney365 @fish_365 @starry_12138 go正式服务器接口出错：';
          break;
        case 'development': // 开发环境
          text = 'go开发服务器接口出错：';
          break;
        default:
          text = 'go开发服务器接口出错：';
          break;
      }
      try {
        const baseUrl = this.url;
        const botToken = 'bot6962991485:AAGDRJANNEul0oOjh6kWZAr2Z3nT8Xqlz3U';
        const endpoint = 'sendMessage';
        const chatId = this.chat_id;
        const text2 = `接口地址：${obj.config.url}，请求参数：${JSON.stringify(obj.data)}，Token：${
          obj.config.headers.Token
        }，响应状态：${obj.status}`;
        const url = `${baseUrl}/${botToken}/${endpoint}?chat_id=${chatId}&text=${text}${text2}`;
        const response = await fetch(url, {
          method: 'GET',
        });
        // 检查响应状态
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        // 解析响应数据
        const data = await response.json();
      } catch (error) {
        Toast.fail(error!);
      }
    },
  },
});
