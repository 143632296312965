import { defineStore } from 'pinia';

export const useLayoutStore = defineStore('layout', {
  state: () => {
    return {
      currentActive: sessionStorage.getItem('currentActive')
        ? JSON.parse(sessionStorage.getItem('currentActive') as string)
        : 0,
    };
  },
  // persist: {
  //   enabled: true, //开启存储
  //   strategies: [
  //     //  指定存储的位置以及存储的变量都有哪些，该属性可以不写，
  //     //  在不写的情况下，默认存储到sessionStorage 里面，默认存储state里面的所有数据。
  //     { storage: sessionStorage, paths: ['currentActive'] },
  //   ],
  // },
  getters: {},
  actions: {
    SET_CURRENTACTIVE(val: number) {
      this.currentActive = val;
      sessionStorage.setItem('currentActive', JSON.stringify(val));
    },
  },
});
