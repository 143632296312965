import { defineStore } from 'pinia';

export const useOrderStore = defineStore('order', {
    state: () => ({
        currentTab: 1,
        currentPage: 1,
    }),
    actions: {
        setCurrentTab(tab: string | number) {
            this.currentTab = tab;
        },
        setCurrentPage(page: number) {
            this.currentPage = page;
        },
    },
});