import { App } from 'vue';

import ConfirmButton from './ConfirmButton/index.vue';
import Header from './HeaderCommon/index.vue';
import AppIcon from './AppIcon/index.vue';

export default {
  install(Vue: App) {
    Vue.component('c-confirm-btn', ConfirmButton);
    Vue.component('c-header', Header);
    Vue.component('c-icon', AppIcon);
  },
};
