import { defineStore } from 'pinia';
import { Language } from './interface';
import { useRequest } from '@/api/commonApi';
import { TUITranslateService } from '@tencentcloud/chat-uikit-engine';

const languageObj = {
  name: 'English',
  value: 'en',
};

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      language: sessionStorage.getItem('language') && sessionStorage.getItem('language')!='undefined'
        ? JSON.parse(sessionStorage.getItem('language') as string)
        : languageObj,
      userInfo: localStorage.getItem('userInfo')
        ? JSON.parse(localStorage.getItem('userInfo') as string)
        : '',
      homeObj: sessionStorage.getItem('homeObj')
        ? JSON.parse(sessionStorage.getItem('homeObj') as string)
        : '',
      userIp: '', // 用户IP
      domainUrl: localStorage.getItem('domainUrl') ? localStorage.getItem('domainUrl') : '',
      ip: localStorage.getItem('domainUrl') ? localStorage.getItem('domainUrl') : ''
    };
  },
  getters: {},
  actions: {
    SET_IP(ip){
      this.ip = ip;
      localStorage.setItem('ip',ip);
    },
    SET_DOMAIN_URL(domainUrl) {
      this.domainUrl = domainUrl;
    },
    SET_TOKEN(token, cb: any = null) {
      this.token = token;
      localStorage.setItem('token',token);
      cb?cb():false;
    },
    SER_USERINFI(userInfo) {
      this.userInfo = userInfo;
    },
    SET_LANGUAGE<T extends Language>(obj: T) {
      this.language = obj;
      sessionStorage.setItem('language', JSON.stringify(obj));
      // 腾讯IM 指定显示语言
      if(obj) TUITranslateService.changeLanguage(obj.value ? obj.value : 'en');
    },
    SET_HOMEOBJ(obj: object) {
      this.homeObj = obj;
      sessionStorage.setItem('homeObj', JSON.stringify(obj));
    },
    async SET_USERIP(val: boolean) {
      if (val) {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        this.userIp = data.ip ? data.ip : '';
      }
    },
  },
});
