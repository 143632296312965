import { defineStore } from 'pinia';
// import { Dialog } from 'vant';
import enqueueDialog from '../../../utils/enqueueDialog';
export const indexStore = defineStore('index', {
  state: () => {
    return {
      hornList: Object(), // 首页小喇叭数据
      showEquity: sessionStorage.getItem('showEquity')
        ? sessionStorage.getItem('showEquity') == 'true'
          ? true
          : false
        : true, //首页权益弹框开关
      isShowDialog: true
    };
  },
  getters: {},
  actions: {
    //   公告
    //"class" 类型0'小喇叭',1'站内公告',2'维护公告',3'推广公告'
    // "manipulate": 0, //前台是否可操作:0=可以,1=不可以
    set_siteNotice<T>(arr: Array<T>) {
      if (Array.isArray(arr) && arr.length > 0) {
        arr.forEach((el: any) => {
          if (el.class == 0) {
            // console.log('el', el)
            this.hornList = el;
          } else if (el.class == 2 && this.showEquity && this.isShowDialog) {
            enqueueDialog({
              title: el.title,
              message: el.content,
              showConfirmButton: el.manipulate == 1 ? false : true,
              confirmButtonText: 'Confirm',
              confirmButtonColor: '#5788ff',
              className: 'globalDialog',
              width: '70%',
              allowHtml: true,
              success: () => {
                this.SET_SHOWEQUITY(false)
              }
            });
          } else if (el.class == 3 && this.showEquity && this.isShowDialog) {
            enqueueDialog({
              title: el.title,
              message: el.content,
              confirmButtonText: 'Confirm',
              confirmButtonColor: '#5788ff',
              className: 'globalDialog',
              width: '70%',
              allowHtml: true,
              success: () => {
                this.SET_SHOWEQUITY(false)
              }
            });
          }
        });
        // console.log(this.hornList, 'dsgdg');
      }
    },
    SET_SHOWEQUITY(flg: boolean) {
      this.showEquity = flg;
      sessionStorage.setItem('showEquity', JSON.stringify(flg));
    },
  },
});
