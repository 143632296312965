<template>
  <i class="icon" :style="{ 'background-image': `url(${iconUrl})` }" />
</template>

<script setup lang="ts">
  const props = defineProps({
    name: {
      type: String,
      required: true,
    },
  });

  const iconUrl = computed(() => {
    return new URL(`/src/assets/icons/${props.name}.png`, import.meta.url).href;
  });
</script>

<style lang="scss" scoped>
  .icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 50%;
  }
</style>
