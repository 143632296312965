import { Dialog } from 'vant';

interface DialogOptions {
  title: string;
  message: string;
  confirmButtonText: string;
  confirmButtonColor: string;
  className: string;
  width: string;
  showConfirmButton?: boolean;
}

let isDialogOpen = false;
let dialogQueue: DialogOptions[] = [];
const annReadList = localStorage.getItem('annRead')
  ? JSON.parse(localStorage.getItem('annRead') as string)
  : [];

const processQueue = () => {
  if (dialogQueue.length > 0 && !isDialogOpen) {
    const options = dialogQueue.shift();
    if (options) {
      showDialogWithOptions(options);
    }
  }
};

const showDialogWithOptions = (options: any) => {
  isDialogOpen = true;
  Dialog.alert({
    title: options.title,
    message: options.message,
    confirmButtonText: options.confirmButtonText,
    confirmButtonColor: options.confirmButtonColor,
    className: options.className,
    width: options.width,
    showConfirmButton: options.showConfirmButton ?? true,
    allowHtml: true,
  })
    .then(() => {
      isDialogOpen = false;
      processQueue();
      if (!annReadList.includes(options.default)) {
        annReadList.push(options.default);
        localStorage.setItem('annRead', JSON.stringify(annReadList));
      }
      options.success(true)
    })
    .catch(() => {
      isDialogOpen = false;
      processQueue();
      options.success(false)
    });
};

const enqueueDialog = (options: any) => {
  if (!isDialogOpen && dialogQueue.length === 0 && !annReadList.includes(options.default)) {
    showDialogWithOptions(options);
  } else if (!!isDialogOpen && dialogQueue.length > 0 && !annReadList.includes(options.default)) {
    dialogQueue = [];
  } else {
    dialogQueue.push(options);
  }
};

export default enqueueDialog;
