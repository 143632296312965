import { createI18n } from 'vue-i18n';
import { Locale } from 'vant';
import enUS from 'vant/es/locale/lang/en-US';
import zhCN from 'vant/es/locale/lang/zh-CN';
import enLocale from './en';
import zhLocale from './zh';
import hiLocale from './hi';
import dayjs from 'dayjs';

console.log(sessionStorage.getItem('language'))
const locale = sessionStorage.getItem('language') && sessionStorage.getItem('language')!='undefined'
  ? JSON.parse(sessionStorage.getItem('language') as string).value
  : 'en';

const i18n = createI18n({
  legacy: false, // 需要设置为 false，以便与 Vue 3 兼容
  locale, // 默认语言
  globalInjection: true, // 全局注册 $t方法
  messages: {
    en: enLocale,
    zh: zhLocale,
    hi: hiLocale
  },
});

Locale.use(locale, locale === 'en' ? enUS : zhCN);
dayjs.locale(locale);

export default i18n;
