// 公共接口
import http, { Response } from '@/utils/http';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { Toast } from 'vant';

export interface Params { }
export interface SmsSend {
  county: string;
  mobile: unknown;
}
export interface SmsEmailSend {
  email: unknown;
}
export interface SmsVerify {
  code: string;
  county: string;
  mobile: string;
  scene: string;
}
export interface SetUserInfo {
  password: string;
  scene: string;
}
export interface BeforeVerifyImages<T> {
  md5: Array<T>;
}
export interface AfterVerifyImages<T> {
  md5: Array<T>;
}
export interface ForgetPassword {
  username: unknown;
  code: string;
  type: number;
  county: string;
  password: string;
  confirm_password: string;
}
interface VerifyUsernamed {
  username: string;
  county: string;
}
interface estimationType {
  post_txcode: number;
  grab_txcode: number;
  grab_id: number;
  rate: number;
}

export const commonApi = {
  // 获取系统配置
  async sysconfig(params: Params) {
    return await http.post<Response<any>>('/api/help/sysconfig', params);
  },
  // 获取用户信息
  async userInfo(params: Params) {
    return await http.post<Response<any>>('/api/userInfo/Info', params);
  },
  // 退出登录
  async logout(params: Params) {
    return await http.post<Response<any>>('/api/login/out', params);
  },
  // 获取腾讯上传参数
  async getTxosskey(params: Params) {
    return await http.post<Response<any>>('/api/userInfo/txosskey', params);
  },
  // 获取手机验证码
  async smsSend(params: SmsSend) {
    return await http.post<Response<any>>('/api/login/smsSend', params);
  },
  // 获取邮箱验证码
  async smsEmailSend(params: SmsEmailSend) {
    return await http.post<Response<any>>('/api/login/sendEmail', params);
  },
  // 手机验证码 验证
  async smsVerify(params: SmsSend) {
    return await http.post<Response<any>>('/api/login/smsVerify', params);
  },
  // 验证手机号码是否存在 code=0 存在  code=1 不存在
  async verifyUsernamed(params: VerifyUsernamed) {
    return await http.post<Response<any>>('/api/login/usernamed', params);
  },
  //忘记密码
  async forgetPassword(params: ForgetPassword) {
    return await http.post<Response<any>>('/api/login/forgotPassword', params);
  },
  //  抢单上传图片验证是否重复
  async beforeVerifyImages<T>(params: BeforeVerifyImages<T>) {
    return await http.post<Response<any>>('/api/image/index', params);
  },
  //  抢单上传图片 把md5上传
  async afterVerifyImages<T>(params: AfterVerifyImages<T>) {
    return await http.post<Response<any>>('/api/image/new_add', params);
  },
  //站内信，小喇叭
  async postSiteNotice(params: Params) {
    return await http.post<Response<any>>('/api/help/pollLatestNotice', params);
  },
  //站内信，新接口
  async postSiteNoticeNew(params: Params) {
    return await http.get<Response<any>>('/api/help/pollLatestNotice_new', params);
  },
  // 个人信息修改
  async setUserInfo(params: SetUserInfo, token: string) {
    const config: AxiosRequestConfig = {
      headers: {
        token: token,
      },
    };
    return await http.post<Response<any>>('/api/userInfo/set', params, config);
  },
  //下发问题
  async Issuesissued(params = {}) {
    return await http.post<any>('/api/msg/Issuesissued', params);
  },
  async postFolder() {
    return await http.post('/api/msg/folder', {});
  },

  // 客服好评
  async edit_estimation(params: estimationType) {
    return await http.post<Response<any>>('/api/msg/edit_estimation', params);
  },
  // 上报机器人 财务客服腾讯code:10010010

  async postBot(params: { bot: string }) {
    return await http.post<Response<any>>('/api/msg/bot', params);
  },
  // 提示语下发
  async sendMsgProactively(params: { txcode: string }) {
    return await http.post<Response<any>>('/api/msg/sendMsgProactively', params);
  },
  // 提示语下发
  async lang() {
    return await http.post<Response<any>>('/api/user/lang');
  },
  // 代理规则
  async level_list() {
    return await http.post<Response<any>>('/api/agent/level_list');
  },
  // tg登录
  async telegram_login(params: any) {
    return await http.post<Response<any>>('/api/telegram/login/in', params);
  },
  // 新人礼校验
  async sysconfigNewCheck() {
    return await http.post<Response<any>>('/api/help/sysconfigNewCheck');
  },
  // 绑定telegram
  async bindTelegram(params: { code: string }) {
    return await http.post<Response<any>>('/api/bind/bindTelegram', params);
  },
  async moneyLog(params: any) {
    return await http.post<Response<any>>('/api/money/moneyLog', params);
  },
  // 广告-轮播列表
  async getBanner(params: { type: number }) {//类型 1首页，2论坛
    return await http.post<Response<any>>('/api/advertisement/list', params);
  },
  // 获取当前系统时间
  async getSystemTime() {
    return await http.post<Response<any>>('/api/help/system_time');
  },
  //上传类型
  async presignedUrl(params: { type: number; path: string }) {
    return await http.post<Response<any>>('/api/get/presigned_url', params);
  },
  // 充值配置
  async payConfig() {
    return await http.post<Response<any>>('/api/investManage/invest/pay_config');
  },

};

// 请求方式
export const FETCH_METHOD = {
  POST: 'post',
  GET: 'get',
};

interface IFetchConfig<P = any> {
  url: string;
  params?: P;
  method?: 'post' | 'get';
  options?: AxiosRequestConfig;
}

/**
 * @function api_fetch 直接请求函数
 * @param url {string} 请求地址
 * @param params {any} 请求参数
 * @param method {post | get} 请求方式
 * @param options {AxiosRequestConfig} Axios的请求配置项
 * @return Promise<any>
 * */
export async function api_fetch({ url, params, method = 'post', options }: IFetchConfig) {
  let res;

  try {
    if (method === FETCH_METHOD.GET) {
      res = await http.get(url, {
        params,
        ...options,
      });
    } else {
      res = await http.post(url, params, options);
    }
    if (res.code === 1) {
      return res.data;
    } else {
      Toast.fail(res.info);
      return Promise.reject(res.info);
    }
  } catch (e) {
    return Promise.reject(e);
  }
}

interface IUseRequestConfig<P = any, R = any> extends IFetchConfig<P> {
  initialValues?: any;
  manual?: boolean;
  formatResult?: <T>(res: R) => T[];
  onSuccess?: <T>(res: R, params: P) => void;
  onErr?: (err: AxiosError) => void;
}

/**
 * @function useRequest 请求hooks
 * @param url {string} 请求地址
 * @param params {any} 请求参数
 * @param method {post | get} 请求方式
 * @param options {AxiosRequestConfig} Axios的请求配置项
 * @param initialValues {any} 响应体的初始值，用于应付渲染时的 undefined 报错警告
 * @param manual {boolean} 是否为手动调用模式，默认false自动调用，改true则需要使用run，onRefresh触发请求
 * @param formatResult {function} 对响应数据进行格式化处理并返回
 * @param onSuccess {function} 响应成功回调函数
 * @param onErr {function} 响应失败回调函数
 * @return Promise<any>
 * */
export function useRequest({
  url,
  params,
  method,
  initialValues,
  manual,
  formatResult,
  onSuccess,
  onErr,
}: IUseRequestConfig) {
  const response = ref(initialValues),
    loading = ref(false);

  // runParams 执行run时的行参
  const run = async (runParams?: object) => {
    if (!loading.value) {
      loading.value = true;

      // 判断要最终要传递的参数 run 的参数优先
      const actualParams = runParams ?? unref(params);
      try {
        const res = await api_fetch({
          url,
          params: actualParams,
          method,
        });

        // 对响应结果处理
        response.value = await (formatResult ? formatResult(res) : res);
        onSuccess?.(response.value, actualParams);
      } catch (e: unknown) {
        onErr?.(e as AxiosError<any, any>);
      } finally {
        loading.value = false;
      }
    }
  };

  if (!manual) run();

  return {
    loading,
    response,
    run,
    onRefresh: async () => await run(),
  };
}
