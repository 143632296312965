import App from './App.vue';
import router from '@/router';
import store from '@/store';
import 'amfe-flexible';
import 'vant/lib/index.css';
import '@/styles/index.scss';
import i18n from './locales/index';
import 'video.js/dist/video-js.css';
import { TUIComponents, TUIChatKit, genTestUserSig } from './TUIKit';
import components from '@/components';
import vue3GoogleLogin from 'vue3-google-login';
import VueHashCalendar from 'vue3-hash-calendar';
import 'vue3-hash-calendar/es/index.css';
const app = createApp(App);
app.use(VueHashCalendar);
app.use(router);
app.use(store);
app.use(i18n);
app.use(components);
app.use(vue3GoogleLogin, {
  clientId: '940947852070-44ekji3gkh32ptf375hds17l1e5cv820.apps.googleusercontent.com',
});
app.mount('#app');

TUIChatKit.components(TUIComponents, app);
TUIChatKit.init();
