import { defineStore } from 'pinia';
import router from '@/router/index';
import { Dialog } from 'vant';
import il8n from '@/locales/index';
import { commonApi } from '@/api/commonApi';
import { Toast } from 'vant';
import { useLoading } from '@/hooks';
import {
  clearLocalStorageExcept,
  clearSessionStorageExcept,
} from '@/utils/clearLocalStorageExcept';
import TencentCloudChat, { ChatSDK } from '@tencentcloud/chat';
import { TUIComponents, TUIChatKit, genTestUserSig } from '@/TUIKit';
import {
  // TUIStore,
  // StoreName,
  TUIConversationService,
  // TUIChatService,
} from '@tencentcloud/chat-uikit-engine';
import { TUILogin } from '@tencentcloud/tui-core';
import { ChatNavigationObject } from './interface';
import { useUserStore } from '@/store';
const { startLoading, stopLoading } = useLoading();

let SDKAppID: number = 0;
let secretKey: string = '';
switch (import.meta.env.VITE_USER_NODE_ENV) {
  case 'development': // 测试环境
    SDKAppID = 50000207;
    secretKey = '5eae02b371d392d3b86006b6cd08e84f398355cc13a792c0cc39758865e739f2';
    break;
  case 'test': // 测试环境
    SDKAppID = 50000207;
    secretKey = '5eae02b371d392d3b86006b6cd08e84f398355cc13a792c0cc39758865e739f2';
    break;
  case 'production': //生产环境
    SDKAppID = 50000206;
    secretKey = '15701037f039c46f2054b0bd8df3f2036a2acdccb69e0390b0832063ce3151d3';
    break;
  default:
    SDKAppID = 50000206;
    secretKey = '15701037f039c46f2054b0bd8df3f2036a2acdccb69e0390b0832063ce3151d3';
    break;
}

export const useTximStore = defineStore('txim', {
  state: () => {
    return {
      chat: TencentCloudChat.create({ SDKAppID }), // ChatSDK实例
      userSigObj: Object(), //userSig 是用户登录即时通信 IM 的密码，其本质是对 UserID 等信息加密后得到的密文。
      options: {
        SDKAppID: SDKAppID,
        secretKey: secretKey,
        userID: '',
      },
      postTxcode: '' as string, // 聊天中对方texcode
      conversationList: localStorage.getItem('conversationList')
        ? JSON.parse(localStorage.getItem('conversationList') as string)
        : Array(), // 会话列表
      conversationID: '', // 单独通话id
      historymessgList: [] as any, // 聊天数据
      toID: '', // 消息接收方的userid
      IssuesissuedObj: Object(),
      ZDYOBJ: {} as any,
    };
  },
  getters: {},
  actions: {
    //消息变更
    SET_MODIFYMESSAGE(item: any, num: any) {
      let this_ = this;
      this_.ZDYOBJ = item;
      if (item.payload.data && item.payload.data[0].text) {
        this_.ZDYOBJ.payload.data[0].text.rate = num;
      } else {
        this_.ZDYOBJ.payload.data = JSON.parse(item.payload.data);
        this_.ZDYOBJ.payload.data[0].text = JSON.parse(this_.ZDYOBJ.payload.data[0].text);
        this_.ZDYOBJ.payload.data[0].text.rate = num;
      }

      let postobj = {
        ...this_.ZDYOBJ,
        payload: {
          data: JSON.stringify(this_.ZDYOBJ.payload.data),
          description: '',
          extension: '',
        },
      };
      // // TUIChatService
      // let promise = this_.ZDYOBJ.modifyMessage({type: postobj.type, payload: {
      //   data: JSON.stringify(this_.ZDYOBJ.payload.data),
      //   description: '',
      //   extension: '',
      // },cloudCustomData: ''})

      // promise.then(function (imResponse) {
      //     const { message } = imResponse.data;

      //     console.log(item.ID, '成功+++++++++++++++++++++++++++00001');
      //   })
      //   .catch(function (imError) {
      //     const { code, data } = imError;
      //     console.log(code, item.ID, '错误+++++++++++++++++++++++++0002');
      //     if (code === 2480) {
      //       // 修改消息发生冲突，data.message 是最新的消息
      //     } else if (code === 2481) {
      //       // 不支持修改直播群消息
      //     } else if (code === 20026) {
      //       // 消息不存在
      //     }
      //   });
      // let onMessageModified = function (event: any) {
      //   console.log('event', event);
      // };
      // this_.chat.on(TencentCloudChat.EVENT.MESSAGE_MODIFIED, onMessageModified);
    },
    // 跳转进入聊天页面
    SET_TO_CHAT(obj: ChatNavigationObject) {
      if (Object.keys(obj).length > 0) {
        this.postTxcode = obj.postTxcode;
        localStorage.setItem('postTxcode', obj.postTxcode);
        router.push({ path: obj.toUrl, query: obj.query });
        TUIConversationService.switchConversation(`C2C${this.postTxcode}`);
      }
    },
    // 会话列表
    SET_CONVERSATION(arr: any) {
      this.conversationList = arr;
      localStorage.setItem('conversationList', JSON.stringify(arr));
    },
    // 刷新处理
    SET_GLOBAL_REFRESH(val: boolean) {
      if (!!val) {
        const txcode = localStorage.getItem('userInfo')
          ? JSON.parse(localStorage.getItem('userInfo') as string).txcode
          : '';
        this.options.userID = txcode;
        !!txcode
          ? this.loginTencentIM()
            .then(() => {
              // 登录成功后的逻辑
              const postTxcode = localStorage.getItem('postTxcode') || '';
              !!postTxcode
                ? TUIConversationService.switchConversation(`C2C${postTxcode}`)
                : false;
              console.log('TXIM登录成功2', this.chat);
            })
            .catch((error) => {
              // 登录失败后的逻辑
              console.log('TXIM登录失败2');
            })
          : false;
      }
    },
    // 腾讯IM登录
    SET_INIT(val: string) {
      this.options.userID = val;
      localStorage.setItem('txcode', val);
      this.loginTencentIM()
        .then(() => {
          console.log('TXIM登录成功', this.chat);
        })
        .catch((error) => {
          // 登录失败后的逻辑
          console.log('TXIM登录失败');
        });
    },
    async loginTencentIM() {
      const userSig = genTestUserSig({
        SDKAppID: this.options.SDKAppID,
        secretKey: this.options.secretKey,
        userID: this.options.userID,
      }).userSig;
      await TUILogin.login({
        SDKAppID: this.options.SDKAppID,
        userID: this.options.userID,
        userSig,
        useUploadPlugin: true,
        useProfanityFilterPlugin: false,
        framework: 'vue3',
      });
    },
    // 退出登录
    SET_LOGOUT(val: boolean) {
      if (!!val) {
        Dialog({
          title: il8n.global.t('确定要退出登录吗？'),
          // message: il8n.global.t('确定要退出登录吗？'),
          showCancelButton: true,
          confirmButtonText: il8n.global.t('确认'),
          cancelButtonText: il8n.global.t('取消'),
          confirmButtonColor: 'blue',
          className: 'globalDialog',
          width: '70%',
        })
          .then(async () => {
            this.logout(val);
          })
          .catch(() => { });
      } else {
        this.logout(val);
      }
    },
    async logout(val: boolean) {
      if (!!val) {
        try {
          startLoading();
          const { code, data, info } = await commonApi.logout({});
          if (code == 1) {
            sessionStorage.clear();
            localStorage.clear();
            // localStorage.getItem('loginInfo')
            //   ? clearLocalStorageExcept(['loginInfo'])
            //   : localStorage.clear();
            // sessionStorage.getItem('language')
            //   ? clearSessionStorageExcept(['language'])
            //   : sessionStorage.clear();
            useUserStore().SER_USERINFI('');
            useUserStore().SET_TOKEN('');
            TUILogin.logout();
            router.push('/register');
            Toast.success(il8n.global.t('退出登录成功'));
          } else {
            Toast.fail(info!);
          }
        } finally {
          stopLoading();
          sessionStorage.clear();
          localStorage.clear();
          useUserStore().SER_USERINFI('');
          useUserStore().SET_TOKEN('');
          TUILogin.logout();
          router.push('/register');
          Toast.success(il8n.global.t('退出登录成功'));
        }
      } else {
        localStorage.getItem('loginInfo')
          ? clearLocalStorageExcept(['loginInfo'])
          : localStorage.clear();
        sessionStorage.getItem('language')
          ? clearSessionStorageExcept(['language'])
          : sessionStorage.clear();
        router.push('/register');
      }
      // setTimeout(() => {
      //   localStorage.clear();
      //   sessionStorage.clear();
      // });
    },
    // 全清退出登录
    SET_CLEAR_LOGOUT(val: boolean) {
      if (!!val) {
        localStorage.clear();
        sessionStorage.clear();
        TUILogin.logout();
        router.push('/login');
      }
    },
    // 清除缓存
    SET_CLEAR_CACHE(val: boolean) {
      if (!!val) {
        Dialog({
          title: il8n.global.t('清除缓存'),
          message: il8n.global.t('清除缓存提示'),
          showCancelButton: true,
          confirmButtonText: il8n.global.t('确认'),
          cancelButtonText: il8n.global.t('取消'),
          confirmButtonColor: 'blue',
          className: 'globalDialog',
          width: '70%',
        })
          .then(async () => {
            localStorage.getItem('loginInfo')
              ? clearLocalStorageExcept([
                'loginInfo',
                'userInfo',
                'sysconfig',
                'txcode',
                'postTxcode',
                'token',
              ])
              : localStorage.clear();
            sessionStorage.getItem('currentActive')
              ? clearSessionStorageExcept(['currentActive'])
              : sessionStorage.clear();
            Toast.success(il8n.global.t('清除缓存成功'));
          })
          .catch(() => { });
      } else {
      }
    },
  },
});
