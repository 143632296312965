import http, { Response } from '@/utils/http';
import ChangePhpne from '../component/changePhpne.vue';

export interface Params { }

export interface UserInfo {
  sex?: number;
}
export interface Mygrablist {
  page: number;
  page_size: number;
  status: string;
}
export interface GrabDetail {
  id: number;
}
export interface Grabupdate<T> {
  assets: T[];
  check_url: string;
  description: string;
  id: string;
  remark: string;
  status: number;
}

export interface ActingPage {
  page_size: number;
  pid: number;
  start_time: string;
  end_time: string;
}

export interface ActingLog {
  change_type: number;
  page: number;
  page_size: number;
  start_time: string;
  end_time: string;
}
export interface UpPhone {
  old_mobile: string;
  old_code: string;
  new_mobile: string;
  new_code: string;
}
export interface UpPwd {
  mobile: string;
  county: string;
  code: string;
  type: number;
  password: string;
  confirm_password: string;
}

export interface SysNotice {
  page: number;
  page_size: number;
  class: string;
}
export interface NewsDetail {
  id: number;
}
export interface BindPhone {
  phone: string;
  code: string;
  county: string;
}
export interface WithdrawLog {
  page: number;
  page_size: number;
}
export interface withdraw {
  amout: string;
  money_pwd: string;
  upi_bank_id: number;
  ip: string;
  withdraw_type: number;
}
export interface BankImg {
  img: string;
}
export interface UpImgMd5 {
  md5: { md5: string; path: string }[];
}
export interface coinParam {
  page?: number,
  page_size?: number,
  month?: string,
  year?: string,
  type?: number
}
export interface payParam {
  payments_id: number;
  pay_amount: string;
  pay_img: string;
  utr: number
}
export interface payList {
  page: number,
  page_size: number
}

export interface revenue {
  page: number,
  page_size: number,
  month: string,
  type: number
}

export interface TaskList {
  page: number,
  page_size: number,
  status: number
}

export interface DetailList {
  page: number,
  page_size: number,
  task_id: number
}
export interface Review {
  grab_id?: number,
  status?: number,
  remark?: string
}

export const API = {
  // 个人信息修改
  async setUserInfo(params: UserInfo) {
    return await http.post<Response<any>>('/api/userInfo/set', params);
  },
  // 我的订单列表
  async mygrablist(params: Mygrablist) {
    return await http.post<Response<any>>('/api/task/mygrablist', params);
  },
  // 订单详情
  async grabDetail(params: GrabDetail) {
    return await http.post<Response<any>>('/api/task/grabDetail', params);
  },
  // 订单详情 提交
  async grabupdate<T>(params: any) {
    return await http.post<Response<any>>('/api/task/grabupdate', params);
  },
  //代理信息
  async agentInformation(params: {}) {
    return await http.post<Response<any>>('/api/agent/agent_information', params);
  },
  // 资金记录
  async agentLog(params: ActingLog) {
    return await http.post<Response<any>>('/api/agent/agent_log', params);
  },
  // 领取奖励
  async agentIndex(params: {}) {
    return await http.post<Response<any>>('/api/agent', params);
  },

  //下级代理
  async subordinate(params: ActingPage) {
    return await http.post<Response<any>>('/api/agent/subordinate', params);
  },

  //下级代理类型列表
  async typeList(params: {}) {
    return await http.post<Response<any>>('/api/agent/type_list', params);
  },

  //图片验证
  async beforeVerifyImages(md5: any) {
    return await http.post<Response<any>>('/api/image/index', md5);
  },
  //图片防重复
  async afterVerifyImages(md5: any, path: any) {
    return await http.post<Response<any>>('/api/image/add', { md5: md5, path: path });
  },
  async bankImg(img: any) {
    return await http.post<Response<any>>('/api/money/bankimg2num', img);
  },

  //展示upi
  async myBank(type: string) {
    return await http.post<Response<any>>('/api/money/mybank', { type: type });
  },
  //领取新人礼
  async newGet(params: Params) {
    return await http.post<Response<any>>('/api/login/new_get', params);
  },
  //上传类型
  async presignedUrl(params: { type: number; path: string }) {
    return await http.post<Response<any>>('/api/get/presigned_url', params);
  },
  //域名前缀
  async domainUrl(params: Params) {
    return await http.post<Response<any>>('/api/get/domainUrl', params);
  },
  // 修改手机号
  async upPhone(params: UpPhone) {
    return await http.post<Response<any>>('/api/user/upPhone', params);
  },
  //修改登录密码或资金密码
  async upPwd(params: UpPwd) {
    return await http.post<Response<any>>('/api/user/upPwd', params);
  },
  //注销账号
  async logOff() {
    return await http.post<Response<any>>('/api/login/logOff');
  },
  //系统公告
  async sysNotice(params: SysNotice) {
    return await http.post<Response<any>>('/api/help/newsiteNotice', params);
  },
  //教程详情
  async getNewsDetail(params: NewsDetail) {
    return await http.post<Response<any>>('/api/help/news_detail', params);
  },
  //绑定手机号
  async bindPhone(params: BindPhone) {
    return await http.post<Response<any>>('/api/bind/phone', params);
  },
  //上传银行卡
  async bankimg2num(params: BankImg) {
    return await http.post<Response<any>>('/api/money/bankimg2num', params);
  },
  //用户提现记录
  async withdrawLog(params: WithdrawLog) {
    return await http.post<Response<any>>('/api/money/withdrawLog', params);
  },
  //用户提现
  async getWithdraw(params: withdraw) {
    return await http.post<Response<any>>('/api/money/withdraw', params);
  },

  //上传图片 md5
  async upImgMd5(params: UpImgMd5) {
    return await http.post<Response<any>>('/api/image/new_add', params);
  },
  // 代理团队列表类型
  async team_type_list() {
    return await http.post<Response<any>>('/api/agent/team_type_list');
  },

  // 拉手币账单明细
  async memberCoinLog(params: coinParam) {
    return await http.post<Response<any>>('/api/user/memberCoinLog', params);
  },
  //会员等级列表
  async memberLevel() {
    return await http.post<Response<any>>('/api/user/level');
  },
  //贡献值流水    
  async dedicationLog(page) {
    return await http.post<Response<any>>('/api/user/dedication-log', { page_size: 20, page });
  },
  //理财-充值
  async payCreate(params: payParam) {
    return await http.post<Response<any>>('/api/investManage/invest/pay_create', params)
  },
  //理财-收款信息配置
  async paylist(params: payList) {
    return await http.post<Response<any>>('/api/investManage/invest/payments/list', params)
  },
  //理财-列表
  async productList(params: payList) {
    return await http.post<Response<any>>('/api/investManage/invest/product/list', params)
  },
  //理财-我的持有-收益明细
  async revenueList(params: revenue) {
    return await http.post<Response<any>>('/api/investManage/invest/log/revenue_list', params)
  },


  //理财-订单-买入产品
  async create(product_id: number, buy_number: number) {
    return await http.post<Response<any>>('/api/investManage/invest/order/create', { product_id, buy_number })
  },
  //理财市场-我的持有-头部信息
  async myHeadInfo() {
    return await http.post<Response<any>>('/api/investManage/invest/order/my_head_info',)
  },

  //订单记录
  async myList(params: payList) {
    return await http.post<Response<any>>('/api/investManage/invest/order/my_list', params)
  },

  //交易记录
  async recordList(params: payList) {
    return await http.post<Response<any>>('/api/investManage/invest/order/record_list', params)
  },

  //违约卖出
  async postSell(id: String) {
    return await http.post<Response<any>>('/api/investManage/invest/order/sell', { 'id': id })
  },

  // 查看理财列表详情  
  async getProductDetail(id: number) {
    return await http.post<Response<any>>('/api/investManage/invest/product/get', { 'id': id })
  },

  //商户入驻-我发布的-列表
  async taskList(params: TaskList) {
    return await http.post<Response<any>>('/api/merchants/merchants/task_list', params)
  },
  //商户入驻-我发布的-发单人任务详情
  async taskDetail(params: { id: number }) {
    return await http.post<Response<any>>('/api/merchants/merchants/task_details', params)
  },
  //商户入驻-我发布的-任务推广数据列表
  async taskPopularPist() {
    return await http.post<Response<any>>('/api/merchants/merchants/task_popular_list')
  },
  //商户入驻-我发布的-点击上热门
  async taskPopular(params: { popular_id: number, task_id: number }) {
    return await http.post<Response<any>>('/api/merchants/merchants/task_popular', params)
  },
  //商户入驻-我发布的-结束任务
  async taskEnd(params: { id: number }) {
    return await http.post<Response<any>>('/api/merchants/merchants/task_end', params)
  },
  //会员信用等级列表
  async creditLevel() {
    return await http.post<Response<any>>('/api/user/credit_level')
  },
  // 商户入驻-接单会员-提交详情列表
  async detailList(params: DetailList) {
    return await http.post<Response<any>>('/api/merchants/merchants/grab/details_list', params)
  },
  //商户入驻-接单会员-详情内容
  async details(params: { id: number }) {
    return await http.post<Response<any>>('/api/merchants/merchants/grab/details', params)
  },
  //商户入驻-接单会员-同意或拒绝
  async review(params: Review) {
    return await http.post<Response<any>>('/api/merchants/merchants/grab/review', params)
  },
  //会员-货币兑换-配置
  async swapConfig() {
    return await http.post<Response<any>>('/api/user/swap_config')
  },
  // 会员-货币兑换
  async swap(param: { number: string, type: number }) {
    return await http.post<Response<any>>('/api/user/swap', param)
  },
}; 
