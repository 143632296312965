<!-- 公共头部 -->
<template>
  <van-nav-bar
    :title="$t(props.title ? props.title : '')"
    @click-left="onClickLeft"
    :right-text="rightText"
  >
    <template #left>
      <van-icon name="arrow-left" color="#263542" size="20" />
    </template>
  </van-nav-bar>
  <div class="down"></div>
</template>

<script lang="ts" setup>
  const router = useRouter();
  const props = defineProps({
    title: {
      type: String,
      default: String,
    },
    rightText: {
      type: String,
      default: '',
    },
  });
  const onClickLeft = () => {
    const flag = window.history.state.back;
    if (flag) {
      window.history.back();
    } else {
      router.push({ path: '/index' });
    }
  };
</script>

<style lang="scss" scoped>
  :deep(.van-nav-bar__content) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 44px;
    z-index: 1;
    background-color: $base-color;

    .van-nav-bar__title {
      font-size: 18px;
    }

    .icon {
      width: 28px;
      height: 14px;
      background-position: 0% 0%;
      background-repeat: no-repeat;
    }
  }

  .down {
    padding-bottom: 44px;
  }
</style>
