<template>
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" v-if="$route.meta.keepAlive" />
    </keep-alive>
    <component :is="Component" v-if="!$route.meta.keepAlive" />
  </router-view>
</template>

<script setup lang="ts">
import debug from '@/utils/debug';
import { useLayoutStore } from '@/store';
import { useTximStore } from '@/store';
import { TUIStore, StoreName, TUIConversationService } from '@tencentcloud/chat-uikit-engine';
import { USE_DEBOUNCE_AND_THROTTLE } from '@/utils/throwingStabilization';
import TencentCloudChat from '@tencentcloud/chat';
import { TUILogin } from '@tencentcloud/tui-core';
import { useUserStore, indexStore } from '@/store';
import { TUITranslateService } from '@tencentcloud/chat-uikit-engine';
// import { commonApi } from '@/api/commonApi';
import { API } from '@/pages/mine/api/index';
const route = useRoute();
const router = useRouter();
const state = reactive({
  intervalId: undefined as number | undefined,
  isShow: true,
});
var eventSource: EventSource | null = null;
const error = ref<string | null>(null);
var SourceUrl = '';
var ipUrl = '';
if (import.meta.env.VITE_USER_NODE_ENV == 'development') {
  ipUrl = '/api/ip';
  SourceUrl = '/api/api/help/pollLatestNotice_new';
} else {
  ipUrl = '/ip'
  SourceUrl = '/api/help/pollLatestNotice_new';
}
watch(
  () => useUserStore().token,
  async (token) => {
    if (token) {
      indexStore().SET_SHOWEQUITY(true);
      // pollLatestNotice();
      let {
        data: { domain },
      } = await API.domainUrl({});
      useUserStore().SET_DOMAIN_URL(domain);
      localStorage.setItem('domainUrl', domain);
    }
  }
);
onBeforeMount(() => {
  const lang = sessionStorage.getItem('language') && sessionStorage.getItem('language') != 'undefined'
    ? JSON.parse(sessionStorage.getItem('language'))
    : { key: 'en-us' };
  fetch(ipUrl, {
    headers: {
      lang: lang.key
    }
  }).then(res => res.text()).then(ip => {
    useUserStore().SET_IP(ip)
  })
})
onMounted(() => {
  // 根据.env中的 VITE_BUILD_VCONSOLE 控制是否开启调试工具 vconsole
  // debug.init();
  console.log('每次都会走一下===============================')
  // state.intervalId = setInterval(siteNotice, 10000) as unknown as number;
  useTximStore().SET_GLOBAL_REFRESH(true);
  useTximStore().chat.setLogLevel(1);
  // 设置腾讯语言包 目前腾讯语音包只有中zh和英en
  setTXIMLanguage();
  pollLatestNotice();
});
//请求站内信，小喇叭
const pollLatestNotice = () => {
  var { key } = (sessionStorage.getItem('language') && sessionStorage.getItem('language') != 'undefined'
    ? JSON.parse(sessionStorage.getItem('language'))
    : { key: 'en-us' });
  eventSource = new EventSource(`${SourceUrl}?lang=${key}`);
  eventSource.onmessage = (event: MessageEvent) => {
    // console.log(JSON.parse(event.data))
    indexStore().set_siteNotice(JSON.parse(event.data));
    // if (indexStore().showEquity) indexStore().set_siteNotice(JSON.parse(event.data));
    // eventSource.close();
  };
  eventSource.onerror = (event: Event) => {
    error.value = 'An error occurred while receiving updates';
    eventSource?.close();
    setTimeout(() => {
      pollLatestNotice();
    }, 5000);
  };
};
watch(
  () => route.fullPath,
  (newPath) => {
    if (newPath) {
      const dataList = router.options.routes[0].children;
      if (Array.isArray(dataList)) {
        dataList.map((el, index) => {
          if (el.path == newPath) {
            useLayoutStore().SET_CURRENTACTIVE(index);
          }
        });
      }
      // if (state.intervalId !== undefined) {
      //   clearInterval(state.intervalId);
      // }
      // state.intervalId = setInterval(siteNotice, 10000) as unknown as number;

      txWatch();
      useUserStore().userIp ? false : useUserStore().SET_USERIP(true);
    }
  }
);
onUnmounted(() => {
  if (eventSource) {
    eventSource.close();
  }
});
const playSound = () => {
  // 创建音频对象
  const audio = new Audio('https://testtxindiancdn.westbury88.com/site/message.mp3');
  audio.play();
};

const playSoundDebounced = USE_DEBOUNCE_AND_THROTTLE(playSound, 3000, 'debounce');
// 腾讯IM监听
const txWatch = () => {
  // 监听腾讯未读消息
  TUIStore.watch(StoreName.CHAT, {
    newMessageList: (newMessageList) => {
      if (Array.isArray(newMessageList)) {
        playSoundDebounced();
        console.log('未读消息', newMessageList);
      }
    },
  });
  // 监听腾讯会话列表
  TUIStore.watch(StoreName.CONV, {
    conversationList: (conversationList) => {
      if (Array.isArray(conversationList)) {
        useTximStore().SET_CONVERSATION(conversationList);
        console.log('会话列表', conversationList);
      }
    },
  });
  // 监听同一账号，多端登录被踢
  useTximStore().chat.on(TencentCloudChat.EVENT.KICKED_OUT, (event: any) => {
    TUILogin.logout();
  });
  // 监听消息被撤回
  useTximStore().chat.on(TencentCloudChat.EVENT.MESSAGE_REVOKED, (event: any) => {
    console.log('监听消息被撤回', event);
  });
};

const setTXIMLanguage = () => {
  const params = sessionStorage.getItem('language') && sessionStorage.getItem('language') != 'undefined'
    ? JSON.parse(sessionStorage.getItem('language') as string).value
    : 'en';
  TUITranslateService.changeLanguage(params);
};
</script>

<style>
#app {
  font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  /* vant 样式重置 */
  /* 主色调 */
  --van-primary-color: #5788ff;

  /* 按钮 */
  /* 按钮圆角 */
  --van-button-border-radius: 5px;
  /* 默认按钮高度 */
  --van-button-large-height: 42px;

  /* tabs 组件 */
  /* tab选中下划线 */
  --van-tabs-bottom-bar-color: var(--van-primary-color);
}
</style>
