export default {
  说明: 'explain',
  '违约金：提前卖出需支付30%的违约金、已得收益利息需扣除': ' Penalty for breach of contract: A penalty of 30% must be paid for early sale, and the interest earned must be deducted',
  '利息计算：预计利息=（本金 * 年化利率 / 365）*存期': 'Interest calculation: Estimated interest=(principal * annualized interest rate/365) * term of deposit',
  '利息发放：凌晨00：00到账个人账户余额': 'Interest payment: credited to personal account balance at 00:00 in the morning',
  预计利息: 'Expected interest',
  份数: 'Number of copies',
  买入份数: 'Purchase quantity',
  您已经满级了哦: 'You have reached the maximum level.',
  登录: 'Login',
  注册: 'Register',
  热门任务: 'Hot task',
  数量: 'Quantity',
  最快赚钱方式: 'Fastest Way To Make Money',
  赚钱: 'Make Money',
  如何赚钱: 'How to make money',
  合作伙伴: 'Partner',
  首页: 'Home',
  接单: 'Receive Orders',
  任务大厅: 'Dispatch Hall',
  消息: 'Information',
  我的: 'My',
  智能排序: 'Smartsorting',
  推荐: 'Recommend',
  限时折扣: 'Limitdiscounts',
  筛选: 'Filter',
  单价最高: 'The Highest Unit Price',
  结算最快: 'Fastest Settlement',
  完成最多: 'Mostaccomplished',
  订单最多: 'Mostorders',
  审核最快: 'Fastestreview',
  确定: 'Sure',
  取消: 'Cancel',
  加载中: 'Loading',
  实名认证: 'Verified',
  先行付款: 'Pay Inadvance',
  平台担保: 'Platform Guarantee',
  企业认证: 'EntCert',
  结算最多: 'Mostsettled',
  评价最多: 'Mostrated',
  聊天: 'Chat',
  我想: 'Ithink',
  充值: 'Topup',
  提现: 'Withdraw',
  提现方式: 'Withdraw method',
  提现金额: 'Withdraw amount',
  提现密码: 'Withdraw password',
  查看余额: 'Balance',
  查看单价: 'Price',
  查看工期: 'Check Period',
  在此输入消息: 'Enter Your Message Here',
  没有更多了: 'No More',
  份已提交: 'Submit',
  支付: 'pay',
  平台审核: 'Review',
  完成率: 'Completion Rate',
  通过率: 'Passing Rate',
  结算速度: 'Settlement Speed',
  查看详情: 'Check Details',
  招标中: 'Under Bidding',
  有效时间: 'Effective Time',
  任务描述: 'Mission Details',
  任务类型: 'Task Type',
  任务单价: 'Task Price',
  接单条件: 'Conditions For Accepting Orders',
  结算方式: 'Settlement Method',
  任务要求: 'Mission Requirements',
  任务有效时间: 'Task Effective Time',
  任务最后完成时间: 'The Final Task Completion Time',
  资源文件: 'Resource',
  系统消息: 'System Information',
  官方客服: 'Official Customer Service',
  商户独聊: 'Merchant Private Chat',
  会员独聊: 'Vip Private Chat',
  代聊: 'Chat Onbehalfof',
  最近聊天: 'Recent Chat',
  我的余额: 'My Balance',
  我的积分: 'My Integral',
  提款中: 'Withdraw',
  去提现: 'Go Withdraw',
  收款账户: 'Account',
  资金明细: 'My Fund',
  订单中心: 'Order Center',
  全部: 'All',
  系统设置: 'System Settings',
  账户与安全: 'Account Security',
  联系客服: 'Contact Customer Service',
  清除缓存: 'Clear Cache',
  清除缓存提示: 'Confirm to clear the cache and all data will be cleared.',
  检查更新: 'Check For Updates',
  退出登录: 'Sign Out',
  新增USDT地址: 'Add USDT Address',
  银行卡: 'Bank Card',
  新增银行卡: 'Add Your Bank Account Detail',
  添加银行卡: 'Add Bank Card',
  删除银行卡: 'Are you sure to delete this bank card?',
  无银行卡: 'No bank card',
  设置提现密码: 'You have not set a payment password yet，please go and set it!',
  设置手机号: "You haven't bound your mobile number yet, please go and bind it!",
  不可用银行卡: 'Disabled',
  新增银行卡最大限制: 'A maximum of {n} cards can be added.',
  选择主网: 'Choose Mainnet',
  '输入钱包/交易所充值地址': 'Enter Wallet/Exchange Deposit Address',
  请输入地址: 'Pleaseenter Adress',
  备注信息: 'Remarks',
  请输入银行名称: 'Bank Name',
  请输入银行卡号: 'Account Number',
  请输入银行编码: 'IFSC CODE',
  请输入持卡人姓名: 'Account Holder Name',
  输出: 'Output',
  有效期: 'Validity Period',
  详情: 'Details',
  有效期限: 'Validity Period',
  任务详情: 'Mission Details',
  订单编号: 'Order Number',
  总金额: 'lump Sum',
  上传凭证: 'Upload Certificate',
  可以上传多张凭证: 'Multiple Vouchers Can be Uploaded',
  提交审核: 'Submit',
  个人信息: 'My Profile',
  头像: 'Profile Photo',
  昵称: 'Nick Name',
  性别: 'Gender',
  用户名: 'username',
  简介: 'Introduction',
  修改昵称: 'Change Username',
  男: 'Male',
  女: 'Female',
  编辑简介: 'Editors Introduction',
  账号安全: 'Account Security',
  修改手机号: 'Change Phone Number',
  修改登陆密码: 'Change Login Password',
  修改支付密码: 'Change Pay Password',
  您当前绑定的手机号: 'Your Currently Cound Mobile Phone Number',
  原手机无法接收短信: 'The Original Mobile Phone Cannot Receive Text Messages',
  '通过IM联系客服进行审核，通过后即可修改':
    'Contact Customer service via IM for review, and you can modify it after passing it.',
  联系: 'Contact',
  人工客服: 'Human Customer Service',
  下一步: 'Next',
  输入新的手机号: 'Enter New Mobile Number',
  输入验证码: 'Enter Confirmation Code',
  确认: 'Confirm',
  设置新密码: 'Set New Password',
  确认新密码: 'Confirmthe New Password',
  设置新的支付密码: 'Set New Payment Password',
  验证码登录: 'Verification Code Login',
  手机号码: 'Phone Number',
  请输入您的账号: 'Please enter your account number',
  请输入您的手机号码: 'Pleaseenter Your Phone Number',
  请输入您的Google邮箱: 'Please enter your Google email address',
  请输入正确的Goole邮箱: 'Please enter a valid Google email address',
  请输入您的旧手机号码: 'Please enter your old phone number',
  请输入您的新手机号码: 'Please enter your new phone number',
  输入旧手机验证码: 'Enter the old phone verification code',
  输入新手机验证码: 'Enter the new phone verification code',
  密码: 'Password',
  密码必须至少6个字符: 'Password Must be at least 6 Characters',
  记住密码: 'Remember Password',
  Flexilndia新用户: 'Flexilndia Newuser',
  立即注册: 'Sign Up',
  一键登录: 'One-click Login',
  密码登录: 'Password Login',
  验证码: 'verification Code',
  请输入手机验证码: 'Please Enter the Mobile Phone Verification Code',
  发送验证码: 'Send Code',
  新密码: 'New Password',
  确认密码: 'Confirm Password',
  完成: 'Finish',
  注册并登录: 'Register and Log in',
  请输入正确的账号: 'Please enter a valid account number',
  调度大厅: 'Dispatch Hall',
  调度详情: 'Scheduling Details',
  删除: 'Delete',
  凭证: 'Certificate',
  返回: 'Return',
  未定义: 'Undefined',
  用户协议: ' User Agreement',
  协议: 'Privacy Protection',
  协议2:
    ' In order to better protect your legitimate rights and interests, please read and agree to the following agreements "Special User Service Agreement", "Platform Service Agreement", "Privacy Policy", "Other User Service Agreement" and log in with your account。',
  同意: 'Agree',
  不同意: 'Disagree',
  输入您的电话号码: 'Enter Your Phone Number',
  手机号不能为空: 'Mobile Phone Number Cannot be Empty',
  数字或者字母: 'At least 6 characters',
  手机号必须为十位数: 'Mobile Phone Number must be ten Digits',
  验证码不能为空: 'Verification Code Cant  be Blank',
  只能输入数字: 'Numbers Only',
  已发送: 'Has Been Sent',
  请先获取验证码: 'Get a Verification Code',
  密码不能为空: 'Password Cant  be Blank',
  新密码不能为空: 'New Password Cannot be Empty',
  '2次密码不一致': ' Two Passwords  Are Invalid',
  手机号或者密码不能为空: 'Mobile Phone Number or Password Cant   be Blank',
  密码不能小于6位: 'Password Cannot be Tess Than 6 Characters',
  暂未开放: 'Not Open Yet',
  手机号码只能输入数字: 'Only numbers can be entered',
  验证码只能输入数字: 'Verification Code Only Enter Numbers',
  灵活: 'Flexible',
  印度: 'India',
  退出成功: 'Exit Successfully',
  已清理: 'Cleaned',
  已是最新版本: 'New Version',
  灵活印度: 'Flexi India',
  热的: 'Hot',
  工作: 'Jobs',
  选择语言: ' Choose language',
  请输入正确的银行名称: 'Please Enter The Correct Bank Name',
  请输入银行名称2: 'Please Enter Bank Name',
  请输入银行卡号2: 'Please Enter Account Number',
  请输入银行编码2: 'Please Enter IFSC CODE',
  请输入持卡人姓名2: 'Please Enter Account Holder Name',
  'ifsc必须为11位,第五位必须为0': 'ifsc must be 11 bits, the fifth bit must be 0',
  请输入正确的银行卡号: 'Please Enter The Correct Account Number',
  请输入正确的银行编码: 'Please Enter The Correct IFSC Code',
  请输入正确持卡人姓名: 'Please Enter The Correct Account Holder Name',
  昨天: 'Yesterday',
  今天: 'Today',
  近一周: 'Weekly',
  近一月: ' Monthly',
  选择日期: 'Date',
  查询: 'Search',
  元: 'INR',
  支付订单: 'Pay Order',
  订单退还: 'Order Return',
  本人任务加款: 'Finish Task Get Money',
  本人任务扣减: 'Tasks Deduction',
  下级返佣: 'Agent Commission',
  提现失败退还: 'Withdrawal Failed',
  管理员加款: 'System Add Money',
  管理员扣款: 'System Deduction',
  未知类型: 'Unknown Type',
  真实姓名: 'Actual Name',
  身份证: 'ID card',
  修改身份证: 'Modify ID Card',
  添加真实姓名: 'Add Your Real Name',
  请输入简介: 'Please Enter Lntroduction',
  请输入有效的昵称: 'Please Enter a Valid Nickname',
  不能为空: 'Can Not be Empty',
  请输入正确12位的身份证: 'Please Enter the Correct 12-digit ID Card',
  不能包含数字和汉字: 'Cannot Contain Numbers and Chinese Characters',
  修改登录密码: 'Change Login Password',
  '原手机无法接收短信？': 'Cant Receive Text Messages on Your Original Phone?',
  '通过IM联系客服进行审核，通过后即可更改':
    'Contact Customer Service via IM for review, and changes can be made after approval.',
  确认密码不能为空: 'Confirm Password Can Not be Blank',
  密码设置成功: 'Password Set Successfully',
  我的订单: 'My Order',
  每人只能接一次: 'Each Person Can Only Pick Up Once',
  每人同时只有一次: 'Only 1 Task per ID at the same time',
  已支付: 'Paid',
  未支付: 'Unpaid',
  暂无数据: 'No Data',
  刷新成功: 'Refresh Successful',
  日期选择: 'Date Selection',
  点击查看历史消息: 'Click To View Historical Messages',
  请登陆: 'Please Log In !',
  提前全部支付: 'Pay Advance',
  逐单支付: 'Pay Order',
  未知: 'Unknown',
  聊天室: 'Chat Room',
  两次密码不一致: 'Two Different Passwords',
  重新连接: 'Reconnect',
  成功: 'Successful',
  失败: 'Fail',
  已结束: 'Over',
  审核中: 'Review',
  进行中: 'Progress',
  注册赠送: 'Sign Up And Get Money',
  补单加款: 'Fill Add',
  补单减款: 'Fill Minus',
  注销账户: 'Delete Account',
  领取奖励: 'Bonus',
  已复制: 'Copied',
  文本描述: 'Text Description',
  链接描述: 'Link Description',
  超过最大任务数: 'Over Max  Tasks, Please Try Again',
  您有新的消息: 'You Have New News',
  评论: 'Remark',
  请输入银请输入正确的银行卡号行卡号: 'Please Enter Correct Account Number',
  签到: 'Sign ln',
  提交任务: 'Submit task',
  null: 'null',
  '登录密码需6-16位字母或数字': 'Login password requires 6-16-bit letters or numbers',
  '请输入真实手机号,否则无法提款': 'Withdrawal not possible without Bank registered mobile number',
  验证码需6位: 'The verification code requires 6 digits',
  新的FlexibleIndia: 'new to FlexibleIndia',
  '确定要退出登录吗？': 'Are you sure you want to exit and log in?',
  退出登录成功: 'Log out successfully',
  复制成功: 'Copy successfully',
  请输入昵称: 'Please enter a nickname',
  ID: 'ID',
  修改真实姓名: 'Change your real name',
  请输入真实姓名: 'please enter your real name',
  请输入身份证: 'Please enter your ID card',
  上传需要JPG或PNG格式: 'Upload requires JPG or PNG format',
  上传大小不能超过1MB: 'Upload size cannot exceed 1MB',
  热门: 'Hot',
  新的: 'New',
  没有数据了: 'No data',
  加载数据失败: 'Loading data failed',
  份已完成: 'Copies completed',
  已发放金额: 'Amount issued',
  抢单: 'Grab an Order',
  抢单1: 'Grab an Order',
  抢单2: 'Grab an Order',
  抢单3: 'Grab an Order',
  抢单4: 'Grab an Order',
  抢单5: 'Grab an Order',
  抢单6: 'Grab an Order',
  抢单7: 'Grab an Order',
  抢单8: 'Grab an Order',
  抢单9: 'Grab an Order',
  提交: 'Submit',
  提交1: 'Submit',
  客服: 'Customer Service',
  客服1: 'Customer Service',
  请输入数字: 'Please enter the number',
  抢单详情: 'Grab details',
  每人每天只能接一单: 'Each person can only take one order per day',
  支付中: 'Payments',
  逐单支付中: 'Payment by order',
  全部支付完成: 'All Pay',
  '请输入...': 'please enter...',
  已提交: 'Submitted',
  审核通过: 'Examination passed',
  审核通过1: 'Examination passed',
  审核不通过: 'Review failed',
  冻结: 'Freeze',
  暂停: 'Pause',
  审核拒绝: 'Audit rejection',
  分享链接: 'Share Link',
  新的手机号: 'New phone number',
  新的密码: 'New password',
  新的支付密码: 'New payment password',
  确认新的支付密码: 'Confirm new payment password',
  新的支付密码需6位: 'New payment passwords need 6 digits',
  确认新的支付密码需6位: 'To confirm that the new payment password requires 6 digits',
  两次支付密码不一致: 'Two payment passwords are inconsistent',
  新的支付密码需数字: 'New payment passwords need to be numbered',
  确认新的支付密码需数字: 'Confirm that the new payment password needs to be numbered',
  代理中心: 'Agency Center',
  代理须知: 'Agency Notice',
  最新: 'Up to date',
  资金详情: 'Details Of Funds',
  我的团队: 'My Team',
  资金类型: 'Fund type',
  选择时间: ' Please select time',
  时间: 'Time',
  金额: 'Amount',
  收入: 'Income',
  类型: 'Type',
  欢迎登录: 'Welcome to login',
  账号: 'Account',
  短信: 'SMS',
  还没有帐户吗: 'No account',
  现在注册: 'Sign up',
  欢迎注册: 'Welcome to register',
  有帐户: 'Have an account',
  发送: 'Send',
  忘记密码: 'Forgot The Password',
  分享: 'Share',
  手机号不存在: 'Mobile phone number does not exist',
  请输入手机号: 'Please enter phone number',
  验证码错误: 'Verification code error',
  手机号已存在: 'Mobile phone number already exists',
  订单号: 'Order number',
  清除缓存成功: 'Clear cache succeeded',
  对话列表: 'Conversation list',
  联系人列表: 'Contact list',
  新增upi地址: 'Add upi address',
  每人终生只能接一次: 'Each person can only pick up once a lifetime',
  每人同时只有一单: 'There is only one order per person at the same time',
  每人同时接多单: 'Each person takes multiple orders at the same time',
  '每人每天只能接一单(规定提交时间)':
    'Each person can only receive a order every day (specified for submission time)',
  每人每天接一单: 'Each person will receive a order per day',
  '每人每天都可接一单(且此任务当日可以提交)': 'Each person can take one order every day (and this task can be submitted on the same day)',
  上传图片重复: 'Upload pictures repeat',
  请输入upi地址: 'Please enter upi address',
  最热: 'Hot',
  收藏: 'Collect',
  教程: 'Teach Tutorial',
  教学详情: 'Teach Detail',
  客户服务: 'Customer Service',
  新手任务: 'Novice Task',
  如何抢任务: 'How to grab tasks',
  如何提现: 'How to withdraw funds',
  如何找到客服: 'How to find customer service',
  步骤: 'Step',
  选择任务并领取订单: 'Select a task and grab an order.',
  提交任务所需的信息以供审核: 'Submit the required information for the task for review.',
  完成任务后获得佣金并实时提现到您的账户:
    'Get commission after completing the task,and withdraw it to your account in real time.',
  点击个人中心页面进入提现页面: 'Click on the Personal Center page to enter the withdrawal page.',
  添加所选付款的银行卡信息: 'Add the bank card information selected for payment.',
  填写提现金额和支付密码然后您可以开始申请提现:
    'Fill in the withdrawal amount and payment password and then you can start applying for withdrawal.',
  关于任务: 'About Task',
  如果您在接收任务时不知道具体流程您可以点击客户进行咨询:
    "If you don't know the specific process when receiving a task, you can click on the customer for consultation.",
  关于其他问题: 'About Other Question',
  选择在线客服: 'Select online customer service.',
  选择您要咨询的问题类型并与专业客服进行一对一咨询:
    'Select the type of question you want to consult and have a one-on-one consultation with professional customer service.',
  完成任务获得: 'Complete the task to get ',
  领取任务: 'Grab task',
  找客服: 'Ask Question',
  设置信息: 'Set Message',
  上一页: 'Previous page',
  下一页: 'Next',
  领取: 'Receive',
  未设置: 'Not set',
  请设置性别: 'Please set gender',
  请绑定您的手机号码: 'Please bind phone number',
  谷歌邮箱: 'Google Mail',
  请绑定您的谷歌邮箱: 'Please bind Google email',
  绑定谷歌邮箱: 'Bind Google Email',
  添加手机号: 'Change Phone Number',
  账号必须大于6位: 'The account must be greater than 6 digits',
  任务ID: 'Task ID',
  任务量已满: 'Task volume is full',
  不建群: 'Do not create a group',
  facebook群: 'Facebook group',
  whatsapp群: 'Whatsapp group',
  telegram群: 'Telegram group',
  线下任务: 'Offline tasks',
  完成别的订单后才可提交: 'Submit only after completing other orders',
  tg两千人大群收集: 'TG 2,000 people gathered in a large group',
  请登录: 'please sign in',
  注销账号: 'Cancel account',
  提示: 'Tips',
  确认注销此账号吗: 'Are you sure to cancel this account?',
  注销成功: 'Logout successful',
  上传视频: 'Upload Video',
  可选择上传视频: 'Option to upload video',
  系统通知: 'System',
  '在接受订单之前,您需要完成新手教程!':
    'You need to complete the novice tutorial before accepting orders!',
  去完成: 'Go complete',
  手机号未注册: 'Mobile phone number is not registered',
  未绑定: 'Not bound',
  记录: 'Record',
  提现记录: 'Withdraw Record',
  请上传银行卡: 'Please upload your bank card',
  重新发送: 'Resend',
  视频: 'video',
  上传图片: 'Please upload pictures',
  视频演示: 'Video demonstration',
  转账中: 'In transfer',
  转账成功: 'Transfer successful',
  转账失败: 'Transfer failed',
  人工终结: 'Manual termination',
  三方超时: 'Tripartite timeout',
  补单: 'Supplementary order',
  会员权益: 'MEMBERSHIP BENEFITS',
  会员享受4大福利: 'MEMBERS ENJOY 4 MAJOY BENEFITS',
  每日入住奖励: 'daily Check-In Bonus',
  高佣金任务: 'high Commission Tasks',
  代理机制: 'agency Mechanism',
  收益保证: 'earnings Guarantee',
  退出: 'QUIT',
  特权: 'PRIVILEGE',
  详细资料: 'DETAILS',
  每日入住可获得10卢比超过10天不入住的会员将被取消会员资格:
    'receive 10 rupees for daily check-ins. Members who do not check in for more than 10 days will be removed from the membership group.',
  优先权接受高佣金任务提款优先:
    'priority rights to accept high commission tasks.Withdrawal priority.',
  优先参与代理机制: 'priority participation in the agency mechanism.',
  如果您在一个月内没有赚取10000卢比您的会员费将直接退还:
    'if you do not earn 10,000 rupees within one month, your membership fee will be refunded directly.',
  排行榜: 'Ranking List',
  了解详情: 'Learn more',
  邀请码: 'Invitation code',
  邀请链接: 'Invitation link',
  邀请二维码: 'Invite QR code',
  点击按钮保存或截图将二维码保存到手机:
    'Click the button to save or take a screenshot to save the QR code to your phone',
  保存到手机: 'Save To Phone',
  总返佣: 'commission',
  接单总数: 'Total orders',
  总人数: 'people',
  今天的佣金: `Today's commission`,
  昨天的佣金: `Yesterday's commission`,
  周奖励: 'Weekly rewards',
  月奖励: 'Monthly rewards',
  代理通知: 'Agency Notice',
  排名: 'Ranking',
  会员信息: 'VIP Info',
  未上榜: 'Not on the list',
  我: 'Me',
  提现排行榜: 'Withdrawal Ranking',
  接单排行榜: 'Order Ranking',
  昨日榜: 'Yesterday',
  今日榜: 'Today',
  周榜: 'Weekly',
  月榜: 'Monthly',
  总榜: 'Overall',
  提现金额1: 'Amount',
  代理: 'Primary Agency',
  请选择一个时间: 'Please select a time',
  下级会员: 'junior member',
  '例如，当 A 邀请 B，B 邀请 C，C 邀请 D，而 D 提现时：': 'For example, when A invites B, B invites C, and C invites D, and D withdraws funds:',
  'A 可以获得高达 0.5% 的佣金': 'A can earn up to {0}% commission ',
  'B 可以获得高达 1% 的佣金': 'B can earn up to {0}% commission',
  'C 可以获得高达 1.4% 的佣金': 'C can earn up to {0}% commission',
  '三层邀请关系之外没有佣金。': 'commission. There is no commission for invitation relationships beyond three levels.',
  代理条件: 'Agency condition',
  代理等级: 'Agent level',
  直接会员提现限额: 'Direct member withdrawal limit',
  直接会员人数: 'Number of direct members',
  代理返佣比例: 'Agency rebate ratio',
  一级代理: 'Primary Agency',
  二级代理: 'Secondary Agency',
  三级代理: 'Tertiary Agency',
  代理升级: 'Proxy upgrade',
  '代理等级每月 15 日 00:00 计算。计算完成后实时生效': 'The agent level is calculated at 00:00 on the 15th of every month. After calculation Real time effect',
  加入群聊: 'Join group chat',
  直属下级: 'subordinate',
  我已阅读并同意: 'I have read and agree to the',
  注册协议: 'Registration Agreement',
  和: 'and',
  隐私政策: '《Privacy Policy》',
  请勾选协议: 'Please check the agreement',
  领取成功: 'Successfully claimed',
  签到中心: 'Sign in Center',
  规则: 'rule',
  拉手币: 'Handle Coin',
  已连续签到: 'Sign in',
  天: 'day',
  剩余补签次数: 'Remaining number of re-signings',
  签到奖励: 'Sign-in Rewards',
  奖励: 'Reward',
  连续签到: 'Consecutive Sign-In',
  规则说明: 'Rule Description',
  活动规则: 'Event Rule',
  每日签到奖励: 'Daily Sign-In Reward',
  漏签后一个月内可补签: 'Make up sign-in within one month after missing',
  次: 'Times',
  次数不转存: 'Number of times not stored',
  补签一次扣除: 'Deduct for one make-up sign-in',
  连续签到固定天数后可领取随机大额拉手币: 'Receive random large amount of Lashou coins after fixed days of consecutive sign-in',
  仅显示并记录当月签到记录: 'Only display and record sign-in records of the current month',
  签到成功: 'Sign-In Successful',
  不能签到未来的日期: 'Cannot sign in to future dates',
  贡献值: 'Contribution',
  立刻充值: 'Recharge',
  立刻提现: 'Withdrawal',
  协议及政策: 'Agreements and Policies',
  隐私协议: 'Privacy Policy',
  我的拉手币: 'My handle coin',
  选择年月: 'Select year and month',
  连续签到5天奖励: 'Rewards for signing in for 5 consecutive days',
  连续签到15天奖励: 'Reward for signing in for 15 consecutive days',
  连续签到30天奖励: 'Reward for signing in for 30 consecutive days',
  补签扣款: 'Deduction for re-signing',
  抽奖: 'lottery',
  获得: 'get',
  消耗: 'consume',
  年: 'year',
  月: 'month',
  账单类型: 'Bill type',
  成长值: 'growth value',
  会员: 'member',
  需要: 'need',
  距下一等级还差: 'Still far from the next level',
  还未到当前等级: 'Not yet at the current level',
  中奖记录: 'Winning Records',
  奖金: 'Bonus',
  开始: 'Start',
  剩余次数: 'the rest',
  点击抽奖: 'Click to Draw',
  中奖名单: 'Winners List',
  奖品: 'Prize',
  抽奖说明: 'Lottery Instructions',
  '1.会员等级：获得会员等级后可参与抽奖；不同等级对应不同抽奖次数': '1. Member Level: Participate in the lottery after obtaining a member level; different levels correspond to different draw chances',
  会员等级: 'Member Level',
  抽奖次数: 'Lottery Chances',
  单次消耗: 'single consumption',
  '2.奖品设置：小米手机、摩托车、日用品、电子产品等；中奖率高达100%': '2. Prize Settings: Xiaomi phone, motorcycle, daily necessities, electronic products, etc.; winning rate up to 100%',
  '3.奖品兑换：抽中奖品后，填写收货地址，静待收货；中奖后7天未兑换，视为自动放弃本次奖品': '3. Prize Redemption: After winning a prize, fill in the shipping address and wait for delivery; if not redeemed within 7 days after winning, it is considered as automatically forfeited',
  '1.为广大会员提供福利，平台决定将每日营业收入的10%纳入奖池': '1. To provide benefits to the vast number of members, the platform decides to include 10% of daily revenue into the prize pool',
  '2.奖池累计足额现金后将会随机在抽奖中掉落中奖机会': '2. After the prize pool accumulates sufficient cash, winning opportunities will randomly drop in the lottery',
  '3.会员级别越高，被抽中的概率越高哦': '3. The higher the member level, the higher the probability of being selected',
  恭喜您成功抽到: 'Congratulations on Successfully Winning',
  很遗憾: 'Very Regretful',
  立即兑换: 'Redeem Now',
  再来一次: 'Try Again',
  '抽奖次数将消耗拉手币请知悉!': 'Please note that lottery attempts will consume Lasho coins!',
  不在提示: 'Do not prompt again',
  我知道了: 'I understand',
  待兑奖: 'Pending Collection',
  已兑奖: 'Redeemed',
  已失效: 'expired',
  中奖者: 'winner',
  中奖时间: 'Winning time',
  兑奖时间: 'redemption time',
  结束时间: 'End Time',
  有效期至: 'Valid until',
  兑换: 'exchange',
  查看订单: 'check order',
  请注册: 'Please register',
  收货信息补充: 'Receipt information supplement',
  收货人名称: 'Consignee name',
  请输入收货人名称: 'Please enter the consignee name',
  联系方式: 'Contact information',
  请输入联系方式: 'Please enter contact information',
  收货人地址: 'recipient address',
  请输入收货人地址: 'Please enter the consignee address',
  备注: 'Remark',
  请输入备注: 'Please enter remarks',
  添加成功: 'Added successfully',
  兑奖成功: 'Redeem successfully',
  手机号: 'Phone number',
  订单详情: 'order details',
  收货信息: 'delivery information',
  订单信息: 'order information',
  兑换单号: 'Exchange number',
  物流单号: 'shipment number',
  // 理财
  我的理财: 'My Finance',
  理财市场: 'Financial Market',
  我的持有: 'My Holdings',
  年化利率: 'Annual Interest Rate',
  当前资产: 'Current Assets',
  昨日收益: "Yesterday's Earnings",
  本月收益: 'Earnings This Month',
  收益明细: 'Earnings Details',
  交易记录: 'Transaction Records',
  总收益: 'Total Earnings',
  买入: 'Buy',
  买入金额: 'Buy Amount',
  付款方式: 'Payment Method',
  账户余额: 'Account Balance',
  基金委托理财说明书: 'Fund Entrusted Financial Instructions',
  付款: 'Payment',
  卖出: 'Sell',
  利息: 'Interest',
  违约金: 'Penalty',
  到期卖出: 'sell on expiration',
  提前卖出: 'sell early',
  余额不足: 'Insufficient balance',
  '买入后2日内卖出将根据协议扣除信誉违约金，信誉违约金=购买金额的30%，确认卖出吗？': 'If you sell within 2 days after buying, you will be charged a credit default penalty according to the agreement. The default credit penalty = 30% of the purchase amount. Do you confirm the sale?',
  '买入后2日内卖出将根据协议扣除信誉违约金，信誉违约金=购买金额的30%，确认买入吗？': 'If you sell within 2 days after buying, a credit penalty will be deducted according to the agreement. Credit penalty = 30% of the purchase amount. Do you confirm the purchase?',
  请阅读并同意: 'Please read and agree',
  充值金额: 'Recharge amount',
  请输入金额: 'Please enter the amount',
  请输入: 'please enter',
  充值凭证: 'Recharge voucher',
  请输入充值金额: 'Please enter the recharge amount',
  请选择充值渠道: 'Please select a recharge channel',
  请输入12位数字的UTR: 'Please enter a 12-digit UTR',
  请上传凭证: 'Please upload the certificate',
  去充值: 'Go to recharge',
  充值账户: 'Recharge account',
  理财产品充值: 'Recharge financial products',
  理财产品买入: 'Financial product purchase',
  理财产品产生的金额: 'Amount generated by financial products',
  理财产品卖出: 'Sell ​​financial products',
  理财产品违约卖出: 'Financial products sold on default',
  账变类型: 'account change type',
  佣金: 'commission',
  余额: 'balance',
  任务: 'Task',
  论坛: 'forum',
  '充值申请已提交，待审核！': 'The recharge application has been submitted and is pending review!',
  点赞: 'Like',
  发布: 'Post',
  游戏: 'Game',
  生活: 'Life',
  新闻: 'News',
  收起: 'Collapse',
  展开: 'Expand',
  待审核: 'Pending Review',
  已通过: 'passed',
  未通过: 'Did not pass',
  复制链接: 'Copy Link',
  热门推荐: 'Popular Recommendations',
  删除帖子: 'Delete Post',
  编辑帖子: 'Edit Post',
  确认删除吗: 'Confirm Deletion?',
  删除成功: 'Deleted Successfully',
  发布动态: 'Post Update',
  '快来发布动态吧~': 'Come and post an update~',
  请输入内容: 'Please enter content',
  动态详情: 'Update Details',
  全部评论: 'All Comments',
  作者: 'Author',
  回复: 'Reply',
  '友善评论，文明发言': 'Friendly comments, civil speech',
  搜索: 'Search',
  请输入搜索关键词: 'Please enter search keywords',
  上热门: 'Trending',
  热门时长: 'Trending Duration',
  论坛帖子上热门: 'Popular forum posts',
  '已发布，待审核': 'Published, pending review',
  '确认删除搜索记录吗？': 'Are you sure you want to delete your search history?',
  图片上传失败: 'Image upload failed',
  发布任务: "Publish Task",
  任务标题: "Task Title",
  请输入任务标题: "Please Enter Task Title",
  请输入任务要求: "Please Enter Task Requirements",
  任务单数: "Number of Tasks",
  请输入任务单数: "Please Enter Number of Tasks",
  请输入任务单价: "Please Enter Task Price",
  任务开始时间: "Task Start Time",
  点击选择时间: "Click to Select Time",
  请选择任务开始时间: "Please Select Task Start Time",
  预付佣金: "Prepaid Commission",
  手续费: "Service Fee",
  实付金额: "Actual Payment Amount",
  支付并发布: "Pay and Publish",
  确认你的任务信息: 'Confirm your mission information',
  我发布的: 'I posted',
  发放金额: 'Disbursement amount',
  推广任务: 'Promotional tasks',
  结束任务: 'end task',
  本次推广于: 'This promotion is on',
  请到期后续费推广: 'Please follow up the promotion fee when it is due',
  '该任务尚未结束，确认提前结束吗？': 'This task has not ended yet. Are you sure it will end early?',
  到期: 'maturity',
  提交审核时间: 'Submit review time',
  开始时间: 'start time',
  '存在佣金争议，请联系': 'If there is a commission dispute, please contact',
  系统客服: 'System customer service',
  日: 'day',
  商户入驻会员发布任务: 'Merchant members publish tasks',
  信用: 'Credit',
  信用等级: 'credit rating',
  '我发布的任务结算金额+100，积分+1': 'The settlement amount of the tasks I posted is +100, and the points are +1',
  '10积分=1级；20积分=2级；30积分=3级；40积分=4级；50积分=5级': '10 points = level 1; 20 points = level 2; 30 points = level 3; 40 points = level 4; 50 points = level 5',
  任务推广: 'Task promotion',
  审核时间: 'review time',
  时: 'hours',
  分: 'minutes',
  秒: 'seconds',
  会员信用等级: 'Member credit rating',
  积分来源: 'Points source',
  我发布的任务结算金额满足一定金额条件后自动获得积分: 'I will automatically receive points after the settlement amount of the task I posted meets certain amount conditions.',
  级: 'class',
  积分: 'integral',
  '结算金额 ：积分': 'Settlement amount : Points',
  理财: 'finance',
  拒绝理由: 'Reason for rejection',
  内容因违规已被删除: 'The content has been deleted due to violations',
  '已上传图片，无法上传视频': 'Pictures have been uploaded, but videos cannot be uploaded',
  自然热门: 'Naturally popular',
  单价不能少于: 'The unit price cannot be less than',
  拒绝: 'reject',
  通过: 'pass',
  请输入拒绝理由: 'Please enter the reason for rejection',
  提交订单详情: 'Submit order details',
  提交订单列表: 'Submit order list',
  货币兑换: 'Currency Conversion',
  从: 'from',
  到: 'arrive',
  请输入兑换数量: 'Please enter the redemption quantity',
  最大: 'maximum',
  最多可兑换: 'Maximum redeemable',
  需支付手续费: 'A handling fee is required',
  拉手币和余额: 'Lashou coins and balance',
  '余额可提现，拉手币不能提现': 'The balance can be withdrawn, but the handle coins cannot be withdrawn.',
  拉手币兑现为余额收取: 'The balance will be charged when cashing out Lashou coins',
  余额兑换为拉手币收取: 'The remaining balance will be converted into LaShou coins and collected.',
  数量不能大于: 'The quantity cannot be greater than',
  兑换成功: 'Redemption successful',
  货币兑换手续费: 'Currency exchange fee',
  拉手币余额: 'Handle Coin balance'
};
