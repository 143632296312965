export default {
  说明: '说明',
  '违约金：提前卖出需支付30%的违约金、已得收益利息需扣除': '违约金：提前卖出需支付30%的违约金、已得收益利息需扣除',
  '利息计算：预计利息=（本金 * 年化利率 / 365）*存期': '利息计算：预计利息=（本金 * 年化利率 / 365）*存期',
  '利息发放：凌晨00：00到账个人账户余额': '利息发放：凌晨00：00到账个人账户余额',
  预计利息: '预计利息',
  份数: '份数',
  买入份数: '买入份数',
  您已经满级了哦: '您已经满级了哦',
  登录: '登录',
  注册: '注册',
  热门任务: '热门任务',
  数量: '数量',
  最快赚钱方式: '最快赚钱方式',
  赚钱: '赚钱',
  如何赚钱: '如何赚钱',
  合作伙伴: '合作伙伴',
  首页: '首页',
  接单: '接单',
  任务大厅: '任务大厅',
  消息: '消息',
  我的: '我的',
  智能排序: '智能排序',
  推荐: '推荐',
  限时折扣: '限时折扣',
  筛选: '筛选',
  单价最高: '单价最高',
  结算最快: '结算最快',
  完成最多: '完成最多',
  订单最多: '订单最多',
  审核最快: '审核最快',
  确定: '确定',
  取消: '取消',
  加载中: '加载中',
  实名认证: '实名认证',
  先行付款: '先行付款',
  平台担保: '平台担保',
  企业认证: '企业认证',
  结算最多: '结算最多',
  评价最多: '评价最多',
  聊天: '聊天',
  我想: '我想',
  充值: '充值',
  提现: '提现',
  提现方式: '提现方式',
  提现金额: '提现金额',
  提现密码: '提现密码',
  查看余额: '查看余额',
  查看单价: '查看单价',
  查看工期: '查看工期',
  在此输入消息: '在此输入消息',
  没有更多了: '没有更多了',
  份已提交: '份已提交',
  支付: '支付',
  平台审核: '平台审核',
  完成率: '完成率',
  通过率: '通过率',
  结算速度: '结算速度',
  查看详情: '查看详情',
  招标中: '招标中',
  有效时间: '有效时间',
  任务描述: '任务描述',
  任务类型: '任务类型',
  任务单价: '任务单价',
  接单条件: '接单条件',
  结算方式: '结算方式',
  任务要求: '任务要求',
  任务有效时间: '任务有效时间',
  任务最后完成时间: '任务最后完成时间',
  资源文件: '资源文件',
  系统消息: '系统消息',
  官方客服: '官方客服',
  商户独聊: '商户独聊',
  会员独聊: '会员独聊',
  代聊: '代聊',
  最近聊天: '最近聊天',
  我的余额: '我的余额',
  我的积分: '我的积分',
  提款中: '提款中',
  去提现: '去提现',
  收款账户: '收款账户',
  资金明细: '资金明细',
  订单中心: '订单中心',
  全部: '全部',
  系统设置: '系统设置',
  账户与安全: '账户与安全',
  联系客服: '联系客服',
  清除缓存: '清除缓存',
  清除缓存提示: '确认清除缓存，所有数据将被清除',
  检查更新: '检查更新',
  退出登录: '退出登录',
  新增USDT地址: '新增USDT地址',
  银行卡: '银行卡',
  新增银行卡: '新增银行卡',
  添加银行卡: '新增银行卡',
  删除银行卡: '是否确认删除该银行卡?',
  无银行卡: '无银行卡',
  设置提现密码: '您尚未设置支付密码，请前往设置!',
  设置手机号: '您还未绑定手机号，请前去绑定！',
  不可用银行卡: '暂不可用',
  新增银行卡最大限制: '最多支持添加{n}张银行卡',
  选择主网: '选择主网',
  '输入钱包/交易所充值地址': '输入钱包/交易所充值地址',
  请输入地址: '请输入地址',
  备注信息: '备注信息',
  请输入银行名称: '请输入银行名称',
  请输入银行卡号: '请输入银行卡号',
  请输入银行编码: '请输入银行编码',
  请输入持卡人姓名: '请输入持卡人姓名',
  输出: '输出',
  有效期: '有效期',
  详情: '详情',
  有效期限: '有效期限',
  任务详情: '任务详情',
  订单编号: '订单编号',
  总金额: '总金额',
  上传凭证: '上传凭证',
  可以上传多张凭证: '可以上传多张凭证',
  提交审核: '提交审核',
  个人信息: '个人信息',
  头像: '头像',
  昵称: '昵称',
  性别: '性别',
  用户名: '用户名',
  简介: '简介',
  修改昵称: '修改昵称',
  男: '男',
  女: '女',
  编辑简介: '编辑简介',
  账号安全: '账号安全',
  修改手机号: '修改手机号',
  修改登陆密码: '修改登陆密码',
  修改支付密码: '修改支付密码',
  您当前绑定的手机号: '您当前绑定的手机号',
  原手机无法接收短信: '原手机无法接收短信',
  '通过IM联系客服进行审核，通过后即可修改': '通过IM联系客服进行审核，通过后即可修改',
  联系: '联系',
  人工客服: '人工客服',
  下一步: '下一步',
  输入新的手机号: '输入新的手机号',
  输入验证码: '输入验证码',
  确认: '确认',
  设置新密码: '设置新密码',
  确认新密码: '确认新密码',
  设置新的支付密码: '设置新的支付密码',
  验证码登录: '验证码登录',
  手机号码: '手机号码',
  请输入您的账号: '请输入您的账号',
  请输入您的手机号码: '请输入您的手机号码',
  请输入您的Google邮箱: '请输入您的Google邮箱',
  请输入正确的Goole邮箱: '请输入正确的Goole邮箱',
  请输入您的旧手机号码: '请输入您的旧手机号码',
  请输入您的新手机号码: '请输入您的新手机号码',
  输入旧手机验证码: '输入旧手机验证码',
  输入新手机验证码: '输入新手机验证码',
  密码: '密码',
  密码必须至少6个字符: '密码必须至少6个字符',
  记住密码: '记住密码',
  Flexilndia新用户: 'Flexilndia新用户',
  立即注册: '立即注册',
  一键登录: '一键登录',
  密码登录: '密码登录',
  验证码: '验证码',
  请输入手机验证码: '请输入手机验证码',
  发送验证码: '发送验证码',
  新密码: '新密码',
  确认密码: '确认密码',
  完成: '完成',
  注册并登录: '注册并登录',
  请输入正确的账号: '请输入正确的账号',
  调度大厅: '调度大厅',
  调度详情: '调度详情',
  删除: '删除',
  凭证: '凭证',
  返回: '返回',
  未定义: '未定义',
  用户协议: '用户协议',
  协议: ' 服务协议及隐私保护',
  协议2:
    ' 为了更好的保障您的合法权益，请您阅读并同意以下协议《特价用户服务协议》《平台服务协议》《隐私政策》《其他用户服务协议》并用账号登录。',
  同意: '同意',
  不同意: '不同意',
  输入您的电话号码: '输入您的电话号码',
  手机号不能为空: '手机号不能为空',
  数字或者字母: '请输入六位以上的数字或者字母',
  手机号必须为十位数: '手机号必须为十位数',
  验证码不能为空: '验证码不能为空',
  只能输入数字: '只能输入数字',
  已发送: '已发送',
  请先获取验证码: '请先获取验证码',
  密码不能为空: '密码不能为空',
  新密码不能为空: '新密码不能为空',
  '2次密码不一致': ' 2次密码不一致',
  手机号或者密码不能为空: '手机号或者密码不能为空',
  密码不能小于6位: '密码不能小于6位',
  暂未开放: '暂未开放',
  手机号码只能输入数字: '手机号码只能输入数字',
  验证码只能输入数字: '验证码只能输入数字',
  灵活: '灵活',
  印度: '印度',
  退出成功: '退出成功',
  已清理: '已清理',
  已是最新版本: '已是最新版本',
  灵活印度: '灵活印度',
  热的: '热的',
  工作: '工作',
  选择语言: '选择语言',
  请输入正确的银行名称: '请输入正确的银行名称',
  请输入正确的银行卡号: '请输入正确的银行卡号',
  请输入正确的银行编码: '请输入正确的银行编码',
  请输入正确持卡人姓名: '请输入正确持卡人姓名',
  昨天: '昨天',
  今天: '今天',
  近一周: '近一周',
  近一月: '近一月',
  选择日期: '选择日期',
  查询: '查询',
  元: '元',
  支付订单: '支付订单',
  订单退还: '订单退还',
  本人任务加款: '本人任务加款',
  本人任务扣减: '本人任务扣减',
  下级返佣: '下级返佣',
  提现失败退还: '提现失败退还',
  管理员加款: '管理员加款',
  管理员扣款: '管理员扣款',
  未知类型: '未知类型',
  真实姓名: '真实姓名',
  身份证: '身份证',
  修改身份证: '修改身份证',
  添加真实姓名: '添加真实姓名',
  请输入简介: '请输入简介',
  请输入有效的昵称: '请输入有效的昵称',
  不能为空: '不能为空',
  请输入正确12位的身份证: '请输入正确12位的身份证',
  不能包含数字和汉字: '不能包含数字和汉字',
  修改登录密码: '修改登录密码',
  '原手机无法接收短信？': '原手机无法接收短信？',
  '通过IM联系客服进行审核，通过后即可更改': '通过IM联系客服进行审核，通过后即可更改',
  确认密码不能为空: '确认密码不能为空',
  密码设置成功: '密码设置成功',
  我的订单: '我的订单',
  每人只能接一次: '每人只能接一次',
  每人同时只有一次: '每人同时只有一次',
  已支付: '已支付',
  未支付: '未支付',
  暂无数据: '暂无数据',
  刷新成功: '刷新成功',
  日期选择: '日期选择',
  点击查看历史消息: '点击查看历史消息',
  请登陆: '请登陆 !',
  提前全部支付: '提前全部支付',
  逐单支付: '逐单支付',
  未知: '未知',
  聊天室: '聊天室',
  两次密码不一致: '两次密码不一致',
  重新连接: '重新连接',
  成功: '成功',
  失败: '失败',
  已结束: '已结束',
  审核中: '审核中',
  进行中: '进行中',
  注册赠送: '注册赠送',
  补单加款: '补单加款',
  补单减款: '补单减款',
  注销账户: '注销账户',
  领取奖励: '领取奖励',
  已复制: '已复制',
  文本描述: '文本描述',
  链接描述: '链接描述',
  超过最大任务数: '超过最大任务数',
  您有新的消息: '您有新的消息',
  评论: '评论',
  请输入银请输入正确的银行卡号行卡号: '请输入正确的卡号',
  签到: '签到',
  提交任务: '提交任务',
  null: 'null',
  '登录密码需6-16位字母或数字': '登录密码需6-16位字母或数字',
  '请输入真实手机号,否则无法提款': '请输入真实手机号,否则无法提款',
  验证码需6位: '验证码需6位',
  新的FlexibleIndia: '新的FlexibleIndia',
  '确定要退出登录吗？': '确定要退出登录吗？',
  退出登录成功: '退出登录成功',
  复制成功: '复制成功',
  请输入昵称: '请输入昵称',
  ID: 'ID',
  修改真实姓名: '修改真实姓名',
  请输入真实姓名: '请输入真实姓名',
  请输入身份证: '请输入身份证',
  上传需要JPG或PNG格式: '上传需要JPG或PNG格式',
  上传大小不能超过1MB: '上传大小不能超过1MB',
  热门: '热门',
  新的: '新的',
  没有数据了: '没有数据了',
  加载数据失败: '加载数据失败',
  份已完成: '份已完成',
  已发放金额: '已发放金额',
  抢单: '抢单',
  抢单1: '抢单',
  抢单2: '抢单',
  抢单3: '抢单',
  抢单4: '抢单',
  抢单5: '抢单',
  抢单6: '抢单',
  抢单7: '抢单',
  抢单8: '抢单',
  抢单9: '抢单',
  提交: '提交',
  提交1: '提交',
  客服: '客服',
  客服1: '客服',
  请输入数字: '请输入数字',
  抢单详情: '抢单详情',
  每人每天只能接一单: '每人每天只能接一单',
  逐单支付中: '逐单支付中',
  全部支付完成: '全部支付完成',
  '请输入...': '请输入...',
  已提交: '已提交',
  审核通过: '审核通过',
  审核通过1: '审核通过',
  审核不通过: '审核不通过',
  冻结: '冻结',
  暂停: '暂停',
  审核拒绝: '审核拒绝',
  分享链接: '分享链接',
  新的手机号: '新的手机号',
  新的密码: '新的密码',
  新的支付密码: '新的支付密码',
  确认新的支付密码: '确认新的支付密码',
  新的支付密码需6位: '新的支付密码需6位',
  确认新的支付密码需6位: '确认新的支付密码需6位',
  两次支付密码不一致: '两次支付密码不一致',
  新的支付密码需数字: '新的支付密码需数字',
  确认新的支付密码需数字: '确认新的支付密码需数字',
  代理中心: '代理中心',
  代理须知: '代理须知',
  最新: '最新',
  资金详情: '资金详情',
  我的团队: '我的团队',
  资金类型: '资金类型',
  选择时间: '请选择时间',
  时间: '时间',
  金额: '金额',
  收入: '收入',
  类型: '类型',
  欢迎登录: '欢迎登录',
  账号: '账号',
  短信: '短信',
  还没有帐户吗: '还没有帐户吗',
  现在注册: '现在注册',
  欢迎注册: '欢迎注册',
  有帐户: '有帐户',
  发送: '发送',
  忘记密码: '忘记密码',
  分享: '分享',
  手机号不存在: '手机号不存在',
  请输入手机号: '请输入手机号',
  验证码错误: '验证码错误',
  手机号已存在: '手机号已存在',
  订单号: '订单号',
  清除缓存成功: '清除缓存成功',
  对话列表: '对话列表',
  联系人列表: '联系人列表',
  新增upi地址: '新增upi地址',
  每人终生只能接一次: '每人终生只能接一次',
  每人同时只有一单: '每人同时只有一单',
  每人同时接多单: '每人同时接多单',
  '每人每天只能接一单(规定提交时间)': '每人每天只能接一单(规定提交时间)',
  每人每天接一单: '每人每天接一单',
  '每人每天都可接一单(且此任务当日可以提交)': '每人每天都可接一单(且此任务当日可以提交)',
  上传图片重复: '上传图片重复',
  请输入upi地址: '请输入upi地址',
  最热: '最热',
  收藏: '收藏',
  教程: '教程',
  教学详情: '教学详情',
  客户服务: '客户服务',
  新手任务: '新手任务',
  如何抢任务: '如何抢任务',
  如何提现: '如何提现',
  如何找到客服: '如何找到客服',
  步骤: '步骤',
  选择任务并领取订单: '选择任务并领取订单。',
  提交任务所需的信息以供审核: '提交任务所需的信息以供审核。',
  完成任务后获得佣金并实时提现到您的账户: '完成任务后获得佣金，并实时提现到您的账户。',
  点击个人中心页面进入提现页面: '点击个人中心页面进入提现页面。',
  添加所选付款的银行卡信息: '添加所选付款的银行卡信息。',
  填写提现金额和支付密码然后您可以开始申请提现: '填写提现金额和支付密码，然后您可以开始申请提现。',
  关于任务: '关于任务',
  如果您在接收任务时不知道具体流程您可以点击客户进行咨询:
    '如果您在接收任务时不知道具体流程，您可以点击客户进行咨询。',
  关于其他问题: '关于其他问题',
  选择在线客服: '选择在线客服。',
  选择您要咨询的问题类型并与专业客服进行一对一咨询:
    '选择您要咨询的问题类型，并与专业客服进行一对一咨询。',
  完成任务获得: '完成任务获得',
  领取任务: '领取任务',
  找客服: '找客服',
  设置信息: '设置信息',
  上一页: '上一页',
  下一页: '下一页',
  领取: '领取',
  未设置: '未设置',
  请设置性别: '请设置性别',
  请绑定您的手机号码: '请绑定您的手机号码',
  谷歌邮箱: '谷歌邮箱',
  请绑定您的谷歌邮箱: '请绑定您的谷歌邮箱',
  绑定谷歌邮箱: '绑定谷歌邮箱',
  添加手机号: '添加手机号',
  账号必须大于6位: '账号必须大于6位',
  请输入银行卡号2: '请输入银行卡号',
  请输入银行名称2: '请输入银行名称',
  请输入银行编码2: '请输入银行编码',
  请输入持卡人姓名2: '请输入持卡人姓名',
  'ifsc必须为11位,第五位必须为0': 'ifsc必须为11位,第五位必须为0',
  任务ID: '任务 ID',
  任务量已满: '任务量已满',
  不建群: '不建群',
  facebook群: 'facebook群',
  whatsapp群: 'whatsapp群',
  telegram群: 'telegram群',
  线下任务: '线下任务',
  完成别的订单后才可提交: '完成别的订单后才可提交',
  tg两千人大群收集: 'tg两千人大群收集',
  请登录: '请登录',
  注销账号: '注销账号',
  提示: '提示',
  确认注销此账号吗: '确认注销此账号吗？',
  注销成功: '注销成功',
  上传视频: '上传视频',
  可选择上传视频: '可选择上传视频',
  系统通知: '系统通知',
  '在接受订单之前,您需要完成新手教程!': '在接受订单之前,您需要完成新手教程!',
  去完成: '去完成',
  手机号未注册: '手机号未注册',
  未绑定: '未绑定',
  记录: '记录',
  提现记录: '提现记录',
  请上传银行卡: '请上传银行卡',
  重新发送: '重新发送',
  视频: '视频',
  上传图片: '上传图片',
  视频演示: '视频演示',
  转账中: '转账中',
  转账成功: '转账成功',
  转账失败: '转账失败',
  人工终结: '人工终结',
  三方超时: '三方超时',
  补单: '补单',
  会员权益: '会员权益',
  会员享受4大福利: '会员享受4大福利',
  每日入住奖励: '每日入住奖励',
  高佣金任务: '高佣金任务',
  代理机制: '代理机制',
  收益保证: '收益保证',
  退出: '退出',
  特权: '特权',
  详细资料: '详细资料',
  每日入住可获得10卢比超过10天不入住的会员将被取消会员资格:
    '每日入住可获得10卢比,超过10天不入住的会员,将被取消会员资格。',
  优先权接受高佣金任务提款优先: '优先权,接受高佣金任务,提款优先',
  优先参与代理机制: '优先参与代理机制',
  如果您在一个月内没有赚取10000卢比您的会员费将直接退还:
    '如果您在一个月内没有赚取10000卢比，您的会员费将直接退还。',
  排行榜: '排行榜',
  了解详情: '了解详情',
  邀请码: '邀请码',
  邀请链接: '邀请链接',
  邀请二维码: '邀请二维码',
  点击按钮保存或截图将二维码保存到手机: '点击按钮保存或截图将二维码保存到手机',
  保存到手机: '保存到手机',
  总返佣: '总返佣',
  接单总数: '接单总数',
  总人数: '总人数',
  今天的佣金: '今天的佣金',
  昨天的佣金: '昨天的佣金',
  周奖励: '周奖励',
  月奖励: '月奖励',
  代理通知: '代理通知',
  排名: '排名',
  会员信息: '会员信息',
  未上榜: '未上榜',
  我: '我',
  提现排行榜: '提现排行榜',
  接单排行榜: '接单排行榜',
  昨日榜: '昨日榜',
  今日榜: '今日榜',
  周榜: '周榜',
  月榜: '月榜',
  总榜: '总榜',
  提现金额1: '提现金额',
  代理: '代理',
  请选择一个时间: '请选择一个时间',
  下级会员: '下级会员',
  '例如，当 A 邀请 B，B 邀请 C，C 邀请 D，而 D 提现时：': '例如，当 A 邀请 B，B 邀请 C，C 邀请 D，而 D 提现时：',
  'A 可以获得高达 0.5% 的佣金': 'A 可以获得高达 {0}% 的佣金',
  'B 可以获得高达 1% 的佣金': 'B 可以获得高达 {0}% 的佣金',
  'C 可以获得高达 1.4% 的佣金': 'C 可以获得高达 {0}% 的佣金',
  '三层邀请关系之外没有佣金。': '三层邀请关系之外没有佣金。',
  代理条件: '代理条件',
  代理等级: '代理等级',
  直接会员提现限额: '直接会员提现限额',
  直接会员人数: '直接会员人数',
  代理返佣比例: '代理返佣比例',
  一级代理: '一级代理',
  二级代理: '二级代理',
  三级代理: '三级代理',
  代理升级: '代理升级',
  '代理等级每月 15 日 00:00 计算。计算完成后实时生效': '代理等级每月 15 日 00:00 计算。计算完成后实时生效',
  加入群聊: '加入群聊',
  直属下级: '直属下级',
  我已阅读并同意: '我已阅读并同意',
  注册协议: '注册协议',
  和: '和',
  隐私政策: '《隐私政策》',
  请勾选协议: '请勾选协议',
  领取成功: '领取成功',
  签到中心: '签到中心',
  规则: '规则',
  拉手币: '拉手币',
  已连续签到: '已连续签到',
  天: '天',
  剩余补签次数: '剩余补签次数',
  签到奖励: '签到奖励',
  奖励: '奖励',
  连续签到: '连续签到',
  规则说明: '规则说明',
  活动规则: '活动规则',
  每日签到奖励: '每日签到奖励',
  漏签后一个月内可补签: '漏签后一个月内可补签',
  次: '次',
  次数不转存: '次数不转存',
  补签一次扣除: '补签一次扣除',
  连续签到固定天数后可领取随机大额拉手币: '连续签到固定天数后可领取随机大额拉手币',
  仅显示并记录当月签到记录: '仅显示并记录当月签到记录',
  签到成功: '签到成功',
  不能签到未来的日期: '不能签到未来的日期',
  贡献值: '贡献值',
  立刻充值: '立刻充值',
  立刻提现: '立刻提现',
  协议及政策: '协议及政策',
  隐私协议: '隐私协议',

  我的拉手币: '我的拉手币',
  选择年月: '选择年月',
  连续签到5天奖励: '连续签到5天奖励',
  连续签到15天奖励: '连续签到15天奖励',
  连续签到30天奖励: '连续签到30天奖励',
  补签扣款: '补签扣款',
  抽奖: '抽奖',
  获得: '获得',
  消耗: '消耗',
  年: '年',
  月: '月',
  账单类型: '账单类型',
  成长值: '成长值',
  会员: '会员',
  需要: '需要',
  距下一等级还差: '距下一等级还差',
  还未到当前等级: '还未到当前等级',
  中奖记录: '中奖记录',
  奖金: '奖金',
  开始: '开始',
  剩余次数: '剩余次数',
  点击抽奖: '点击抽奖',
  中奖名单: '中奖名单',
  奖品: '奖品',
  抽奖说明: '抽奖说明',
  '1.会员等级：获得会员等级后可参与抽奖；不同等级对应不同抽奖次数': '1.会员等级：获得会员等级后可参与抽奖；不同等级对应不同抽奖次数',
  会员等级: '会员等级',
  抽奖次数: '抽奖次数',
  单次消耗: '单次消耗',
  '2.奖品设置：小米手机、摩托车、日用品、电子产品等；中奖率高达100%': '2.奖品设置：小米手机、摩托车、日用品、电子产品等；中奖率高达100%',
  '3.奖品兑换：抽中奖品后，填写收货地址，静待收货；中奖后7天未兑换，视为自动放弃本次奖品': '3.奖品兑换：抽中奖品后，填写收货地址，静待收货；中奖后7天未兑换，视为自动放弃本次奖品',
  '1.为广大会员提供福利，平台决定将每日营业收入的10%纳入奖池': '1.为广大会员提供福利，平台决定将每日营业收入的10%纳入奖池',
  '2.奖池累计足额现金后将会随机在抽奖中掉落中奖机会': '2.奖池累计足额现金后将会随机在抽奖中掉落中奖机会',
  '3.会员级别越高，被抽中的概率越高哦': '3.会员级别越高，被抽中的概率越高哦',
  恭喜您成功抽到: '恭喜您成功抽到',
  很遗憾: '很遗憾',
  立即兑换: '立即兑换',
  再来一次: '再来一次',
  '抽奖次数将消耗拉手币请知悉!': '抽奖次数将消耗拉手币请知悉!',
  不在提示: '不在提示',
  我知道了: '我知道了',
  待兑奖: '待兑奖',
  已兑奖: '已兑奖',
  已失效: '已失效',
  中奖者: '中奖者',
  中奖时间: '中奖时间',
  兑奖时间: '兑奖时间',
  结束时间: '结束时间',
  有效期至: '有效期至',
  兑换: '兑换',
  查看订单: '查看订单',
  收货信息补充: '收货信息补充',
  收货人名称: '收货人名称',
  请输入收货人名称: '请输入收货人名称',
  联系方式: '联系方式',
  请输入联系方式: '请输入联系方式',
  收货人地址: '收货人地址',
  请输入收货人地址: '请输入收货人地址',
  备注: '备注',
  请输入备注: '请输入备注',
  添加成功: '添加成功',
  兑奖成功: '兑奖成功',
  请注册: '请注册',
  手机号: '手机号',
  订单详情: '订单详情',
  收货信息: '收货信息',
  订单信息: '订单信息',
  兑换单号: '兑换单号',
  物流单号: '物流单号',
  // 理财
  我的理财: '我的理财',
  理财市场: '理财市场',
  我的持有: '我的持有',
  年化利率: '年化利率',
  当前资产: '当前资产',
  昨日收益: '昨日收益',
  本月收益: '本月收益',
  收益明细: '收益明细',
  交易记录: '交易记录',
  总收益: '总收益',
  买入: '买入',
  买入金额: '买入金额',
  付款方式: '付款方式',
  账户余额: '账户余额',
  基金委托理财说明书: '基金委托理财说明书',
  付款: '付款',
  卖出: '卖出',
  利息: '利息',
  违约金: '违约金',
  到期卖出: '到期卖出',
  提前卖出: '提前卖出',
  余额不足: '余额不足',
  '买入后2日内卖出将根据协议扣除信誉违约金，信誉违约金=购买金额的30%，确认卖出吗？': '买入后2日内卖出将根据协议扣除信誉违约金，信誉违约金=购买金额的30%，确认卖出吗？',
  '买入后2日内卖出将根据协议扣除信誉违约金，信誉违约金=购买金额的30%，确认买入吗？': '买入后2日内卖出将根据协议扣除信誉违约金，信誉违约金=购买金额的30%，确认买入吗？',
  请阅读并同意: '请阅读并同意',
  充值金额: '充值金额',
  请输入金额: '请输入金额',
  请输入: '请输入',
  充值凭证: '充值凭证',
  请输入充值金额: '请输入充值金额',
  请选择充值渠道: '请选择充值渠道',
  请输入12位数字的UTR: '请输入12位数字的UTR',
  请上传凭证: '请上传凭证',
  去充值: '去充值',
  充值账户: '充值账户',
  理财产品充值: '理财产品充值',
  理财产品买入: '理财产品买入',
  理财产品产生的金额: '理财产品产生的金额',
  理财产品卖出: '理财产品卖出',
  理财产品违约卖出: '理财产品违约卖出',
  账变类型: '账变类型',
  佣金: '佣金',
  余额: '余额',
  任务: '任务',
  论坛: '论坛',
  '充值申请已提交，待审核！': '充值申请已提交，待审核！',
  点赞: '点赞',
  发布: '发布',
  游戏: '游戏',
  生活: '生活',
  新闻: '新闻',
  收起: '收起',
  展开: '展开',
  待审核: '待审核',
  已通过: '已通过',
  未通过: '未通过',
  复制链接: '复制链接',
  热门推荐: '热门推荐',
  删除帖子: '删除帖子',
  编辑帖子: '编辑帖子',
  确认删除吗: '确认删除吗',
  删除成功: '删除成功',
  发布动态: '发布动态',
  '快来发布动态吧~': '快来发布动态吧~',
  请输入内容: '请输入内容',
  动态详情: '动态详情',
  全部评论: '全部评论',
  作者: '作者',
  回复: '回复',
  '友善评论，文明发言': '友善评论，文明发言',
  搜索: '搜索',
  请输入搜索关键词: '请输入搜索关键词',
  上热门: '上热门',
  热门时长: '热门时长',
  论坛帖子上热门: '论坛帖子上热门',
  '已发布，待审核': '已发布，待审核',
  '确认删除搜索记录吗？': '确认删除搜索记录吗？',
  图片上传失败: '图片上传失败',
  发布任务: '发布任务',
  任务标题: '任务标题',
  请输入任务标题: '请输入任务标题',
  请输入任务要求: '请输入任务要求',
  任务单数: '任务单数',
  请输入任务单数: '请输入任务单数',
  请输入任务单价: '请输入任务单价',
  任务开始时间: '任务开始时间',
  点击选择时间: '点击选择时间',
  请选择任务开始时间: '请选择任务开始时间',
  预付佣金: '预付佣金',
  手续费: '手续费',
  实付金额: '实付金额',
  支付并发布: '支付并发布',
  确认你的任务信息: '确认你的任务信息',
  我发布的: '我发布的',
  发放金额: '发放金额',
  推广任务: '推广任务',
  结束任务: '结束任务',
  本次推广于: '本次推广于',
  请到期后续费推广: '请到期后续费推广',
  '该任务尚未结束，确认提前结束吗？': '该任务尚未结束，确认提前结束吗？',
  到期: '到期',
  提交审核时间: '提交审核时间',
  开始时间: '开始时间',
  '存在佣金争议，请联系': '存在佣金争议，请联系',
  系统客服: '系统客服',
  日: '日',
  商户入驻会员发布任务: '商户入驻会员发布任务',
  信用: '信用',
  信用等级: '信用等级',
  '我发布的任务结算金额+100，积分+1': '我发布的任务结算金额+100，积分+1',
  '10积分=1级；20积分=2级；30积分=3级；40积分=4级；50积分=5级': '10积分=1级；20积分=2级；30积分=3级；40积分=4级；50积分=5级',
  任务推广: '任务推广',
  审核时间: '审核时间',
  时: '时',
  分: '分',
  秒: '秒',
  会员信用等级: '会员信用等级',
  积分来源: '积分来源',
  我发布的任务结算金额满足一定金额条件后自动获得积分: '我发布的任务结算金额满足一定金额条件后自动获得积分',
  级: '级',
  积分: '积分',
  '结算金额 ：积分': '结算金额 ：积分',
  理财: '理财',
  拒绝理由: '拒绝理由',
  内容因违规已被删除: '内容因违规已被删除',
  '已上传图片，无法上传视频': '已上传图片，无法上传视频',
  自然热门: '自然热门',
  单价不能少于: '单价不能少于',
  拒绝: '拒绝',
  通过: '通过',
  请输入拒绝理由: '请输入拒绝理由',
  提交订单详情: '提交订单详情',
  提交订单列表: '提交订单列表',
  货币兑换: '货币兑换',
  从: '从',
  到: '到',
  请输入兑换数量: '请输入兑换数量',
  最大: '最大',
  最多可兑换: '最多可兑换',
  需支付手续费: '需支付手续费',
  拉手币和余额: '拉手币和余额',
  '余额可提现，拉手币不能提现': '余额可提现，拉手币不能提现',
  拉手币兑现为余额收取: '拉手币兑现为余额收取',
  余额兑换为拉手币收取: '余额兑换为拉手币收取',
  数量不能大于: '数量不能大于',
  兑换成功: '兑换成功',
  货币兑换手续费: '货币兑换手续费',
  拉手币余额: '拉手币余额'
};
