export default {
  说明: 'विवरण',
  '违约金：提前卖出需支付30%的违约金、已得收益利息需扣除': 'कर्ता के विरोध के लिए शिक्षा है: 30% की शिक्षा प्रारंभ विक्रेत के लिए प्रारंभ होना चाहिए, और जो प्राप्त होना चा',
  '利息计算：预计利息=（本金 * 年化利率 / 365）*存期': 'अंतिम गणना: अंतिम दिया गया है=(प्राथमिक * वर्षीय दिया गया हुआ दिया गया है/365) * डोसिट का वर्ष',
  '利息发放：凌晨00：00到账个人账户余额': 'इंटरस्ट पैसा: व्यक्तिगत खाता बैलेन्स के लिए सुबह 00:00 में क्रेडिट किया गया',
  预计利息: 'अपेक्षित रीति',
  份数: 'नक़लों की संख्या',
  买入份数: 'खरीदा मात्रा',
  您已经满级了哦: 'आप अधिकतम स्तर पर पहुँच गए हैं।',
  登录: 'लॉगइनकरें',
  注册: 'रजिस्टर करें',
  热门任务: 'लोकप्रिय कार्य',
  数量: 'संख्या',
  最快赚钱方式: 'तेज़ी से पैसा कमाने का तरीका',
  赚钱: 'पैसा कमाएं',
  如何赚钱: 'पैसा कैसे कमाएं',
  合作伙伴: 'सहयोगी',
  首页: 'मुख्य पृष्ठ',
  接单: 'ऑर्डर प्राप्त करें',
  任务大厅: 'कार्य हॉल',
  消息: 'सूचना',
  我的: 'मेरा',
  智能排序: 'स्मार्ट सॉर्टिंग',
  推荐: 'अनुशंसा',
  限时折扣: 'सीमित समय की छूट',
  筛选: 'फ़िल्टर करें',
  单价最高: 'उच्चतम प्रति यूनिट मूल्य',
  结算最快: 'सबसे तेज़ निपटान',
  完成最多: 'अधिकतम पूरा',
  订单最多: 'अधिकतम ऑर्डर',
  审核最快: 'सबसे तेज़ समीक्षा',
  确定: 'पक्का करें',
  取消: 'रद्द करें',
  加载中: 'लोड हो रहा है',
  实名认证: 'सत्यापित',
  先行付款: 'अग्रिम भुगतान',
  平台担保: 'प्लेटफ़ॉर्म गारंटी',
  企业认证: 'कंपनी प्रमाणन',
  结算最多: 'अधिकतम निपटान',
  评价最多: 'अधिकतम रेटिंग',
  聊天: 'चैट',
  我想: 'मैं चाहता हूँ',
  充值: 'रिचार्ज करें',
  提现: 'निकासी',
  提现方式: 'निकासी विधि',
  提现金额: 'निकासी राशि',
  提现密码: 'निकासी पासवर्ड',
  查看余额: 'शेष राशि देखें',
  查看单价: 'मूल्य देखें',
  查看工期: 'अवधि देखें',
  在此输入消息: 'यहां अपना संदेश दर्ज करें',
  没有更多了: 'और नहीं',
  份已提交: 'प्रस्तुत',
  支付: 'भुगतान करें',
  平台审核: 'प्लेटफॉर्म समीक्षा',
  完成率: 'पूर्णता दर',
  通过率: 'उत्तीर्ण दर',
  结算速度: 'निपटान गति',
  查看详情: 'विवरण देखें',
  招标中: 'बोली में',
  有效时间: 'प्रभावी समय',
  任务描述: 'कार्य विवरण',
  任务类型: 'कार्य प्रकार',
  任务单价: 'कार्य मूल्य',
  接单条件: 'ऑर्डर स्वीकार करने की शर्तें',
  结算方式: 'निपटान विधि',
  任务要求: 'कार्य आवश्यकताएँ',
  任务有效时间: 'कार्य प्रभावी समय',
  任务最后完成时间: 'कार्य की अंतिम पूर्णता समय',
  资源文件: 'संसाधन फ़ाइल',
  系统消息: 'सिस्टम सूचना',
  官方客服: 'आधिकारिक ग्राहक सेवा',
  商户独聊: 'व्यापारी निजी चैट',
  会员独聊: 'सदस्य निजी चैट',
  代聊: 'बातचीत करें',
  最近聊天: 'हाल की चैट',
  我的余额: 'मेरा शेष',
  我的积分: 'मेरे अंक',
  提款中: 'निकासी में',
  去提现: 'निकासी करें',
  收款账户: 'खाता',
  资金明细: 'मेरे धन का विवरण',
  订单中心: 'ऑर्डर केंद्र',
  全部: 'सभी',
  系统设置: 'सिस्टम सेटिंग्स',
  账户与安全: 'खाता सुरक्षा',
  联系客服: 'ग्राहक सेवा से संपर्क करें',
  清除缓存: 'कैश साफ़ करें',
  清除缓存提示: 'कैश साफ़ करने की पुष्टि करें, सभी डेटा साफ़ हो जाएगा।',
  检查更新: 'अपडेट जांचें',
  退出登录: 'लॉगआउट करें',
  新增USDT地址: 'नया USDT पता जोड़ें',
  银行卡: 'बैंक कार्ड',
  新增银行卡: 'अपना बैंक खाता विवरण जोड़ें',
  添加银行卡: 'बैंक कार्ड जोड़ें',
  删除银行卡: 'क्या आप इस बैंक कार्ड को हटाना चाहते हैं?',
  无银行卡: 'कोई बैंक कार्ड नहीं',
  设置提现密码: 'आपने अभी तक भुगतान पासवर्ड सेट नहीं किया है, कृपया जाकर सेट करें!',
  设置手机号: 'आपने अभी तक अपना मोबाइल नंबर नहीं जोड़ा है, कृपया जाकर जोड़ें!',
  不可用银行卡: 'अक्षम',
  新增银行卡最大限制: 'अधिकतम {n} कार्ड जोड़े जा सकते हैं।',
  选择主网: 'मुख्य नेट चुनें',
  '输入钱包/交易所充值地址': 'वॉलेट/एक्सचेंज डिपॉज़िट पता दर्ज करें',
  请输入地址: 'कृपया पता दर्ज करें',
  备注信息: 'टिप्पणियाँ',
  请输入银行名称: 'बैंक का नाम दर्ज करें',
  请输入银行卡号: 'खाता संख्या दर्ज करें',
  请输入银行编码: 'IFSC कोड दर्ज करें',
  请输入持卡人姓名: 'खाताधारक का नाम दर्ज करें',
  输出: 'आउटपुट',
  有效期: 'वैधता अवधि',
  详情: 'विवरण',
  有效期限: 'वैधता अवधि',
  任务详情: 'कार्य विवरण',
  订单编号: 'ऑर्डर संख्या',
  总金额: 'कुल राशि',
  上传凭证: 'प्रमाणपत्र अपलोड करें',
  可以上传多张凭证: 'कई वाउचर अपलोड किए जा सकते हैं',
  提交审核: 'प्रस्तुत करें',
  个人信息: 'मेरी प्रोफ़ाइल',
  头像: 'प्रोफ़ाइल फोटो',
  昵称: 'निक नेम',
  性别: 'लिंग',
  用户名: 'उपयोक्ता नाम',
  简介: 'परिचय',
  修改昵称: 'उपयोगकर्ता नाम बदलें',
  男: 'पुरुष',
  女: 'महिला',
  编辑简介: 'परिचय संपादक',
  账号安全: 'खाता सुरक्षा',
  修改手机号: 'फोन नंबर बदलें',
  修改登陆密码: 'लॉगिन पासवर्ड बदलें',
  修改支付密码: 'भुगतान पासवर्ड बदलें',
  您当前绑定的手机号: 'आपका वर्तमान बंधित मोबाइल फोन नंबर',
  原手机无法接收短信: 'मूल मोबाइल फोन टेक्स्ट संदेश प्राप्त नहीं कर सकता',
  '通过IM联系客服进行审核，通过后即可修改':
    'समीक्षा के लिए IM के माध्यम से ग्राहक सेवा से संपर्क करें, और पास होने के बाद आप इसे संशोधित कर सकते हैं।',
  联系: 'संपर्क करें',
  人工客服: 'मानव ग्राहक सेवा',
  下一步: 'अगला',
  输入新的手机号: 'नया मोबाइल नंबर दर्ज करें',
  输入验证码: 'पुष्टिकरण कोड दर्ज करें',
  确认: 'पुष्टि करें',
  设置新密码: 'नया पासवर्ड सेट करें',
  确认新密码: 'नया पासवर्ड पुष्टि करें',
  设置新的支付密码: 'नया भुगतान पासवर्ड सेट करें',
  验证码登录: 'पुष्टिकरण कोड लॉगिन',
  手机号码: 'फोन नंबर',
  请输入您的账号: 'कृपया अपना खाता संख्या दर्ज करें',
  请输入您的手机号码: 'कृपया अपना फोन नंबर दर्ज करें',
  请输入您的Google邮箱: 'कृपया अपना Google ईमेल पता दर्ज करें',
  请输入正确的Goole邮箱: 'कृपया मान्य Google ईमेल पता दर्ज करें',
  请输入您的旧手机号码: 'कृपया अपना पुराना फोन नंबर दर्ज करें',
  请输入您的新手机号码: 'कृपया अपना नया फोन नंबर दर्ज करें',
  输入旧手机验证码: 'पुराने फोन पुष्टिकरण कोड दर्ज करें',
  输入新手机验证码: 'नए फोन पुष्टिकरण कोड दर्ज करें',
  密码: 'पासवर्ड',
  密码必须至少6个字符: 'पासवर्ड कम से कम 6 अक्षर होना चाहिए',
  记住密码: 'पासवर्ड याद रखें',
  Flexilndia新用户: 'Flexilndia नया उपयोगकर्ता',
  立即注册: 'साइन अप करें',
  一键登录: 'एक-क्लिक लॉगिन',
  密码登录: 'पासवर्ड लॉगिन',
  验证码: 'पुष्टिकरण कोड',
  请输入手机验证码: 'कृपया मोबाइल फोन पुष्टिकरण कोड दर्ज करें',
  发送验证码: 'कोड भेजें',
  新密码: 'नया पासवर्ड',
  确认密码: 'पासवर्ड पुष्टि करें',
  完成: 'पूरा करें',
  注册并登录: 'रजिस्टर करें और लॉगिन करें',
  请输入正确的账号: 'कृपया मान्य खाता संख्या दर्ज करें',
  调度大厅: 'डिस्पैच हॉल',
  调度详情: 'शेड्यूलिंग विवरण',
  删除: 'हटाएं',
  凭证: 'प्रमाणपत्र',
  返回: 'वापस जाएं',
  未定义: 'अपरिभाषित',
  用户协议: 'उपयोगकर्ता समझौता',
  协议: 'गोपनीयता संरक्षण',
  协议2: 'आपके वैध अधिकारों और हितों की सुरक्षा के लिए, कृपया ऑपरेशन से पहले ध्यान से पढ़ें',
  同意: 'सहमत',
  不同意: 'असहमत',
  输入您的电话号码: 'अपना फोन नंबर दर्ज करें',
  手机号不能为空: 'मोबाइल नंबर खाली नहीं हो सकता',
  数字或者字母: 'कम से कम 6 वर्ण',
  手机号必须为十位数: 'मोबाइल नंबर दस अंकों का होना चाहिए',
  验证码不能为空: 'पुष्टिकरण कोड खाली नहीं हो सकता',
  只能输入数字: 'केवल अंक दर्ज करें',
  已发送: 'भेजा गया',
  请先获取验证码: 'कृपया पहले पुष्टिकरण कोड प्राप्त करें',
  密码不能为空: 'पासवर्ड खाली नहीं हो सकता',
  新密码不能为空: 'नया पासवर्ड खाली नहीं हो सकता',
  '2次密码不一致': 'दो पासवर्ड मेल नहीं खाते',
  手机号或者密码不能为空: 'मोबाइल नंबर या पासवर्ड खाली नहीं हो सकता',
  密码不能小于6位: 'पासवर्ड 6 वर्णों से कम नहीं हो सकता',
  暂未开放: 'अभी तक उपलब्ध नहीं है',
  手机号码只能输入数字: 'मोबाइल नंबर में केवल अंक दर्ज किए जा सकते हैं',
  验证码只能输入数字: 'पुष्टिकरण कोड में केवल अंक दर्ज करें',
  灵活: 'लचीला',
  印度: 'भारत',
  退出成功: 'सफलतापूर्वक निकला',
  已清理: 'साफ किया गया',
  已是最新版本: 'नवीनतम संस्करण',
  灵活印度: 'फ्लेक्सी इंडिया',
  热的: 'गरम',
  工作: 'काम',
  选择语言: 'भाषा चुनें',
  请输入正确的银行名称: 'कृपया सही बैंक नाम दर्ज करें',
  请输入银行名称2: 'कृपया बैंक नाम दर्ज करें',
  请输入银行卡号2: 'कृपया खाता संख्या दर्ज करें',
  请输入银行编码2: 'कृपया आईएफएससी कोड दर्ज करें',
  请输入持卡人姓名2: 'कृपया खाताधारक का नाम दर्ज करें',
  'ifsc必须为11位,第五位必须为0': 'ifsc 11 बिट होना चाहिए, पांचवां बिट 0 होना चाहिए',
  请输入正确的银行卡号: 'कृपया सही खाता संख्या दर्ज करें',
  请输入正确的银行编码: 'कृपया सही आईएफएससी कोड दर्ज करें',
  请输入正确持卡人姓名: 'कृपया सही खाताधारक का नाम दर्ज करें',
  昨天: 'कल',
  今天: 'आज',
  近一周: 'साप्ताहिक',
  近一月: 'मासिक',
  选择日期: 'तारीख चुनें',
  查询: 'खोजें',
  元: 'रुपया',
  支付订单: 'ऑर्डर का भुगतान',
  订单退还: 'ऑर्डर रिटर्न',
  本人任务加款: 'कार्य पूरा करें पैसे प्राप्त करें',
  本人任务扣减: 'कार्य कटौती',
  下级返佣: 'एजेंट कमीशन',
  提现失败退还: 'निकासी असफल',
  管理员加款: 'सिस्टम से पैसे जोड़ें',
  管理员扣款: 'सिस्टम से कटौती',
  未知类型: 'अज्ञात प्रकार',
  真实姓名: 'वास्तविक नाम',
  身份证: 'आईडी कार्ड',
  修改身份证: 'आईडी कार्ड संशोधित करें',
  添加真实姓名: 'अपना वास्तविक नाम जोड़ें',
  请输入简介: 'कृपया परिचय दर्ज करें',
  请输入有效的昵称: 'कृपया एक मान्य उपनाम दर्ज करें',
  不能为空: 'खाली नहीं हो सकता',
  请输入正确12位的身份证: 'कृपया सही 12 अंकों का आईडी कार्ड दर्ज करें',
  不能包含数字和汉字: 'अंक और चीनी वर्ण शामिल नहीं हो सकते',
  修改登录密码: 'लॉगिन पासवर्ड बदलें',
  '原手机无法接收短信？': 'आपके मूल फोन पर टेक्स्ट संदेश प्राप्त नहीं हो सकते?',
  '通过IM联系客服进行审核，通过后即可更改':
    'समीक्षा के लिए IM के माध्यम से ग्राहक सेवा से संपर्क करें, और स्वीकृति के बाद संशोधन किया जा सकता है।',
  确认密码不能为空: 'पुष्टि पासवर्ड खाली नहीं हो सकता',
  密码设置成功: 'पासवर्ड सफलतापूर्वक सेट किया गया',
  我的订单: 'मेरा ऑर्डर',
  每人只能接一次: 'प्रत्येक व्यक्ति केवल एक बार उठा सकता है',
  每人同时只有一次: 'प्रत्येक आईडी पर एक ही समय में केवल एक कार्य',
  已支付: 'भुगतान किया गया',
  未支付: 'अवैतनिक',
  暂无数据: 'कोई डेटा नहीं',
  刷新成功: 'सफलतापूर्वक ताज़ा किया गया',
  日期选择: 'तारीख का चयन',
  点击查看历史消息: 'ऐतिहासिक संदेश देखने के लिए क्लिक करें',
  请登陆: 'कृपया लॉगिन करें!',
  提前全部支付: 'अग्रिम भुगतान करें',
  逐单支付: 'ऑर्डर भुगतान करें',
  未知: 'अज्ञात',
  聊天室: 'चैट रूम',
  两次密码不一致: 'दो पासवर्ड मेल नहीं खाते',
  重新连接: 'पुनः कनेक्ट करें',
  成功: 'सफल',
  失败: 'असफल',
  已结束: 'समाप्त',
  审核中: 'समीक्षा में',
  进行中: 'प्रगति में',
  注册赠送: 'साइन अप करें और पैसे प्राप्त करें',
  补单加款: 'पूरक जोड़ें',
  补单减款: 'पूरक घटाएं',
  注销账户: 'खाता हटाएं',
  领取奖励: 'बोनस प्राप्त करें',
  已复制: 'कॉपी किया गया',
  文本描述: 'पाठ विवरण',
  链接描述: 'लिंक विवरण',
  超过最大任务数: 'अधिकतम कार्यों से अधिक, कृपया पुनः प्रयास करें',
  您有新的消息: 'आपके पास नई खबरें हैं',
  评论: 'टिप्पणी',
  请输入银请输入正确的银行卡号行卡号: 'कृपया सही खाता संख्या दर्ज करें',
  签到: 'साइन इन',
  提交任务: 'कार्य प्रस्तुत करें',
  null: 'शून्य',
  '登录密码需6-16位字母或数字': 'लॉगिन पासवर्ड में 6-16 अंकों के अक्षर या अंक होने चाहिए',
  '请输入真实手机号,否则无法提款': 'बैंक रजिस्टर्ड मोबाइल नंबर के बिना निकासी संभव नहीं है',
  验证码需6位: 'पुष्टिकरण कोड में 6 अंक होने चाहिए',
  新的FlexibleIndia: 'फ्लेक्सिबलइंडिया के नए',
  '确定要退出登录吗？': 'क्या आप लॉग आउट और लॉगिन करना चाहते हैं?',
  退出登录成功: 'सफलतापूर्वक लॉग आउट',
  复制成功: 'सफलतापूर्वक कॉपी किया गया',
  请输入昵称: 'कृपया उपनाम दर्ज करें',
  ID: 'आईडी',
  修改真实姓名: 'अपना वास्तविक नाम बदलें',
  请输入真实姓名: 'कृपया अपना वास्तविक नाम दर्ज करें',
  请输入身份证: 'कृपया अपना आईडी कार्ड दर्ज करें',
  上传需要JPG或PNG格式: 'अपलोड के लिए JPG या PNG फॉर्मेट चाहिए',
  上传大小不能超过1MB: 'अपलोड का आकार 1MB से अधिक नहीं हो सकता',
  热门: 'लोकप्रिय',
  新的: 'नया',
  没有数据了: 'कोई डेटा नहीं है',
  加载数据失败: 'डेटा लोड करने में विफल',
  份已完成: 'प्रतियां पूरी हुईं',
  已发放金额: 'जारी की गई राशि',
  抢单: 'ऑर्डर लें',
  抢单1: 'ऑर्डर लें',
  抢单2: 'ऑर्डर लें',
  抢单3: 'ऑर्डर लें',
  抢单4: 'ऑर्डर लें',
  抢单5: 'ऑर्डर लें',
  抢单6: 'ऑर्डर लें',
  抢单7: 'ऑर्डर लें',
  抢单8: 'ऑर्डर लें',
  抢单9: 'ऑर्डर लें',
  提交: 'प्रस्तुत करें',
  提交1: 'प्रस्तुत करें',
  客服: 'ग्राहक सेवा',
  客服1: 'ग्राहक सेवा',
  请输入数字: 'कृपया संख्या दर्ज करें',
  抢单详情: 'विवरण प्राप्त करें',
  每人每天只能接一单: 'प्रत्येक व्यक्ति एक दिन में केवल एक ऑर्डर ले सकता है',
  支付中: 'भुगतान जारी है',
  逐单支付中: 'ऑर्डर के अनुसार भुगतान जारी है',
  全部支付完成: 'सभी भुगतान पूर्ण',
  '请输入...': 'कृपया दर्ज करें...',
  已提交: 'प्रस्तुत किया गया',
  审核通过: 'समीक्षा पारित',
  审核通过1: 'समीक्षा पारित',
  审核不通过: 'पुनरावृत्ति असफल',
  冻结: 'जमाना',
  暂停: 'विराम',
  审核拒绝: 'समीक्षा अस्वीकार',
  分享链接: 'लिंक साझा करें',
  新的手机号: 'नया फोन नंबर',
  新的密码: 'नया पासवर्ड',
  新的支付密码: 'नया भुगतान पासवर्ड',
  确认新的支付密码: 'नया भुगतान पासवर्ड की पुष्टि करें',
  新的支付密码需6位: 'नया भुगतान पासवर्ड में 6 अंक होने चाहिए',
  确认新的支付密码需6位: 'नए भुगतान पासवर्ड की पुष्टि में 6 अंक होने चाहिए',
  两次支付密码不一致: 'दो भुगतान पासवर्ड मेल नहीं खाते',
  新的支付密码需数字: 'नए भुगतान पासवर्ड में अंक होने चाहिए',
  确认新的支付密码需数字: 'नए भुगतान पासवर्ड की पुष्टि में अंक होने चाहिए',
  代理中心: 'एजेंसी केंद्र',
  代理须知: 'एजेंसी नोटिस',
  最新: 'नवीनतम',
  资金详情: 'धन का विवरण',
  我的团队: 'मेरी टीम',
  资金类型: 'धन प्रकार',
  选择时间: 'कृपया समय चुनें',
  时间: 'समय',
  金额: 'राशि',
  收入: 'आय',
  类型: 'प्रकार',
  欢迎登录: 'लॉगिन में आपका स्वागत है',
  账号: 'खाता',
  短信: 'एसएमएस',
  还没有帐户吗: 'क्या आपके पास अभी तक खाता नहीं है?',
  现在注册: 'अभी पंजीकरण करें',
  欢迎注册: 'पंजीकरण में आपका स्वागत है',
  有帐户: 'खाता है',
  发送: 'भेजें',
  忘记密码: 'पासवर्ड भूल गए',
  分享: 'साझा करें',
  手机号不存在: 'मोबाइल नंबर मौजूद नहीं है',
  请输入手机号: 'कृपया मोबाइल नंबर दर्ज करें',
  验证码错误: 'पुष्टिकरण कोड त्रुटि',
  手机号已存在: 'मोबाइल नंबर पहले से मौजूद है',
  订单号: 'ऑर्डर संख्या',
  清除缓存成功: 'कैश साफ करने में सफलता मिली',
  对话列表: 'वार्तालाप सूची',
  联系人列表: 'संपर्क सूची',
  新增upi地址: 'नया यूपीआई पता जोड़ें',
  每人终生只能接一次: 'प्रत्येक व्यक्ति जीवन में केवल एक बार ही ऑर्डर ले सकता है',
  每人同时只有一单: 'एक समय में प्रत्येक व्यक्ति का केवल एक ऑर्डर हो सकता है',
  每人同时接多单: 'एक समय में प्रत्येक व्यक्ति कई ऑर्डर ले सकता है',
  '每人每天只能接一单(规定提交时间)':
    'प्रत्येक व्यक्ति केवल एक ऑर्डर प्राप्त कर सकता है (निर्धारित जमा करने का समय)',
  每人每天接一单: 'प्रत्येक व्यक्ति प्रतिदिन एक ऑर्डर प्राप्त करेगा',
  '每人每天都可接一单(且此任务当日可以提交)': 'प्रत्येक व्यक्ति प्रति दिन एक ऑर्डर ले सकता है (और यह कार्य उसी दिन सबमिट किया जा सकता है)',
  上传图片重复: 'चित्र अपलोड दोहराया गया है',
  请输入upi地址: 'कृपया यूपीआई पता दर्ज करें',
  最热: 'सबसे लोकप्रिय',
  收藏: 'संग्रह',
  教程: 'ट्यूटोरियल',
  教学详情: 'शिक्षण विवरण',
  客户服务: 'ग्राहक सेवा',
  新手任务: 'नवीन कार्य',
  如何抢任务: 'कार्य कैसे प्राप्त करें',
  如何提现: 'फंड कैसे निकालें',
  如何找到客服: 'ग्राहक सेवा कैसे प्राप्त करें',
  步骤: 'चरण',
  选择任务并领取订单: 'एक कार्य चुनें और ऑर्डर प्राप्त करें।',
  提交任务所需的信息以供审核: 'समीक्षा के लिए कार्य के लिए आवश्यक जानकारी जमा करें।',
  完成任务后获得佣金并实时提现到您的账户:
    'कार्य पूरा करने के बाद कमीशन प्राप्त करें, और इसे आपके खाते में तुरंत निकालें।',
  点击个人中心页面进入提现页面: 'व्यक्तिगत केंद्र पृष्ठ पर क्लिक करें और निकासी पृष्ठ पर जाएं।',
  添加所选付款的银行卡信息: 'भुगतान के लिए चयनित बैंक कार्ड जानकारी जोड़ें।',
  填写提现金额和支付密码然后您可以开始申请提现:
    'निकासी राशि और भुगतान पासवर्ड दर्ज करें और फिर आप निकासी के लिए आवेदन कर सकते हैं।',
  关于任务: 'कार्य के बारे में',
  如果您在接收任务时不知道具体流程您可以点击客户进行咨询:
    'यदि आपको कार्य प्राप्त करते समय विशिष्ट प्रक्रिया का पता नहीं है, तो आप ग्राहक से परामर्श के लिए क्लिक कर सकते हैं।',
  关于其他问题: 'अन्य प्रश्नों के बारे में',
  选择在线客服: 'ऑनलाइन ग्राहक सेवा चुनें।',
  选择您要咨询的问题类型并与专业客服进行一对一咨询:
    'परामर्श के लिए प्रश्न के प्रकार का चयन करें और पेशेवर ग्राहक सेवा के साथ एक-से-एक परामर्श करें।',
  完成任务获得: 'कार्य पूरा करके प्राप्त करें',
  领取任务: 'कार्य प्राप्त करें',
  找客服: 'प्रश्न पूछें',
  设置信息: 'संदेश सेट करें',
  上一页: 'पिछला पृष्ठ',
  下一页: 'अगला',
  领取: 'प्राप्त करें',
  未设置: 'सेट नहीं है',
  请设置性别: 'कृपया लिंग सेट करें',
  请绑定您的手机号码: 'कृपया अपना फोन नंबर बाइंड करें',
  谷歌邮箱: 'गूगल मेल',
  请绑定您的谷歌邮箱: 'कृपया अपना गूगल ईमेल बाइंड करें',
  绑定谷歌邮箱: 'गूगल ईमेल बाइंड करें',
  添加手机号: 'फोन नंबर बदलें',
  账号必须大于6位: 'खाता 6 अंकों से अधिक होना चाहिए',
  任务ID: 'कार्य आईडी',
  任务量已满: 'कार्य मात्रा पूरी हो गई है',
  不建群: 'समूह नहीं बनाएं',
  facebook群: 'फेसबुक समूह',
  whatsapp群: 'व्हाट्सएप समूह',
  telegram群: 'टेलीग्राम समूह',
  线下任务: 'ऑफलाइन कार्य',
  完成别的订单后才可提交: 'अन्य आदेश पूरे करने के बाद ही जमा करें',
  tg两千人大群收集: 'टीजी 2,000 लोगों का एक बड़ा समूह',
  请登录: 'कृपया साइन इन करें',
  注销账号: 'खाता रद्द करें',
  提示: 'संकेत',
  确认注销此账号吗: 'क्या आप निश्चित है कि इस खाता को रद्द करना है',
  注销成功: 'सफलतापूर्वक लॉगआउट हुआ',
  上传视频: 'वीडियो अपलोड करें',
  可选择上传视频: 'वीडियो अपलोड करने का विकल्प',
  系统通知: 'प्रणाली',
  '在接受订单之前,您需要完成新手教程!':
    'ऑर्डर स्वीकार करने से पहले आपको नया ट्यूटोरियल पूरा करना होगा!',
  去完成: 'पूरा करें',
  手机号未注册: 'मोबाइल फोन नंबर पंजीकृत नहीं है',
  未绑定: 'बाइंड नहीं है',
  记录: 'रिकॉर्ड',
  提现记录: 'निकासी रिकॉर्ड',
  请上传银行卡: 'कृपया अपना बैंक कार्ड अपलोड करें',
  重新发送: 'फिर से भेजें',
  视频: 'वीडियो',
  上传图片: 'कृपया तस्वीरें अपलोड करें',
  视频演示: 'वीडियो प्रदर्शन',
  转账中: 'स्थानान्तरण में',
  转账成功: 'स्थानान्तरण सफल',
  转账失败: 'स्थानान्तरण असफल',
  人工终结: 'मैनुअल समाप्ति',
  三方超时: 'त्रिपार्टीट टाइमआउट',
  补单: 'संपूरक क्रम',
  会员权益: 'सदस्य के लाभ',
  会员享受4大福利: 'सदस्यों के लिए चार महान फायदे आनंद हैं',
  每日入住奖励: 'दिनांक प्रविष्टि प्रतिफल',
  高佣金任务: 'उच्च कमिशन कार्य',
  代理机制: 'एजेंटिशन योजनिस',
  收益保证: 'आगत गारंटी',
  退出: 'हस्ताक्षर करें',
  特权: 'अधिकार',
  详细资料: 'विस्तृत जानकारी',
  每日入住可获得10卢比超过10天不入住的会员将被取消会员资格:
    'दररोज प्रविष्टि 10 रूपी लेता है. सदस्यों के लिए जो 10 दिन से ज़्यादा नहीं जोड़ेंगे उनके सदस्य को रद्द कर देंगे।',
  优先权接受高佣金任务提款优先:
    'प्राथमिकता, उच्च कमिशियन कार्य स्वीकार, तथा प्राथमिकता प्राथमिकता प्राप्त करें',
  优先参与代理机制: 'एजेंसिटी यंत्राधिकार में प्राथमिकता सहभाग',
  如果您在一个月内没有赚取10000卢比您的会员费将直接退还:
    'यदि आप एक महीने में 10000 रूपी नहीं प्राप्त करेंगे, तो आपके सदस्य फी',
  排行榜: 'रेन्ग सूची',
  了解详情: 'और अधिक जानें',
  邀请码: 'आमंत्रण कोड',
  邀请链接: 'आमंत्रण लिंक',
  邀请二维码: 'आमंत्रण क्यूआर कोड',
  点击按钮保存或截图将二维码保存到手机:
    'बटन पर क्लिक करें या स्क्रीनशॉट लेकर क्यूआर कोड को अपने फोन में सहेजें',
  保存到手机: 'फोन में सहेजें',
  总返佣: 'कुल कमीशन',
  接单总数: 'प्राप्त आदेशों की कुल संख्या',
  总人数: 'कुल लोगों की संख्या',
  今天的佣金: 'आज का कमीशन',
  昨天的佣金: 'कल का कमीशन',
  周奖励: 'सप्ताहिक बदलाव',
  月奖励: 'महीने से बदलाव',
  代理通知: 'एजेंट सूचना',
  排名: 'रेजिंग',
  会员信息: 'सदस्य जानकारी',
  未上榜: 'सूची में नहीं',
  我: 'I',
  提现排行榜: 'विटदवाल रेन्किंग',
  接单排行榜: 'क्रम स्वीकार रेजिंग सूची',
  昨日榜: 'कल की सूची',
  今日榜: 'आज की सूची',
  周榜: 'सप्ताह सूची',
  月榜: 'महीना चार्ट',
  总榜: 'सारा रेजिंग',
  提现金额1: 'निकासी राशि',
  代理: 'अभिनय',
  请选择一个时间: ' कृपया एक समय चुनें',
  下级会员: 'कनिष्ठ सदस्य',
  '例如，当 A 邀请 B，B 邀请 C，C 邀请 D，而 D 提现时：': 'उदाहरण के लिए, जब A ने B को आमंत्रित किया, B ने C को आमंत्रित किया, और C ने D को आमंत्रित किया, और D ने धन निकासी की：',
  'A 可以获得高达 0.5% 的佣金': 'A {0}% तक का कमीशन कमा सकता है',
  'B 可以获得高达 1% 的佣金': 'B {0}% तक का कमीशन कमा सकता है',
  'C 可以获得高达 1.4% 的佣金': 'C {0}% तक का कमीशन कमा सकता है',
  '三层邀请关系之外没有佣金。': 'तीन स्तरों से अधिक के आमंत्रण संबंधों के लिए कोई कमीशन नहीं है।',
  代理条件: 'एजेंसी की शर्तें',
  代理等级: 'एजेंसी स्तर',
  直接会员提现限额: 'प्रत्यक्ष सदस्य निकासी सीमा',
  直接会员人数: 'प्रत्यक्ष सदस्य संख्या',
  代理返佣比例: 'एजेंसी कमीशन अनुपात',
  一级代理: 'प्राथमिक एजेंसी',
  二级代理: 'द्वितीयक एजेंसी',
  三级代理: 'तृतीयक एजेंसी',
  代理升级: 'एजेंसी उन्नयन',
  '代理等级每月 15 日 00:00 计算。计算完成后实时生效': 'एजेंसी स्तर की गणना हर महीने की 15 तारीख को 00:00 बजे की जाती है। गणना के बाद तुरंत प्रभावी होता है',
  加入群聊: 'समूह संवाद में जोड़ें',
  直属下级: 'सीधे उपनिर्धारित',
  我已阅读并同意: 'मैंने पढ़या है और स्वीकार किया है',
  注册协议: 'रेजिस्ट्रेशन एमेंट्री',
  和: 'हैऔर',
  隐私政策: '《निजी नीति》',
  请勾选协议: 'कृपया समझौते को चुनें',
  领取成功: 'सफलतापूर्वक दावा किया',
  签到中心: 'चेक-इन केंद्र',
  规则: 'नियम',
  拉手币: 'हाथ का सिक्का',
  已连续签到: 'दाखिल करना',
  天: 'दिन',
  剩余补签次数: 'पुनः हस्ताक्षर करने वालों की संख्या शेष है',
  签到奖励: 'साइन-इन पुरस्कार',
  奖励: 'इनाम',
  连续签到: 'लगातार साइन-इन',
  规则说明: 'नियम विवरण',
  活动规则: 'इवेंट नियम',
  每日签到奖励: 'दैनिक साइन-इन इनाम',
  漏签后一个月内可补签: 'मिसिंग के बाद एक महीने के भीतर साइन-इन बनाएं',
  次: 'बार',
  次数不转存: 'बार की संख्या संग्रहीत नहीं की जाती',
  补签一次扣除: 'एक मेकअप साइन-इन के लिए कटौती',
  连续签到固定天数后可领取随机大额拉手币: 'नियत दिनों के लगातार साइन-इन के बाद रैंडम बड़ी मात्रा में लाशौ सिक्के प्राप्त करें',
  仅显示并记录当月签到记录: 'केवल वर्तमान महीने के साइन-इन रिकॉर्ड प्रदर्शित और रिकॉर्ड करें',
  签到成功: 'साइन-इन सफल',
  不能签到未来的日期: 'भविष्य की तारीखों में साइन इन नहीं किया जा सकता',
  贡献值: 'योगदान',
  立刻充值: 'तुरंत रिचार्ज करें',
  立刻提现: 'निकालना',
  协议及政策: 'समझौते और नीतियां',
  隐私协议: 'गोपनीयता समझौता',
  我的拉手币: 'मेरा हैंडल सिक्का',
  选择年月: 'वर्ष और महीना चुनें',
  连续签到5天奖励: 'लगातार 5 दिनों तक साइन इन करने पर पुरस्कार',
  连续签到15天奖励: 'लगातार 15 दिनों तक साइन इन करने का इनाम',
  连续签到30天奖励: 'लगातार 30 दिनों तक साइन इन करने का इनाम',
  补签扣款: 'दोबारा हस्ताक्षर करने पर कटौती',
  抽奖: 'लॉटरी',
  获得: 'पाना',
  消耗: 'उपभोग करना',
  年: 'वर्ष',
  月: 'महीना',
  账单类型: 'बिल प्रकार',
  成长值: 'विकास मूल्य',
  会员: 'सदस्य',
  需要: 'ज़रूरत',
  距下一等级还差: 'अभी भी अगले स्तर से दूर है',
  还未到当前等级: 'अभी मौजूदा स्तर पर नहीं है',
  中奖记录: 'जीत का रिकॉर्ड',
  奖金: 'इनाम राशि',
  开始: 'शुरू',
  剩余次数: 'शेष अवसर',
  点击抽奖: 'लकी ड्रॉ पर क्लिक करें',
  中奖名单: 'विजेता सूची',
  奖品: 'पुरस्कार',
  抽奖说明: 'लकी ड्रॉ के निर्देश',
  '1.会员等级：获得会员等级后可参与抽奖；不同等级对应不同抽奖次数': '1.सदस्य स्तर: सदस्य स्तर प्राप्त करने के बाद लकी ड्रॉ में भाग ले सकते हैं; विभिन्न स्तरों के लिए अलग-अलग ड्रॉ अवसर हैं',
  会员等级: 'सदस्य स्तर',
  抽奖次数: 'लकी ड्रॉ अवसर',
  单次消耗: 'एकल खपत',
  '2.奖品设置：小米手机、摩托车、日用品、电子产品等；中奖率高达100%': '2.पुरस्कार सेटिंग्स: Xiaomi फोन, मोटरसाइकिल, दैनिक उपयोग की वस्तुएं, इलेक्ट्रॉनिक उत्पाद आदि; जीतने की दर 100% तक',
  '3.奖品兑换：抽中奖品后，填写收货地址，静待收货；中奖后7天未兑换，视为自动放弃本次奖品': '3.पुरस्कार रिडेम्पशन: पुरस्कार जीतने के बाद, शिपिंग पता भरें और वितरण का इंतजार करें; जीतने के 7 दिनों के बाद रिडेम्पशन न होने पर इसे स्वचालित रूप से छोड़ दिया जाता है',
  '1.为广大会员提供福利，平台决定将每日营业收入的10%纳入奖池': '1.सदस्यों को लाभ प्रदान करने के लिए, प्लेटफ़ॉर्म ने दैनिक राजस्व का 10% पुरस्कार पूल में जोड़ने का निर्णय लिया है',
  '2.奖池累计足额现金后将会随机在抽奖中掉落中奖机会': '2.जब पुरस्कार पूल पर्याप्त नकदी जमा कर लेता है, तो लकी ड्रॉ में जीतने का मौका रैंडमली गिरता है',
  '3.会员级别越高，被抽中的概率越高哦': '3.सदस्य स्तर जितना ऊंचा होगा, चुने जाने की संभावना उतनी ही अधिक होगी',
  恭喜您成功抽到: 'बधाई हो, आपने सफलतापूर्वक जीता',
  很遗憾: 'बहुत अफ़सोस',
  立即兑换: 'तुरंत रिडीम करें',
  再来一次: 'फिर से प्रयास करें',
  '抽奖次数将消耗拉手币请知悉!': 'कृपया ध्यान दें कि लॉटरी के प्रयास लाशो सिक्के खर्च करेंगे!',
  不在提示: 'फिर से सूचित न करें',
  我知道了: 'मुझे पता है',
  待兑奖: 'रिडेम्पशन लंबित',
  已兑奖: 'रिडीम हो गया',
  已失效: 'अवधि समाप्त',
  中奖者: 'विजेता',
  中奖时间: 'जीतने का समय',
  兑奖时间: 'भुनाने का समय',
  结束时间: 'अंत समय',
  有效期至: 'तब तक वैध',
  兑换: 'अदला-बदली',
  查看订单: 'आदेश की जाँच करें',
  收货信息补充: 'प्राप्ति सूचना अनुपूरक',
  收货人名称: 'प्रेषिती का नाम',
  请输入收货人名称: 'कृपया परेषिती का नाम दर्ज करें',
  联系方式: 'संपर्क जानकारी',
  请输入联系方式: 'कृपया संपर्क जानकारी दर्ज करें',
  收货人地址: 'प्राप्तकर्ता का पता',
  请输入收货人地址: 'कृपया परेषिती का पता दर्ज करें',
  备注: 'टिप्पणी',
  请输入备注: 'कृपया टिप्पणियाँ दर्ज करें',
  添加成功: 'सफलतापूर्वक जोड़ा गया',
  兑奖成功: 'सफलतापूर्वक भुनाएं',
  请注册: 'कृपया दर्ज करें',
  手机号: 'फ़ोन नंबर',
  订单详情: 'ऑर्डर का विवरण',
  收货信息: 'वितरण की जानकारी',
  订单信息: 'आदेश की जानकारी',
  兑换单号: 'विनिमय आदेश संख्या',
  物流单号: 'लदान संख्या',
  // 理财
  我的理财: 'मेरी वित्त',
  理财市场: 'वित्तीय बाजार',
  我的持有: 'मेरी होल्डिंग्स',
  年化利率: 'वार्षिक ब्याज दर',
  当前资产: 'वर्तमान संपत्ति',
  昨日收益: 'कल की कमाई',
  本月收益: 'इस महीने की कमाई',
  收益明细: 'कमाई विवरण',
  交易记录: 'लेनदेन रिकॉर्ड',
  总收益: 'कुल कमाई',
  买入: 'खरीद',
  买入金额: 'खरीद राशि',
  付款方式: 'भुगतान विधि',
  账户余额: 'खाता शेष',
  基金委托理财说明书: 'फंड सौंपा गया वित्तीय निर्देश',
  付款: 'भुगतान',
  卖出: 'बेचना',
  利息: 'ब्याज',
  违约金: 'जुर्माना',
  到期卖出: 'समाप्ति पर बेचें',
  提前卖出: 'जल्दी बेचो',
  余额不足: 'अपर्याप्त शेषराशि',
  '买入后2日内卖出将根据协议扣除信誉违约金，信誉违约金=购买金额的30%，确认卖出吗？': 'यदि आप खरीदने के 2 दिनों के भीतर बेचते हैं, तो आपसे समझौते के अनुसार क्रेडिट डिफ़ॉल्ट जुर्माना लगाया जाएगा। क्या आप बिक्री की पुष्टि करते हैं?',
  '买入后2日内卖出将根据协议扣除信誉违约金，信誉违约金=购买金额的30%，确认买入吗？': 'यदि आप खरीदने के बाद 2 दिनों के भीतर बेचते हैं, तो समझौते के अनुसार क्रेडिट जुर्माना काट लिया जाएगा = खरीद राशि का 30%।',
  请阅读并同意: 'कृपया पढ़ें और सहमत हों',
  充值金额: 'पुनर्भरण राशि',
  请输入金额: 'कृपया राशि दर्ज करें',
  请输入: 'कृपया दर्ज करें',
  充值凭证: 'रिचार्ज वाउचर',
  请输入充值金额: 'कृपया रिचार्ज राशि दर्ज करें',
  请选择充值渠道: 'कृपया एक रिचार्ज चैनल चुनें',
  请输入12位数字的UTR: 'कृपया 12 अंकों का यूटीआर दर्ज करें',
  请上传凭证: 'कृपया प्रमाणपत्र अपलोड करें',
  去充值: 'रिचार्ज पर जाएं',
  充值账户: 'अकाउंट रिचार्ज करें',
  理财产品充值: 'वित्तीय उत्पादों को रिचार्ज करें',
  理财产品买入: 'वित्तीय उत्पाद खरीद',
  理财产品产生的金额: 'वित्तीय उत्पादों द्वारा उत्पन्न राशि',
  理财产品卖出: 'वित्तीय उत्पाद बेचें',
  理财产品违约卖出: 'डिफ़ॉल्ट पर बेचे गए वित्तीय उत्पाद',
  账变类型: 'खाता परिवर्तन किस्म',
  佣金: 'कमिशन',
  余额: 'बैलेन्स',
  任务: 'कार्य',
  论坛: 'फ़ॉर्म',
  '充值申请已提交，待审核！': 'पुनर्भरण आवेदन जमा कर दिया गया है और समीक्षा लंबित है!',
  点赞: 'पसंद करें',
  发布: 'प्रकाशित करें',
  游戏: 'खेल',
  生活: 'जीवन',
  新闻: 'समाचार',
  收起: 'संकुचित करें',
  展开: 'विस्तारित करें',
  待审核: 'समीक्षा लंबित',
  已通过: 'उत्तीर्ण',
  未通过: 'उत्तीर्ण नहीं हुआ',
  复制链接: 'लिंक कॉपी करें',
  热门推荐: 'लोकप्रिय सिफारिशें',
  删除帖子: 'पोस्ट हटाएं',
  编辑帖子: 'पोस्ट संपादित करें',
  确认删除吗: 'हटाने की पुष्टि करें?',
  删除成功: 'सफलतापूर्वक हटाया गया',
  发布动态: 'अपडेट पोस्ट करें',
  '快来发布动态吧~': 'आओ और अपडेट पोस्ट करें~',
  请输入内容: 'कृपया सामग्री दर्ज करें',
  动态详情: 'अपडेट विवरण',
  全部评论: 'सभी टिप्पणियाँ',
  作者: 'लेखक',
  回复: 'उत्तर दें',
  '友善评论，文明发言': 'दोस्ताना टिप्पणियाँ, सभ्य भाषण',
  搜索: 'खोजें',
  请输入搜索关键词: 'कृपया खोज कीवर्ड दर्ज करें',
  上热门: 'प्रचलन में',
  热门时长: 'प्रचलन अवधि',
  论坛帖子上热门: 'लोकप्रिय फ़ोरम पोस्ट',
  '已发布，待审核': 'प्रकाशित, समीक्षा लंबित',
  '确认删除搜索记录吗？': 'क्या आप वाकई अपना खोज इतिहास हटाना चाहते हैं?',
  图片上传失败: 'छवि अपलोड विफल रहा',
  发布任务: "कोई कार्य पोस्ट करें",
  任务标题: "कार्य का शीर्षक",
  请输入任务标题: "कृपया कार्य शीर्षक दर्ज करें",
  请输入任务要求: "कृपया कार्य आवश्यकताएँ दर्ज करें",
  任务单数: "कार्यों की संख्या",
  请输入任务单数: "कृपया ऑर्डर की संख्या दर्ज करें",
  请输入任务单价: "कृपया कार्य इकाई मूल्य दर्ज करें",
  任务开始时间: "कार्य प्रारंभ समय",
  点击选择时间: "समय चुनने के लिए क्लिक करें",
  请选择任务开始时间: "कृपया कार्य आरंभ समय चुनें",
  预付佣金: "अग्रिम कमीशन",
  手续费: "संचालन शुल्क",
  实付金额: "भुगतान की गई वास्तविक राशि",
  支付并发布: "भुगतान करें और प्रकाशित करें",
  确认你的任务信息: 'अपने मिशन की जानकारी की पुष्टि करें',
  我发布的: 'मैंने पोस्ट किया',
  发放金额: 'संवितरण राशि',
  推广任务: 'प्रचारात्मक कार्य',
  结束任务: 'कार्य का अंत करें',
  本次推广于: 'ये प्रमोशन चालू है',
  请到期后续费推广: 'कृपया पदोन्नति शुल्क देय होने पर उसका पालन करें',
  '该任务尚未结束，确认提前结束吗？': 'यह कार्य अभी ख़त्म नहीं हुआ है क्या आप आश्वस्त हैं कि यह जल्दी ख़त्म हो जाएगा?',
  到期: 'परिपक्वता',
  提交审核时间: 'समीक्षा समय सबमिट करें',
  开始时间: 'समय शुरू',
  '存在佣金争议，请联系': 'यदि कोई कमीशन विवाद हो तो कृपया संपर्क करें',
  系统客服: 'सिस्टम ग्राहक सेवा',
  日: 'दिन',
  商户入驻会员发布任务: 'व्यापारी सदस्य कार्य प्रकाशित करते हैं',
  信用: 'श्रेय',
  信用等级: 'क्रेडिट रेटिंग',
  '我发布的任务结算金额+100，积分+1': 'मेरे द्वारा पोस्ट किए गए कार्यों की निपटान राशि +100 है, और अंक +1 हैं',
  '10积分=1级；20积分=2级；30积分=3级；40积分=4级；50积分=5级': '10 अंक = स्तर 1; 20 अंक = स्तर 2; 40 अंक = स्तर 4;',
  任务推广: 'कार्य प्रगति',
  审核时间: 'समीक्षा का समय',
  时: 'घंटे',
  分: 'मिनट',
  秒: 'सेकंड',
  会员信用等级: 'सदस्य क्रेडिट रेटिंग',
  积分来源: 'अंक स्रोत',
  我发布的任务结算金额满足一定金额条件后自动获得积分: 'मेरे द्वारा पोस्ट किए गए कार्य की निपटान राशि निश्चित राशि शर्तों को पूरा करने के बाद मुझे स्वचालित रूप से अंक प्राप्त होंगे।',
  级: 'कक्षा',
  积分: 'अभिन्न',
  '结算金额 ：积分': 'निपटान राशि : अंक',
  理财: 'आर्थिक',
  拒绝理由: 'खारिज करने का कारण',
  内容因违规已被删除: 'उल्लंघनों के कारण सामग्री हटा दी गई है',
  '已上传图片，无法上传视频': 'चित्र अपलोड कर दिए गए हैं, लेकिन वीडियो अपलोड नहीं किए जा सकते',
  自然热门: 'स्वाभाविक रूप से लोकप्रिय',
  单价不能少于: 'इकाई मूल्य से कम नहीं हो सकता',
  拒绝: 'अस्वीकार करना',
  通过: 'उत्तीर्ण',
  请输入拒绝理由: 'कृपया अस्वीकृति का कारण दर्ज करें',
  提交订单详情: 'ऑर्डर विवरण सबमिट करें',
  提交订单列表: 'ऑर्डर सूची जमा करें',
  货币兑换: 'मुद्रा विनिमय',
  从: 'से',
  到: 'आना',
  请输入兑换数量: 'कृपया मोचन मात्रा दर्ज करें',
  最大: 'अधिकतम',
  最多可兑换: 'अधिकतम प्रतिदेय',
  需支付手续费: 'एक हैंडलिंग शुल्क आवश्यक है',
  拉手币和余额: 'लशौ सिक्के और संतुलन',
  '余额可提现，拉手币不能提现': 'शेष राशि निकाली जा सकती है, लेकिन हैंडल के सिक्के नहीं निकाले जा सकते।',
  拉手币兑现为余额收取: 'लैशौ सिक्कों को भुनाते समय शेष राशि का शुल्क लिया जाएगा',
  余额兑换为拉手币收取: 'शेष शेष राशि को लाशोउ सिक्कों में परिवर्तित किया जाएगा और एकत्र किया जाएगा।',
  数量不能大于: 'मात्रा इससे अधिक नहीं हो सकती',
  兑换成功: 'मोचन सफल',
  货币兑换手续费: 'मुद्रा विनिमय शुल्क',
  拉手币余额: 'लशौ सिक्का संतुलन'
};