// 节流和防抖
// 应用场景
// 防抖debounce在连续的事件，只需触发一次回调
// 节流throttle在间隔一段时间执行一次回调
// 使用防抖
// const debouncedClick = useDebounceAndThrottle(handleClick, 1000, 'debounce');
// 使用节流
// const throttledClick = useDebounceAndThrottle(handleClick, 1000, 'throttle');

import { ref, onUnmounted } from 'vue';
import { Toast } from 'vant';
import il8n from '@/locales/index';

export function USE_DEBOUNCE_AND_THROTTLE<T extends (...args: any[]) => any>(
  fn: T,
  delay: number,
  type: 'debounce' | 'throttle'
) {
  const timer = ref<NodeJS.Timeout | null>(null);

  function debounceAndThrottle(...args: Parameters<T>) {
    if (type === 'debounce') {
      if (timer.value) {
        clearTimeout(timer.value);
      }
      timer.value = setTimeout(() => {
        fn(...args);
      }, delay) as NodeJS.Timeout;
    } else if (type === 'throttle') {
      if (!timer.value) {
        timer.value = setTimeout(() => {
          timer.value = null;
        }, delay) as NodeJS.Timeout;
        fn(...args);
      }
    }
  }

  onUnmounted(() => {
    if (timer.value) {
      clearTimeout(timer.value);
    }
  });

  return debounceAndThrottle;
}
// 拷贝赋值
export const COPY_ASSIGNMENT = (val: any): void => {
  // 如果包含 HTML 标签则去除，否则直接复制
  const copiedText = /<[^>]+>/.test(val) ? val.replace(/<[^>]+>/g, '') : val;
  let input = document.createElement('input');
  input.value = copiedText;
  document.body.appendChild(input);
  input.select();
  document.execCommand('Copy');
  input.remove();
  Toast.success(il8n.global.t('复制成功'));
};
