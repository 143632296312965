import { createPinia } from 'pinia';
import piniaPersist from 'pinia-plugin-persist';

const pinia = createPinia();
pinia.use(piniaPersist);

export default pinia;

export * from './modules/user';
export * from './modules/menu';
export * from './modules/layout';
export * from './modules/TXIM';
export * from './modules/captureMistake';
export * from './modules/index';
export * from './modules/other';
