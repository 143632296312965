<template>
  <div class="custom">
    <template v-if="isCustom.businessID === CHAT_MSG_CUSTOM_TYPE.SERVICE">
      <div>
        <h1>
          <label>{{ extension.title }}</label>
          <a v-if="extension.hyperlinks_text" :href="extension.hyperlinks_text.value" target="view_window">{{
            extension.hyperlinks_text.key }}</a>
        </h1>
        <ul v-if="extension.item && extension.item.length > 0">
          <li v-for="(item, index) in extension.item" :key="index">
            <a v-if="isUrl(item.value)" :href="item.value" target="view_window">{{ item.key }}</a>
            <p v-else>
              {{ item.key }}
            </p>
          </li>
        </ul>
        <article>{{ extension.description }}</article>
      </div>
    </template>
    <template v-else-if="isCustom.businessID === CHAT_MSG_CUSTOM_TYPE.EVALUATE">
      <div class="evaluate">
        <h1>{{ TUITranslateService.t('message.custom.对本次服务评价') }}</h1>
        <ul class="evaluate-list">
          <li v-for="(item, index) in Math.max(isCustom.score ? isCustom.score : 0, 0)" :key="index"
            class="evaluate-list-item">
            <Icon :file="star" class="file-icon" />
          </li>
        </ul>
        <article>{{ isCustom.comment }}</article>
      </div>
    </template>
    <template v-else-if="isCustom.businessID === CHAT_MSG_CUSTOM_TYPE.ORDER">
      <div class="order" @click="openLink(isCustom.link)">
        <img :src="isCustom.imageUrl ? isCustom.imageUrl : ''" />
        <main>
          <h1>{{ isCustom.title }}</h1>
          <p>{{ isCustom.description }}</p>
          <span>{{ isCustom.price }}</span>
        </main>
      </div>
    </template>
    <template v-else-if="isCustom.businessID === CHAT_MSG_CUSTOM_TYPE.LINK">
      <div class="textLink">
        <p>{{ isCustom.text }}</p>
        <a :href="isCustom.link ? isCustom.link : ''" target="view_window">{{
          TUITranslateService.t('message.custom.查看详情>>')
        }}</a>
      </div>
    </template>
    <!-- 自定义消息处理 接单任务确认-->
    <template v-else-if="isCustom[0].businessID === 'Merchant'">
      <div class="customizeBox">
        <div class="top">
          <li>
            <img src="@/assets/home/zan.png" alt="" />
            <!-- <img v-for="(item, index) in customize.assets" :key="index" :src="item.url.startsWith('http')
              ? item.url
              : state.avatarPath + item.url
              " alt="" /> -->
            <span>{{ customize.title }}</span>
          </li>
          <p v-html="customize.detail"></p>
        </div>
        <div class="mid">
          <li>{{ new Date(customize.time_end).toLocaleString() }}</li>
        </div>
        <div v-if="customize.num_total && customize.pay_total" style="display: flex;justify-content: flex-end; margin-top: 15px;">
          {{ customize.num_total }} x {{ customize.pay_total }} 
        </div>
        <div class="bot">
          <p @click="handleCustomizeConfirm">{{ $t('确认') }}</p>
        </div>
      </div>
    </template>
    <!-- 自定义消息处理 下发手机号 -->
    <template v-else-if="isCustom[0].businessID === 'Task'">
      <div class="customizePhoneBox">
        <div class="tab">
          <p class="left" @click="handlecustomizePhoneCopy(customizePhone.text)">
            <img class="left" src="@/assets/icons/copy.png" alt="" />
          </p>
          <p @click="generateVcf(customizePhone.text)">
            <img src="@/assets/icons/xz.png" alt="" />
          </p>
        </div>

        <ul>
          <li v-for="item in customizePhone.text" :key="item">
            <span>{{ item }}</span>
          </li>
        </ul>
      </div>
    </template>
    <!-- 自定义消息处理 名片 -->
    <template v-else-if="isCustom[0].businessID === 'ServiceCard'">
      <div> 分享名片 </div>
    </template>
    <template v-else-if="isCustom[0].businessID === 'Estimation'">
      <div class="evaluate">
        <div class="title">{{ isCustom[0].text.title }}</div>
        <div class="evaList">
          <div @click="postEvaluate(isCustom[0].text, 1)">
            <img v-if="isCustom[0].text.rate == 1" src="@/assets/im/face_0.png" mode="scaleToFill" />
            <img v-else src="@/assets/im/face_un_0.png" mode="scaleToFill" />
          </div>
          <div @click="postEvaluate(isCustom[0].text, 2)">
            <img v-if="isCustom[0].text.rate == 2" src="@/assets/im/face_1.png" mode="scaleToFill" />
            <img v-else src="@/assets/im/face_un_1.png" mode="scaleToFill" />
          </div>
          <div @click="postEvaluate(isCustom[0].text, 3)">
            <img v-if="isCustom[0].text.rate == 3" src="@/assets/im/face_2.png" mode="scaleToFill" />
            <img v-else src="@/assets/im/face_un_2.png" mode="scaleToFill" />
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <!-- {{content}} -->
      <div>{{ isCustom[0].text }}</div>
      <!-- <span v-html="content.custom"></span> -->
    </template>
  </div>
</template>

<script lang="ts" setup>
import { watchEffect, ref, reactive, onMounted } from '../../../../adapter-vue';
import { useRoute, useRouter } from 'vue-router';
import { TUITranslateService, IMessageModel } from '@tencentcloud/chat-uikit-engine';
import { isUrl, JSONToObject } from '../../../../utils/index';
import { CHAT_MSG_CUSTOM_TYPE } from '../../../../constant';
import { ICustomMessagePayload } from '../../../../interface';
import Icon from '../../../common/Icon.vue';
import star from '../../../../assets/icon/star-light.png';
import { API } from '../../../../../pages/orders/api/index';
import { Toast, TOAST_TYPE } from '../../../../components/common/Toast';
import { useLoading } from '../../../../../../src/hooks/index';
import { useI18n } from 'vue-i18n';
import { commonApi } from '../../../../../api/commonApi';
import {
  USE_DEBOUNCE_AND_THROTTLE,
  COPY_ASSIGNMENT,
} from '../../../../../utils/throwingStabilization';
import { useTximStore } from '../../../../../store/index';
import eventBus from '../../../../../utils/eventBus';
const { t, locale } = useI18n();
const { startLoading, stopLoading } = useLoading();
const route = useRoute();
const router = useRouter();
interface Props {
  messageItem: IMessageModel | any;
  content: any;
}
const props = withDefaults(defineProps<Props>(), {
  messageItem: null,
  content: undefined,
});

const custom = ref();
const message = ref<IMessageModel>();
const extension = ref();
// 自定义消息 抢单
const customize = ref(Object());
// 自定义消息 下发手机
const customizePhone = ref();
const state = reactive({
  avatarPath: localStorage.getItem('sysconfig')
    ? JSON.parse(localStorage.getItem('sysconfig') as string).assets_url
    : '',
  task_id: '' as unknown,
  num_grab: '' as unknown,
  userInfo: localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo') as string)
    : Object(),
  type:'' as unknown
});
const isCustom = ref<ICustomMessagePayload>({
  businessID: '',
});

onMounted(() => {
  state.task_id = route.query ? route.query.task_id : '';
  state.num_grab = route.query ? route.query.num_grab : '';
  // state.type = route.query ? route.query.type : ''
});

watchEffect(() => {

  custom.value = props.content;
  message.value = props.messageItem;
  const { payload } = props.messageItem;
  isCustom.value = payload.data || '';
  isCustom.value = JSONToObject(payload.data);
  console.log(isCustom.value, 'cus----------------------------------------------------------------------------------')

  if (payload.data === CHAT_MSG_CUSTOM_TYPE.SERVICE) {
    extension.value = JSONToObject(payload.extension);
  }
  if (isCustom.value[0].text) {
    customize.value = JSONToObject(isCustom.value[0].text);
  }
  if (customize.value.assets) {
    customize.value.assets = JSONToObject(customize.value.assets);
  }
  if (isCustom.value[0].params == 'phone_msg') {
    customizePhone.value = isCustom.value[0] ? isCustom.value[0] : [];
    customizePhone.value.text = customizePhone.value.text.split(',');
  }
  if (isCustom.value[0].businessID == 'Estimation') {
    isCustom.value[0].text = JSONToObject(isCustom.value[0].text);
  }
});
const openLink = (url: any) => {
  window.open(url);
};

const generateVcfContent = (name: string, phone: string) =>
  `
BEGIN:VCARD
VERSION:3.0
FN:${name}
TEL:${phone}
END:VCARD
    `.trim();
//下载vcf格式数据
const generateVcf = (data: any) => {
  let vcfArray = [] as any;
  data.map((item: any) => {
    vcfArray.push(generateVcfContent(item, item));
  });
  const blob = new Blob([vcfArray.join('\n')], { type: 'text/vcard' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `index.vcf`;
  a.click();
  URL.revokeObjectURL(url);
};

const postEvaluate = async (item: any, index: number) => {
  let params = {
    post_txcode: item.post_txcode,
    grab_txcode: Number(state.userInfo.txcode),
    rate: index,
    grab_id: item.grab_id,
  };
  const { code, data, info } = await commonApi.edit_estimation(params);
  if (code == 1) {
    Toast({
      message: info,
      type: TOAST_TYPE.SUCCESS,
    });
    useTximStore().SET_MODIFYMESSAGE(props.messageItem, index);
  } else {
    Toast({
      message: info,
      type: TOAST_TYPE.ERROR,
    });
  }
};

// 自定义消息确认按钮
const handleCustomizeConfirm = async () => {
  startLoading();
  console.log("isCustom.value[0]", state.num_grab , isCustom.value[0].params.num_grab , customize.value.num_grab)
  const params = {
    task_id: Number(state.task_id ? state.task_id : customize.value.id),
    num_grab: Number(
      state.num_grab || isCustom.value[0].params.num_grab || customize.value.num_grab
    ),
    source: 1,
  };
  const { code, data, info } = await API.taskGrab(params);
  if (code == 1) {
    Toast({
      message: info,
      type: TOAST_TYPE.SUCCESS,
    });
    stopLoading();
    // 如果是返回接口列表，需要刷新
    // if(state.type == 1){
    //    //返回orders页面
    //    router.push({ path: '/orders', query: { taskInfo: JSON.stringify(data.taskInfo) } });
    // }else{
    //   let event = new CustomEvent("updateRef", { detail: data.taskInfo });
    //   eventBus.value.dispatchEvent(event);
    //   router.go(-1);
    // }
    let event = new CustomEvent("updateRef", { detail: data.taskInfo });
    eventBus.value.dispatchEvent(event);
    router.go(-1);
  } else {
    Toast({
      message: info,
      type: TOAST_TYPE.ERROR,
    });
    stopLoading();
  }
};

// 自定义消息  复制手机号
const handlecustomizePhoneCopy = USE_DEBOUNCE_AND_THROTTLE(COPY_ASSIGNMENT, 2000, 'throttle');
</script>
<style lang="scss" scoped>
@import '../../../../assets/styles/common';

.customizePhoneBox {
  >p {
    text-align: right;
    margin-bottom: 10px;

    >img {
      width: 20px;
      height: 20px;
      object-fit: fill;
    }
  }

  >ul {
    >li {
      margin-bottom: 10px;
    }

    >li:nth-last-child(1) {
      margin-bottom: 0;
    }
  }
}

.customizeBox {
  padding: 10px;
  width: 100%;
  min-width: 70vw;
  .top {
    >li {
      display: flex;
      align-items: center;
      // justify-content: space-between;

      >img {
        width: 36px;
        height: 36px;
        object-fit: fill;
      }

      >span {
        font-size: 14px;
        font-weight: 500;
        margin-left: 5px;
      }
    }

    >p {
      padding: 20px 0;
      font-size: 12px;
      color: #999999;
    }
  }

  .mid {
    >li:nth-child(1) {
      font-size: 12px;
      color: #999999;
    }
  }

  .bot {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 10px;

    >p {
      background-color: #5788ff;
      color: #fff;
      padding: 4px 10px;
      border-radius: 10px;
    }
  }
}

a {
  color: #679ce1;
}

.custom {
  font-size: 14px;

  h1 {
    font-size: 14px;
    color: #000;
  }

  h1,
  a,
  p {
    font-size: 14px;
  }

  .evaluate {
    ul {
      display: flex;
      padding: 10px 0;
    }

    &-list {
      display: flex;
      flex-direction: row;

      &-item {
        padding: 0 2px;
      }
    }
  }

  .order {
    display: flex;

    main {
      padding-left: 5px;

      p {
        font-family: PingFangSC-Regular;
        width: 145px;
        line-height: 17px;
        font-size: 14px;
        color: #999;
        letter-spacing: 0;
        margin-bottom: 6px;
        word-break: break-word;
      }

      span {
        font-family: PingFangSC-Regular;
        line-height: 25px;
        color: #ff7201;
      }
    }

    img {
      width: 67px;
      height: 67px;
    }
  }
}

.tab {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;

  .left {
    margin-right: 6px;
  }

  img {
    width: 20px;
    height: 20px;
    object-fit: fill;
  }
}

.evaluate {
  line-height: 1.5;

  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    /*将对象作为弹性伸缩盒子模型显示*/
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .evaList {
    display: flex;

    img {
      width: 36px;
      height: 36px;
    }
  }
}
</style>
