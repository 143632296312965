import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import { Dialog } from 'vant';
import il8n from '@/locales/index';

// 定义包含额外属性的对象类型
interface ExtraProperties {
  activeIcon: string;
  inactiveIcon: string;
}

// 使用类型交叉将 RouteRecordRaw 类型与 ExtraProperties 类型合并
type CustomRouteRecordRaw = RouteRecordRaw & ExtraProperties;

export const layoutRoutes: Array<CustomRouteRecordRaw> = [
  {
    path: '/index',
    name: 'home',
    activeIcon: 'active_home',
    inactiveIcon: 'inactive_home',
    meta: {
      title: '首页',
    },
    component: () => import('@/pages/home/index.vue'),
  },
  {
    path: '/orders',
    name: 'orders',
    activeIcon: 'active_applyfor',
    inactiveIcon: 'inactive_applyfor',
    meta: {
      title: '任务',
      requireAuth: true,
      keepAlive: true,
    },
    component: () => import('@/pages/orders/index.vue'),
  },
  // v1.0.9功能
  {
    path: '/forum',
    name: 'forum',
    activeIcon: 'active_forum',
    inactiveIcon: 'inactive_forum',
    meta: {
      title: '论坛',
      requireAuth: true,
    },
    component: () => import('@/pages/forum/index.vue'),
  },
  {
    path: '/news',
    name: 'news',
    activeIcon: 'active_Invite',
    inactiveIcon: 'inactive_Invite',
    meta: {
      title: '消息',
      requireAuth: true,
    },
    component: () => import('@/pages/news/index.vue'),
  },
  {
    path: '/mine',
    name: 'mine',
    activeIcon: 'active_my',
    inactiveIcon: 'inactive_my',
    meta: {
      title: '我的',
      requireAuth: true,
    },
    component: () => import('@/pages/mine/index.vue'),
  },
];

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('@/layout/index.vue'),
    redirect: '/index',
    // 需要layout的页面
    children: layoutRoutes,
  },
  // 不需要layout的页面
  {
    path: '/login',
    name: 'login',
    meta: {
      title: 'login',
    },
    component: () => import('@/pages/login/index.vue'),
  },
  {
    path: '/codeLogin',
    name: 'CodeLogin',
    meta: {
      title: 'code login',
    },
    component: () => import('@/pages/login/codeLogin.vue'),
  },
  {
    path: '/forgotPassword',
    name: 'forgotPassword',
    meta: {
      title: '忘记密码',
    },
    component: () => import('@/pages/login/forgotPassword.vue'),
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      title: '注册',
    },
    component: () => import('@/pages/login/register.vue'),
  },
  {
    path: '/noviceTask',
    name: 'noviceTask',
    meta: {
      title: '新手任务',
      requireAuth: true,
    },
    component: () => import('@/pages/home/component/noviceTask.vue'),
  },
  {
    path: '/tutorial',
    name: 'tutorial',
    meta: {
      title: '教程',
      requireAuth: true,
    },
    component: () => import('@/pages/home/component/tutorial.vue'),
  },
  {
    path: '/tutorialDetail',
    name: 'tutorialDetail',
    meta: {
      title: '教学详情',
      requireAuth: true,
    },
    component: () => import('@/pages/home/component/tutorialDetail.vue'),
  },
  {
    path: '/makeMoney',
    name: 'makeMoney',
    meta: {
      title: '赚钱',
      requireAuth: true,
    },
    component: () => import('@/pages/home/component/makeMoney.vue'),
  },
  {
    path: '/personal',
    name: 'personal',
    meta: {
      title: '个人信息',
      requireAuth: true,
    },
    component: () => import('@/pages/mine/component/personal.vue'),
  },
  {
    path: '/grabDetail',
    name: 'grabDetail',
    meta: {
      title: '抢单详情',
      requireAuth: true,
    },
    component: () => import('@/pages/orders/component/grabDetail.vue'),
  },
  {
    path: '/customerService',
    name: 'customerService',
    meta: {
      title: '客服',
      requireAuth: true,
    },
    component: () => import('@/pages/im/index.vue'),
  },
  {
    path: '/myOrder',
    name: 'myOrder',
    meta: {
      title: '我的订单',
      requireAuth: true,
    },
    component: () => import('@/pages/mine/component/myOrder.vue'),
  },
  {
    path: '/orderDetailes',
    name: 'orderDetailes',
    meta: {
      title: '订单详情',
      requireAuth: true,
    },
    component: () => import('@/pages/mine/component/orderDetailes.vue'),
  },
  {
    path: '/accountSecurity',
    name: 'accountSecurity',
    meta: {
      title: '账号安全',
      requireAuth: true,
    },
    component: () => import('@/pages/mine/component/accountSecurity.vue'),
  },
  {
    path: '/verifCode',
    name: 'verifCode',
    meta: {
      title: '验证码', // 修改手机号和修改登录密码 两个共用页面（已废弃）
      requireAuth: true,
    },
    component: () => import('@/pages/mine/component/verifCode.vue'),
  },
  {
    path: '/site',
    name: 'site',
    meta: {
      title: '设置', // 修改支付密码和新的手机号和新的密码 三个共用页面
      requireAuth: true,
    },
    component: () => import('@/pages/mine/component/site.vue'),
  },
  {
    path: '/agencyCenter',
    name: 'agencyCenter',
    meta: {
      title: '代理中心', //
      requireAuth: true,
    },
    component: () => import('@/pages/mine/component/agencyCenter.vue'),
  },
  {
    path: '/agentLnstructions',
    name: 'agentLnstructions',
    meta: {
      title: '代理须知', //
      requireAuth: true,
    },
    component: () => import('@/pages/mine/component/agentLnstructions.vue'),
  },
  {
    path: '/detailsOfFunds',
    name: 'detailsOfFunds',
    meta: {
      title: '资金详情', //
      requireAuth: true,
    },
    component: () => import('@/pages/mine/component/detailsOfFunds.vue'),
  },
  {
    path: '/fundDetails',
    name: 'fundDetails',
    meta: {
      title: '资金明细',
      requireAuth: true,
    },
    component: () => import('@/pages/mine/component/fundDetails.vue'),
  },
  {
    path: '/MyTeam',
    name: 'MyTeam',
    meta: {
      title: '我的团队',
      requireAuth: true,
    },
    component: () => import('@/pages/mine/component/MyTeam.vue'),
  },
  {
    path: '/cardCase',
    name: 'cardCase',
    meta: {
      title: '收款账户',
      requireAuth: true,
    },
    component: () => import('@/pages/mine/component/cardCase.vue'),
  },
  {
    path: '/inviteFriends',
    name: 'inviteFriends',
    meta: {
      title: '邀请好友',
      requireAuth: true,
    },
    component: () => import('@/pages/mine/component/inviteFriends.vue'),
  },
  {
    path: '/addBank',
    name: 'addBank',
    meta: {
      title: '添加银行卡',
      requireAuth: true,
    },
    component: () => import('@/pages/mine/component/addBank.vue'),
  },
  {
    path: '/addUpi',
    name: 'addUpi',
    meta: {
      title: '添加Upi',
      requireAuth: true,
    },
    component: () => import('@/pages/mine/component/addUpi.vue'),
  },

  {
    path: '/withdrawal',
    name: 'withdrawal',
    meta: {
      title: '提现',
      requireAuth: true,
    },
    component: () => import('@/pages/mine/component/withdrawal.vue'),
  },
  {
    path: '/changePhpne',
    name: 'changePhpne',
    meta: {
      title: '修改手机号',
      requireAuth: true,
    },
    component: () => import('@/pages/mine/component/changePhpne.vue'),
  },
  {
    path: '/changePassword', //修改登录密码和资金密码
    name: 'changePassword',
    meta: {
      title: '修改登录密码',
      requireAuth: true,
    },
    component: () => import('@/pages/mine/component/changePassword.vue'),
  },
  /* 贡献值 */
  {
    path: '/contribution',
    name: 'contribution',
    meta: {
      title: '贡献值',
      requireAuth: true,
    },
    component: () => import('@/pages/mine/component/contribution.vue'),
  },
  {
    path: '/notice',
    name: 'notice',
    meta: {
      title: '系统通知',
      requireAuth: true,
    },
    component: () => import('@/pages/mine/component/notice.vue'),
  },
  {
    path: '/noticeDetail',
    name: 'noticeDetail',
    meta: {
      title: '系统通知详情',
      requireAuth: true,
    },
    component: () => import('@/pages/mine/component/noticeDetail.vue'),
  },
  {
    path: '/withdrawRecord',
    name: 'withdrawRecord',
    meta: {
      title: '提现记录',
      requireAuth: true,
    },
    component: () => import('@/pages/mine/component/withdrawRecord.vue'),
  },
  {
    path: '/banner_detail',
    name: 'banner_detail',
    meta: {
      title: '活动详情',
      requireAuth: true,
    },
    component: () => import('@/pages/home/banner_detail.vue'),
  },
  {
    path: '/dialer',
    name: 'dialer',
    meta: {
      title: '摇奖',
      requireAuth: true,
    },
    component: () => import('@/pages/home/dialer.vue'),
  },
  {
    path: '/signin',
    name: 'signin',
    meta: {
      title: '签到',
      requireAuth: true,
    },
    component: () => import('@/pages/home/signin.vue'),
  },
  {
    path: '/rankingList',
    name: 'RankingList',
    meta: {
      title: '排行榜',
      requireAuth: true,
    },
    component: () => import('@/pages/home/component/rankingList.vue'),
  },
  {
    path: '/privacy_policy.html',
    name: 'privacy_policy.html',
    meta: {
      title: '协议',
    },
    component: () => import('@/pages/privacy_policy.html.vue'),
  },
  {
    path: '/topUp',
    name: 'topUp',
    meta: {
      title: '充值',
    },
    component: () => import('@/pages/mine/component/topUp.vue'),
  },
  {
    path: '/Management',
    name: 'Management',
    meta: {
      title: '我的理财',
    },
    component: () => import('@/pages/mine/component/financialManagement/Management.vue'),
  },
  {
    path: '/Buyitin',
    name: 'Buyitin',
    meta: {
      title: '买入',
    },
    component: () => import('@/pages/mine/component/financialManagement/Buyitin.vue'),
  },
  {
    path: '/income',
    name: 'income',
    meta: {
      title: '收益明细',
    },
    component: () => import('@/pages/mine/component/financialManagement/income.vue'),
  },
  {
    path: '/records',
    name: 'records',
    meta: {
      title: '交易记录',
    },
    component: () => import('@/pages/mine/component/financialManagement/records.vue'),
  },
  {
    path: '/signin',
    name: 'Signin',
    meta: {
      title: '签到',
    },
    component: () => import('@/pages/home/signin.vue'),
  },
  {
    path: '/myCoin',
    name: 'MyCoin',
    meta: {
      title: '拉手币',
    },
    component: () => import('@/pages/mine/component/myCoin.vue'),
  },
  {
    path: '/lottery',
    name: 'Lottery',
    meta: {
      title: '抽奖',
    },
    component: () => import('@/pages/home/component/lottery.vue'),
  },
  {
    path: '/lotterRecords',
    name: 'LotterRecords',
    meta: {
      title: '中奖记录',
      // keepAlive: true,
    },
    component: () => import('@/pages/home/component/lotterRecords.vue'),
  },
  {
    path: '/deliveryInformation',
    name: 'DeliveryInformation',
    meta: {
      title: '收货信息补充',
    },
    component: () => import('@/pages/home/component/deliveryInformation.vue'),
  },
  {
    path: '/lotterDetail',
    name: 'LotterDetail',
    meta: {
      title: '订单详情',
    },
    component: () => import('@/pages/home/component/lotterDetail.vue'),
  },
  {
    path: '/agreementsAndPolicies',
    name: 'AgreementsAndPolicies',
    meta: {
      title: '协议及政策',
    },
    component: () => import('@/pages/mine/component/agreementsAndPolicies.vue'),
  },
  {
    path: '/manual',
    name: 'Manual',
    meta: {
      title: '说明书',
    },
    component: () => import('@/pages/mine/component/financialManagement/manual.vue'),
  },
  {
    path: '/forumDetail',
    name: 'ForumDetail',
    meta: {
      title: '动态详情',
    },
    component: () => import('@/pages/forum/component/forumDetail.vue'),
  },
  {
    path: '/addForum',
    name: 'addForum',
    meta: {
      title: '发表动态',
    },
    component: () => import('@/pages/forum/component/addForum.vue'),
  },
  {
    path: '/searchForum',
    name: 'SearchForum',
    meta: {
      title: '搜索',
    },
    component: () => import('@/pages/forum/component/search.vue'),
  },
  {
    path: '/topTrending',
    name: 'topTrending',
    meta: {
      title: '上热门',
    },
    component: () => import('@/pages/forum/component/topTrending.vue'),
  },
  {
    path: '/publishTask',
    name: 'PublishTask',
    meta: {
      title: '发布任务',
    },
    component: () => import('@/pages/orders/component/publishTask.vue'),
  },
  {
    path: '/pay',
    name: 'Pay',
    meta: {
      title: '支付',
    },
    component: () => import('@/pages/orders/component/pay.vue'),
  },
  {
    path: '/posted',
    name: 'Posted',
    meta: {
      title: '我发布的',
    },
    component: () => import('@/pages/mine/component/myOrder/posted.vue'),
  },
  {
    path: '/taskDetails',
    name: 'TaskDetails',
    meta: {
      title: '我发布的',
    },
    component: () => import('@/pages/mine/component/myOrder/taskDetails.vue'),
  },
  {
    path: '/promotionTasks',
    name: 'PromotionTasks',
    meta: {
      title: '推广任务',
    },
    component: () => import('@/pages/mine/component/myOrder/promotionTasks.vue')
  },
  {
    path: '/submitList',
    name: 'submitList',
    meta: {
      title: '提交订单列表',
    },
    component: () => import('@/pages/mine/component/myOrder/submitList.vue')
  },
  {
    path: '/submitDetail',
    name: 'submitDetail',
    meta: {
      title: '提交订单列表详情',
    },
    component: () => import('@/pages/mine/component/myOrder/submitDetail.vue')
  },
  {
    path: '/exchange',
    name: 'Exchange',
    meta: {
      title: '货币兑换',
    },
    component: () => import('@/pages/mine/component/exchange.vue')
  },
  {
    path: '/privacyPolicy',
    name: 'PrivacyPolicy',
    meta: {
      title: '隐私协议',
    },
    component: () => import('@/pages/login/privacyPolicy.vue')
  },
  {
    path: '/registerAgreement',
    name: 'RegisterAgreement',
    meta: {
      title: '注册协议',
    },
    component: () => import('@/pages/login/registerAgreement.vue')
  },

  // 替代vue2中的'*'通配符路径
  { path: '/:pathMatch(.*)*', redirect: '/' },
];

const router = createRouter({
  history: createWebHashHistory(), // history 模式则使用 createWebHistory()
  routes,
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');
  if (to.meta.requireAuth) {
    if (token) {
      next();
    } else {
      Dialog({
        confirmButtonText: il8n.global.t('确定'),
        message: il8n.global.t('请注册'),
        confirmButtonColor: 'blue',
        className: 'globalDialog',
        width: '70%',
      }).then(() => {
        next({ path: '/register' });
      });
    }
  } else {
    next();
  }
});
export default router;
