import http, { Response } from '@/utils/http';

export interface TaskParams {
  range: number;
  page: number;
  page_size: number;
  sorttype: number;
}
export interface PickParams {
  task_id: number;
}
export interface TaskGrabParams {
  num_grab: number | string | unknown;
  source: number | string;
  task_id: number | string | unknown;
}
export interface PostDetailParams {
  id: number;
}
export interface TaskCreat {
  title?: string,
  remark?: string,
  pay_one?: string,
  num_total?: number,
  image?: Array<string>,
  time_start?: string
}
export interface UpImgMd5 {
  md5: { md5: string; path: string }[];
}

export const API = {
  // 接单列表
  async taskList(params: TaskParams) {
    return await http.post<Response<any>>('/api/task/list', params);
  },
  // 收藏
  async pick(params: PickParams) {
    return await http.post<Response<any>>('/api/userInfo/pick', params);
  },
  // 接单成功
  async taskGrab(params: TaskGrabParams) {
    return await http.post<Response<any>>('/api/task/grab', params);
  },
  // 抢单详细
  async postDetail(params: PostDetailParams) {
    return await http.post<Response<any>>('/api/task/postDetail', params);
  },
  // 客服详情
  async grabCustomer(params: PickParams) {
    return await http.post<Response<any>>('/api/task/grab_customer', params);
  },
  //发布任务
  async taskCreat(params: TaskCreat) {
    return await http.post<Response<any>>('/api/merchants/merchants/task_create', params);
  },
  //图片验证
  async beforeVerifyImages(md5: any) {
    return await http.post<Response<any>>('/api/image/index', md5);
  },
  //上传类型
  async presignedUrl(params: { type: number; path: string }) {
    return await http.post<Response<any>>('/api/get/presigned_url', params);
  },
  //上传图片 md5
  async upImgMd5(params: UpImgMd5) {
    return await http.post<Response<any>>('/api/image/new_add', params);
  },
  //商户入驻-手续费配置
  async feeConfig() {
    return await http.post<Response<any>>('/api/merchants/merchants/fee_config');
  },

};
