import { Toast } from 'vant';
import il8n from '@/locales/index';
import dayjs from 'dayjs';

/**
 * 首字母大写
 * @param str 字符串
 * @returns 首字母大写的字符串
 */
export function TITLE_VASE(str: string): string {
  return str.replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
}
/**
 * 下划线转驼峰
 * @param str 字符串
 * @returns 转换为驼峰命名的字符串
 */
export function CAMEL_CASE(str: string): string {
  return str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
}
/**
 * 生成指定范围内的随机整数
 * @param min 最小值（包含）
 * @param max 最大值（包含）
 * @returns 介于 min 和 max 之间的随机整数
 */
export function RANDOM_INTEGER(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

// 校验新的支付密码
export function validatorMoneypwd(val: string) {
  const regular = /^[0-9]{6}$/;
  if (val.length < 6) {
    return il8n.global.t('新的支付密码需6位');
  } else if (!regular.test(val)) {
    return il8n.global.t('新的支付密码需数字');
  } else {
    return true;
  }
}

// 校验手机号
export function validatorUsername(val: string) {
  // if (Math.abs(val).toString().length < 10) {
  //   return il8n.global.t('手机号必须为十位数');
  // } else {
  //   return true;
  // }
  if (/^\d+$/.test(val) && val.length == 10) {
    return true;
  } else if (/^\d+$/.test(val) && val.length !== 10) {
    return il8n.global.t('手机号必须为十位数');
  } else {
    return il8n.global.t('手机号码只能输入数字');
  }
}
//校验账号
export function validatorAccount(val: string) {
  // const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/;
  // const re = new RegExp(reg);
  // if (val.length < 6) {
  //   return il8n.global.t('请输入正确的账号');
  // } else if (val.length > 16) {
  //   return il8n.global.t('请输入正确的账号');
  // } else if (!re.test(val)) {
  //   return il8n.global.t('请输入正确的账号');
  // } else {
  //   return true;
  // }


  // if (/^\d+$/.test(val) && val.length == 10) {
  //   return true;
  // } else if (!/^\d+$/.test(val) && val.length > 6) {
  //   return true;
  // } else {
  //   if (/^\d+$/.test(val)) {
  //     return il8n.global.t('手机号必须为十位数');
  //   } else if (6 > val.length) {
  //     return il8n.global.t('账号必须大于6位');
  //   }
  // }
  // /^[a-zA-Z0-9]{6,16}$/
  if (/^(?=.*[a-zA-Z0-9])[a-zA-Z0-9@.]{6,16}$/.test(val)) {
    return true;
  } else {
    return il8n.global.t('请输入正确的账号');
  }
}

// 校验password
export function validatorPassword(val: string) {
  const regular = /^[a-zA-Z0-9]{6,16}$/;
  if (val.length < 6) {
    return il8n.global.t('密码必须至少6个字符');
  } else if (!regular.test(val)) {
    return il8n.global.t('登录密码需6-16位字母或数字');
  } else {
    return true;
  }
}

// 校验验证码
export function validatorVerificationCode(val: string) {
  if (val.length < 6) {
    return il8n.global.t('验证码需6位');
  } else {
    return true;
  }
}

//校验谷歌邮箱
export function validatorEmail(val: string) {
  const regular = /^[a-zA-Z0-9_]+([.+\-]*[a-zA-Z0-9_]+)*@gmail\.com$/;
  if (!regular.test(val)) {
    return il8n.global.t('请输入正确的Goole邮箱');
  } else {
    return true;
  }
}

/**
 * @function utils_mobile_hide 手机号隐藏处理
 * @param mobile {string} 手机号
 * @return string
 * */
export const utils_mobile_hide = (mobile: string) =>
  mobile ? `${mobile.slice(0, 3)}***${mobile.slice(6)}` : '';

/**
 * @function utils_bank_hide 银行卡隐藏处理
 * @param bank {string} 银行卡
 * @return string
 * */
export const utils_bank_hide = (bank: string) =>
  bank ? `${bank.slice(0, 4)} **** **** ${bank.slice(-4)}` : '';

// 时间格式化模板
export const TIME_FORMAT = {
  FULL: 'YYYY-MM-DD HH:mm:ss',
  YMD: 'YYYY-MM-DD',
};

/**
 * @function utils_time_format 时间格式化
 * @param time {Date} 需要处理的时间
 * @param format {string} 时间格式化模板
 * @return string
 * */
export const utils_time_format = (time: Date | string | number, format = TIME_FORMAT.FULL) => {
  return time ? dayjs(time).format(format) : '';
};
/**
 * @function utils_time_format_second 时间格式化
 * @param time {Date | string | number} 需要处理的时间
 * @param format {string} 时间格式化模板
 * @param type {string} [type] startTime 开始时间 endTime 结束时间
 * @return string
 */
export const utils_time_format_second = (
  time: Date | string | number,
  format: string,
  type?: 'startTime' | 'endTime'
): string => {
  if (!time) return '';
  const formattedTime = dayjs(time).format(format);
  if (format === TIME_FORMAT.YMD) {
    console.log(type, 'typetypetypetypetype');
    if (type === 'startTime') {
      return `${formattedTime} 00:00:00`;
    } else if (type === 'endTime') {
      return `${formattedTime} 23:59:59`;
    }
  }
  return formattedTime;
};

/**
 * @description 时间戳转化yyyy-mm-dd hh-mm-ss
 * @datetime  时间戳为10位要*1000，13位不用*1000
 * */
export function FORMAT_DATE(datetime: number) {
  if (!datetime) {
    return;
  }
  let time = null;
  if (datetime.toString().length < 13) {
    time = new Date(datetime * 1000);
  } else {
    time = new Date(datetime);
  }
  const timeZoneOffset = 5.5; // 时区偏移量， UTC+8 =北京   UTC+4 =迪拜  UTC+5.5 =印度
  const utc = time.getTime() + time.getTimezoneOffset() * 60000; // 将本地时间转换为UTC时间
  const convertedDate = new Date(utc + 3600000 * timeZoneOffset); // 转换为指定时区的时间
  const year = convertedDate.getFullYear();
  let month: number | string = convertedDate.getMonth() + 1;
  let date: number | string = convertedDate.getDate();
  let hours: number | string = convertedDate.getHours();
  let minute: number | string = convertedDate.getMinutes();
  let second: number | string = convertedDate.getSeconds();
  if (!!month || month === 0) {
    if (month < 10) {
      month = '0' + month;
    }
  }
  if (!!date || date === 0) {
    if (date < 10) {
      date = '0' + date;
    }
  }
  if (!!hours || hours === 0) {
    if (hours < 10) {
      hours = '0' + hours;
    }
  }
  if (!!minute || minute === 0) {
    if (minute < 10) {
      minute = '0' + minute;
    }
  }
  if (!!second || second === 0) {
    if (second < 10) {
      second = '0' + second;
    }
  }
  return year + '-' + month + '-' + date + ' ' + hours + ':' + minute + ':' + second;
}

/**
 * @description 数字格式化
 * */
export function formatNumber(number: number | string) {
  let [integerPart, decimalPart] = number.toString().split('.');
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (decimalPart) {
    decimalPart = decimalPart.substring(0, 4);
    return `${integerPart}.${decimalPart}`;
  } else {
    return integerPart;
  }
}
